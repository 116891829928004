import React, { useState } from 'react'
import './cuponsComponent.css'
import { Avatar, Card, Drawer, Tag } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Cupons from '../cupons'
import { isMobile } from 'react-device-detect'
import { useRecoilValue } from 'recoil'
import { userState, pedidoState, lojaState } from '../../store/dataRecoil'

export const CuponsComponent = (props) => {


  const [visibleCupons, setVisibleCupons] = useState(false);
  const pedido = useRecoilValue(pedidoState)
  const loja = useRecoilValue(lojaState)
  const user = useRecoilValue(userState)

  function showCupons(value) {
    setVisibleCupons(value)
  }


  return <div>
    {user?.isAnonymous === false && <Card 
    size='small'
    style={{ marginTop: 5, marginBottom: 5, cursor: 'pointer' }} onClick={() => {
      setVisibleCupons(true)
    }}>
      <div className="CuponsComponent-main">
        <div className="CuponsComponent-body">
          <div className="CuponsComponent-avater">
            <Avatar src={require("../../img/coupon.png")} size="large" />
          </div>
          <div className="CuponsComponent-info">
            <div><b>{pedido?.cupom !== undefined ? pedido?.cupom?.tag : "CUPOM"}</b></div>
            <div>{pedido?.cupom !== undefined ? <Tag color="success" > cupom aplicado </Tag> : "Insira um código"}</div>

          </div>
        </div>
        <div className="CuponsComponent-icon">
          <RightOutlined />

        </div>
      </div>
    </Card>}


    <Drawer title={null} placement="right"
      width={isMobile ? '100%' : 500}
      onClose={() => setVisibleCupons(false)}
      open={visibleCupons}>
      <Cupons path={loja.path} showCupons={showCupons} />
    </Drawer>
  </div>

}

export default CuponsComponent