import React, { useState, useEffect } from 'react'
import {
    Table, Message
} from 'rsuite';
import { DatePicker, Button, Space, App, ConfigProvider } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import ptBR from "antd/es/locale/pt_BR";
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Column, HeaderCell, Cell } = Table

export const EntregasBairros = (props) => {


    const waiterUser = useRecoilValue(waiterUserState)
    const [dates, setDates] = useState([dayjs().add(-15, 'days'), dayjs()])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        pesquisar()
    }, [])


    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return false//current && current > dayjs().endOf('day')
    };



    async function pesquisar() {
        try {
            setLoading(true)
            setStatus(0)
            socket.emit('bairrosEntregas', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {

                var total = 0
                var taxas = 0
                let aux = result.data
                aux.map(item => {
                    total = total + parseInt(item.total)
                    taxas = taxas + parseFloat(item.taxa)
                    return item
                })
                aux.push({ total: total, cidade: "Total R$:", taxa: taxas })
                setData(aux)
                setStatus(1)
                setLoading(false)
            })

        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }



    return (<main>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <span></span>
                <Space >
                    <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>
                    <ConfigProvider locale={ptBR}>
                        <RangePicker
                            locale={ptBR}
                            size='small'
                            allowClear={false}
                            format={'DD-MM-YYYY'}
                            value={dates || null}
                            disabledDate={disabledDate}
                            onChange={(val) => {
                                setDates(val);
                            }}
                            changeOnBlur />
                    </ConfigProvider>
                    <Button size='small' type='primary' style={{ marginLeft: 5 }}
                        onClick={() => pesquisar()}>Pesquisar</Button>
                </Space>
            </div>

            <div style={{ marginTop: 10 }}>
                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}
                <Table
                loading={loading}
                    autoHeight={true}
                    hover
                    style={{ fontSize: '0.8em' }}
                    rowHeight={30}
                    headerHeight={30}
                    data={data}
                    onRowClick={rowData => {
                        // pesquisar()
                    }}>
                    <Column fixed flexGrow={1} align='center'>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>Bairro</HeaderCell>
                        <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                            {rowData => {
                                return rowData.bairro
                            }}
                        </Cell>
                    </Column>
                    <Column fixed flexGrow={1} align='center'>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>Cidade</HeaderCell>
                        <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                            {rowData => {
                                // console.log(rowData)
                                return rowData.cidade
                            }}
                        </Cell>
                    </Column>
                    <Column fixed width={80} align='center'>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>Total Taxas</HeaderCell>
                        <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                            {rowData => {
                                return rowData.taxa

                            }}
                        </Cell>
                    </Column>
                    <Column width={160} align='center'>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>Entregas</HeaderCell>
                        <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                            {rowData => {
                                return rowData.total

                            }}
                        </Cell>
                    </Column>


                </Table>
            </div>
        </div>
    </main>
    )
}

