import React, { useEffect, useState } from 'react'
import { Tag, Card, Drawer, Typography, Badge, Space } from 'antd'
import { calcularTaxa } from '../../service/dal.service'
import './totaisComponent.css'
import Address from '../../component/address'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useRecoilValue } from 'recoil'
import { pedidoState,  selCurrentAddress } from '../../store/dataRecoil'


export const TotaisComponent = (props) => {

  const params = useParams()
  const pedido = useRecoilValue(pedidoState);
  const address = useRecoilValue(selCurrentAddress);
  // const loja = useRecoilValue(lojaState);
  //const [current, atualizarCurrent] = useRecoilState(current_address)
  const [total, setTotal] = useState(0)
  const [subtotal, setSubTotal] = useState(0)
  const [modalVisibleEndereco, setModalVisibleEndereco] = useState(false)

  useEffect(() => {
    // atualizarCurrent(calcularTaxa(address, loja?.bairros))
   // atualizarCurrent(calcularTaxa(address, loja?.bairros, loja))
    calcularTotais()
  }, [total, pedido, address])


  function showAddress(value) {
    setModalVisibleEndereco(value)
    renderTaxa()
  }

  function calcularTotais() {
    var subtotal = 0;
    pedido.itens.map((item, index) => {
      subtotal = (item.valorUnitarioFinal * item.Quant) + subtotal
    })
    var total = 0;
    var cupom = 0;
    var fidelidade = 0

    if (pedido?.cupom !== undefined) {
      cupom = -1 * pedido?.cupom.valor;
    }

    if (pedido?.fidelidade !== undefined) {
      fidelidade = -1 * pedido?.fidelidade.valor;
    }

    if (address !== undefined) {
      if (address.taxa > 0 && pedido.type === "DELIVERY")
        total = subtotal + address.taxa + cupom + fidelidade
      else
        total = subtotal + cupom + fidelidade;
    } else {
      total = subtotal + cupom + fidelidade;
    }

    setTotal(total)
    setSubTotal(subtotal)
  }


  return <div>
    <Card style={{ marginTop: 5, marginBottom: 5 }} size="small">
      <div className="totaisComponent-line">
        <div> Subtotal </div>
        <div> R$ {subtotal.toFixed(2).toString()} </div>
      </div>
      {
        pedido?.cupom && <div className="totaisComponent-line">
          <div> Cupom </div>
          <div>  -R$ {parseFloat(pedido?.cupom?.valor).toFixed(2)} </div>
        </div>
      }

      {

        pedido?.fidelidade && <div className="totaisComponent-line">
          <div> {pedido?.fidelidade?.tag} </div>
          <div>  -R$ {parseFloat(pedido?.fidelidade?.valor).toFixed(2)} </div>
        </div>

      }

      {
        (pedido?.type === "DELIVERY" && parseInt(address?.atendido) === 1) && <div className="totaisComponent-line">
          <div className="totaisComponent-line-col1"> Taxa Delivery </div>
          <div>
            {
              renderTaxa()
            }
          </div>
        </div>

      }

      <div className="totaisComponent-line" style={{ fontSize: 18 }}>
        <div > <b> Total </b> </div>
        <div > <b>  R$ {total.toFixed(2)} </b></div>
      </div>
    </Card>


    {/* MODAL DE ENDEREÇO */}
    <Drawer title={null}
      placement="right"
      width={isMobile ? '100%' : 500}
      open={modalVisibleEndereco}
      onClose={() => {
        //   console.log('FECHOU')
        setModalVisibleEndereco(false)
      }}>
      <Address path={params.restaurant} showAddress={showAddress} />
    </Drawer>
  </div>


  function renderTaxa() {
    try {
      //   console.log('Atendido: ', address.atendido)
      if (!address?.bairro) {
        return <Typography.Text type='danger'> ? </Typography.Text>
      }
      else if (parseInt(address?.atendido) === 0)
              return <Badge color='red' text={"Endereço não atendido"}> </Badge>
      else {
        //  console.log("ELSE Executado")
        return <span> +R$ {address?.taxa?.toFixed(2)} </span>
      }
    } catch (erro) {

      return <Tag color="magenta" onClick={() =>
        setModalVisibleEndereco(true)
      }> Erro ao verificar taxa de entrega </Tag>
    }

  }

}

export default TotaisComponent