import React, { useState, useEffect } from 'react'
import { DatePicker, Button, Space, Card, App, Flex, ConfigProvider } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import { api } from '../../../service/api.service';
import { generateColor } from '../../../service/util'
import { Chart } from "react-google-charts";
import ptBR from "antd/es/locale/pt_BR";
import { FlexboxGrid, Container, Content, Col, DateRangePicker } from 'rsuite'
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import pt_BR from "rsuite/locales/pt_BR";

const { Meta } = Card

const { RangePicker } = DatePicker;


export const Dashboard = (props) => {

    const navigate = useNavigate()
    const waiterUser = useRecoilValue(waiterUserState)
    const { notification } = App.useApp();
    const [dates, setDates] = useState([
        dayjs().add(-10, 'days'),
        dayjs()])
    const [dados, setDados] = useState({})
    const [tiposPedidos, setTiposPedidos] = useState([])
    const [tiposPedidosPie, setTiposPedidosPie] = useState([])
    const [vendasLine, setVendasLine] = useState([])
    const [porCategoria, setPorCategoria] = useState([])
    const [porQuantidade, setPorQuantidade] = useState([])
    const [porValor, setPorValor] = useState([])


    const [loading0, setLoading0] = useState(true)
    const [loading1, setLoading1] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)
    const [loading4, setLoading4] = useState(true)

    // 

    // modal.warning({ title: 'Good' });

    useEffect(() => {
        // console.log(waiterUser)
        if (waiterUser?.cargoid !== 1)
            navigate('/waiter/login')
        pesquisar()
    }, [])

    function pesquisar() {
        load0()
        load1()
        load2()
        load3()
        load4()
    }

    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return current && current > dayjs().endOf('day')
        /*
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 15;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 15;
        const tooLate2 = current > dayjs().endOf('day')
        return !!tooEarly || !!tooLate || tooLate2;
        */
    };

    async function load0() {
        try {
           
            setLoading0(true)
            socket.emit('dashboard', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (value) => {
                 console.log(value)
                if (value.status === 200)
                    setDados(value.data)
                else
                    setDados({})
                setLoading0(false)
            });


        } catch (error) {
            console.log('error', error.message)
            notification.error({ message: error.message });
            setLoading0(false)
        }

    }

    async function load1() {
        try {
            setLoading1(true)
            socket.emit('loadTiposVendas', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {
                if (result.status === 200) {
                    var data = [["Element", "Pedidos", { role: "style" }]];
                    result.data.forEach(value => {
                        data.push([value.TipoVenda, value.Total, generateColor()])
                    })
                    setTiposPedidos(data)
                    //  console.log('data', data)
                } else {
                    var data = [["Element", "Pedidos", { role: "style" }]];
                    setTiposPedidos(data)
                }
                setLoading1(false)
            });
        } catch (error) {
            notification.error({ message: error.message });
            setLoading1(false)
        }

    }


    async function load2() {
        try {
            setLoading2(true)
            socket.emit('loadTiposVendasPie', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {
                //  console.log(result)
                var data = [["Task", "Pedidos"]];
                if (result.status === 200) {
                    result.data.forEach(value => {
                        data.push([value.TipoVenda, value.Total])
                    })
                }
                setLoading2(false)
                setTiposPedidosPie(data)
            });

        } catch (error) {
            notification.error({ message: error.message });
            setLoading2(false)
        }

    }

    async function load3() {
        try {
            setLoading3(true)
            socket.emit('movimentosPeriodoChart', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {
                var data = [["Dia", "Vendas"]];
                if (result.status === 200) {
                    result.data.forEach(value => {
                        data.push([dayjs(value.data).format('DD/MM'), value.total])
                    });
                }
                setVendasLine(data)
                setLoading3(false)
            });

        } catch (error) {
            notification.error({ message: error.message });
            setLoading3(false)
        }

    }


    async function load4() {
        try {
            setLoading4(true)
            socket.emit('produtosTop', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {
                var data = [["Dia", "Vendas"]];
                if (result.status === 200) {
                    var data = [["Element", "Categorias", { role: "style" }]];
                    result.data?.porcategoria.forEach(value => {
                        data.push([value.Categoria, parseFloat(value.Total), generateColor()])
                    })
                    setPorCategoria(data)

                    var data2 = [["Element", "Produto por valor", { role: "style" }]];
                    result.data?.porvalor.forEach(value => {
                        data2.push([value.produtoNome, parseFloat(value.Total), generateColor()])
                    })
                    setPorValor(data2)

                    var data3 = [["Element", "Produto por quantidade", { role: "style" }]];
                    result.data?.porquantidade.forEach(value => {
                        data3.push([value.produtoNome, parseFloat(value.Total), generateColor()])
                    })
                    setPorQuantidade(data3)
                }
                setLoading4(false)
            });
        } catch (error) {
            notification.error({ message: error.message });
        }
        setLoading4(false)
    }

    function renderLoading() {
        return <div style={{ height: 50 }}><LoadingOutlined style={{ marginLeft: 5 }} /> loading... </div>
    }

    function renderChart2() {
        if (loading2 === true)
            return renderLoading()
        else if (tiposPedidosPie.length > 1)
            return <Chart
                chartType="PieChart"
                data={tiposPedidosPie}
                options={{
                    title: "TIPOS DE PEDIDOS R$",
                }}
                width={350}
            />
        else
            return <div />
    }

    function renderChart1() {
        if (loading1 === true)
            return <Flex><LoadingOutlined /> loading... </Flex>
        else if (tiposPedidos?.length > 1)
            return <Chart
                chartType="ColumnChart"
                height={400}
                data={tiposPedidos}
                options={{
                    legend: { position: 'top', alignment: 'start' },
                    chart: {
                        title: "Pedidos",
                        subtitle: "Número de pedidos por tipo de vendas",
                    },
                }}
            />
        else
            return <div />
    }


    return <main>

        <Container>
            <Content>
                <div className="show-grid">
                    <FlexboxGrid>
                        <FlexboxGrid.Item as={Col} colspan={24} >
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>

                                <Button size='small' type='primary' style={{ marginLeft: 5 }}
                                    onClick={() => pesquisar()}>Pesquisar</Button>                                

                                {<ConfigProvider locale={ptBR}>
                                    <RangePicker
                                        locale={ptBR}
                                        size='small'
                                        allowClear={false}
                                        format={'DD-MM-YYYY'}
                                        value={dates}
                                        disabledDate={disabledDate}
                                        onChange={(val) => {
                                            setDates(val);
                                        }}
                                        changeOnBlur />
                                </ConfigProvider>
                                     }
                                <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item as={Col} colspan={24} md={12}>
                        <Card
                                cover={
                                    renderChart1()
                                }>
                            </Card>

                            <Flex vertical={true} gap={5} >
                                <Card
                                    style={{ height: 50, marginTop: 7 }}
                                    bodyStyle={{
                                        padding: 0,
                                        overflow: 'hidden',
                                    }}>
                                    <Flex>
                                        <img
                                            alt="avatar"
                                            src="https://programaengfood.com.br/images/manager/salesman.png"
                                            style={{
                                                display: 'block',
                                                width: 35,
                                                height: 35
                                            }}
                                        />

                                        <Flex
                                            style={{ fontSize: '0.9em', margin: 5, width: '100%' }}
                                            vertical>
                                            <div style={{ fontSize: '0.9em' }}><b>CLIENTES</b></div>
                                            <div style={{ fontSize: '0.9em', textAlign: 'end' }}>
                                                Novos <b style={{ fontSize: '1.4em', marginLeft: 7 }}>{loading0 === true ? <LoadingOutlined /> : dados?.ClientesCount}</b></div>
                                        </Flex>

                                    </Flex>
                                </Card>

                                <Card
                                    style={{ height: 50 }}
                                    bodyStyle={{
                                        padding: 0,
                                        overflow: 'hidden',
                                    }}>
                                    <Flex>
                                        <img
                                            alt="avatar"
                                            src="https://programaengfood.com.br/images/manager/money.png"
                                            style={{
                                                display: 'block',
                                                width: 35,
                                                height: 35
                                            }}
                                        />

                                        <Flex
                                            style={{ fontSize: '0.9em', margin: 5, width: '100%' }}
                                            vertical>
                                            <div style={{ fontSize: '0.9em' }}><b>VENDAS</b></div>
                                            <div style={{ fontSize: '0.9em', textAlign: 'end' }}>
                                                Realizadas <b style={{ fontSize: '1.4em', marginLeft: 7 }}>{loading0 === true ? <LoadingOutlined /> : dados?.VendasCount}</b></div>
                                        </Flex>

                                    </Flex>
                                </Card>

                                <Card
                                    style={{ height: 50 }}
                                    bodyStyle={{
                                        padding: 0,
                                        overflow: 'hidden',
                                    }}>
                                    <Flex>
                                        <img
                                            alt="avatar"
                                            src="https://programaengfood.com.br/images/manager/bank.png"
                                            style={{
                                                display: 'block',
                                                width: 35,
                                                height: 35
                                            }}
                                        />

                                        <Flex
                                            style={{ fontSize: '0.9em', margin: 5, width: '100%' }}
                                            vertical>
                                            <div style={{ fontSize: '0.9em' }}><b>RECEBIMENTOS</b></div>
                                            <div style={{ fontSize: '0.9em', textAlign: 'end' }}>
                                                R$ <b style={{ fontSize: '1.4em', marginLeft: 7 }}>{loading0 === true ? <LoadingOutlined /> : dados?.Recebimentos}</b></div>
                                        </Flex>

                                    </Flex>
                                </Card>

                                <Card
                                    style={{ height: 50 }}
                                    bodyStyle={{
                                        padding: 0,
                                        overflow: 'hidden',
                                    }}>
                                    <Flex>
                                        <img
                                            alt="avatar"
                                            src="https://programaengfood.com.br/images/manager/relationship.png"
                                            style={{
                                                display: 'block',
                                                width: 35,
                                                height: 35
                                            }}
                                        />

                                        <Flex
                                            style={{ fontSize: '0.9em', margin: 5, width: '100%' }}
                                            vertical>
                                            <div style={{ fontSize: '0.9em' }}><b>SERVIÇOS</b></div>
                                            <div style={{ fontSize: '0.9em', textAlign: 'end' }}>
                                                R$ <b style={{ fontSize: '1.4em', marginLeft: 7 }}>{loading0 === true ? <LoadingOutlined /> : dados?.Servicos?.toFixed(2)}</b></div>
                                        </Flex>
                                    </Flex>
                                </Card>
                            </Flex>


                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item as={Col} colspan={24} md={12}>
                            <Card
                                style={{ marginRight: 5, marginTop: 7 }}
                                cover={
                                    renderChart2()
                                }>
                                <Meta title="TICKET MÉDIO"
                                    description={<div>
                                        <div>É o cálculo da divisão do valor total das vendas pelo o número de vendas efetuados no período.</div>
                                        <Space direction='horizontal'>
                                            <span style={{ fontSize: '1.15em', color: '#000' }}>R$</span>
                                            <span style={{ fontSize: '1.8em', color: '#000' }}>{dados?.TicketMedio?.toFixed(2)}</span>
                                        </Space>
                                    </div>} />
                            </Card>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item as={Col} colspan={24} md={24} style={{ marginBottom: 7, marginTop: 7 }}>
                            {vendasLine.length > 1 && <Card style={{ marginTop: 7 }}
                                cover={vendasLine.length > 1 && <Chart
                                    chartType="Line"
                                    width="100%"
                                    height="400px"
                                    data={vendasLine}
                                    options={{
                                        chart: {

                                            title: " Vendas R$ ",
                                            subtitle: (dates[0] && dates[1]) ? " ".concat(dayjs(dates[0]).format('DD/MM/YYYY'),
                                                ' até ', dayjs(dates[1]).format('DD/MM/YYYY')) : "",
                                        }
                                    }}
                                />}>
                            </Card>
                            }
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item as={Col} colspan={24} md={12}>
                            <Card
                                style={{ marginRight: 5, marginTop: 7 }}
                                cover={
                                    porCategoria?.length > 1 && <Chart
                                        chartType="ColumnChart"
                                        height={400}
                                        data={porCategoria}
                                        options={{
                                            legend: { position: 'top', alignment: 'start' },
                                            chart: {
                                                title: "Categorias",
                                                subtitle: "Vendas por categoria",
                                            },
                                        }}
                                    />
                                }>
                            </Card>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item as={Col} colspan={24} md={12}>
                            <Card
                                style={{ marginRight: 5, marginTop: 7 }}
                                cover={
                                    porValor?.length > 1 && <Chart
                                        chartType="ColumnChart"
                                        height={400}
                                        data={porValor}
                                        options={{
                                            legend: { position: 'top', alignment: 'start' },
                                            chart: {
                                                title: "Produto",
                                                subtitle: "Vendas por quantidade",
                                            },
                                        }}
                                    />

                                }>

                            </Card>
                        </FlexboxGrid.Item>


                        <FlexboxGrid.Item as={Col} colspan={24} md={12} >
                            <Card
                                style={{ marginRight: 5, marginTop: 7 }}
                                cover={
                                    porQuantidade?.length > 1 && <Chart
                                        chartType="ColumnChart"
                                        height={400}
                                        data={porQuantidade}
                                        options={{
                                            legend: { position: 'top', alignment: 'start' },
                                            chart: {
                                                title: "Produto",
                                                subtitle: "Vendas por quantidade",
                                            },
                                        }}
                                    />

                                }>

                            </Card>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>



                </div>

            </Content>
        </Container>
    </main>

}

