import React, { useEffect, useState } from 'react'
import { api } from '../../service/api.service'
import { Table, Row, Col, Tag, Button, Divider, Avatar } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { lojaState, userState, pedidoState } from '../../store/dataRecoil'
import './cupons.css'
import { useToasts } from 'react-toast-notifications'

const { Column } = Table

export const Cupons = (props) => {

  const params = useParams()
  const toash = useToasts()
  const user = useRecoilValue(userState)
  const [pedido, setCupom] = useRecoilState(pedidoState)
  const loja = useRecoilValue(lojaState)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {

    if (params.restaurant)
      getCupons()
    else
      navigate('/notfound')

  }, [pedido])

  async function getCupons() {
    try {
      setLoading(true)
      const resp = await api.get("api/v2.0/cupom/all?uid=".concat(user.uid, '&path=', params.restaurant))
      if (resp.status === 200) {
        //  console.log('data', resp.data)
        var data = resp.data;
        if (pedido.cupom) {
          data.map(item => {
            item.ativo = false;
            if (pedido.cupom === undefined)
              item.ativo = false;
            else if (item.idengcupons === pedido.cupom.idengcupons) {
              item.ativo = true;
            }
          })
        } else {
          data.map(item => {
            item.ativo = false;
          })
        }
        setData(resp.data)

        //   console.log(resp.data)
      }
      else
        setData([])
    } catch (error) {
      toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
    setLoading(false)
  }

  return <div className="main-cupons">
    <div className="main-cupons-title">
      {loja?.informacoes?.Fantasia}
    </div>
    <div className="cupons-table">
      <Table size={'small'} pagination={false} bordered dataSource={data} showHeader={false}>
        <Column dataIndex="createAt" key="createAt"
          render={(text, item, index) => {
            return <Row>
              <Col span={24}>
                <div className="cupom-row-1">
                  <div>
                    <div>
                      <Avatar alt="fidel" size="small" style={{ marginRight: 10 }}
                        src={require("../../img/coupon.png")} />
                      <b>  {item.tag} - REGRAS</b>
                    </div>
                  </div>
                  <div className="cupom-row-item">
                    {
                      item.ativo === true ? <Button size="small" type="primary" danger onClick={() => removeCupom(item)}>
                        Remover cupom
                      </Button> : <Button size="small" type="primary" onClick={() => cupomClick(item)}>
                        Aplicar cupom
                      </Button>
                    }
                  </div>

                </div>
              </Col>
              <Col span={24}><Divider style={{ marginTop: 7, marginBottom: 12 }} /></Col>
              <Col span={24}>
                {
                  item.exclusive !== null && <Tag color="magenta">
                    - Enviado exclusivamente para você.
                  </Tag>
                }
              </Col>
              <Col span={24}>- Cumpom com valor de desconto de <b>R${item.valor.toFixed(2)}</b>.</Col>
              <Col span={24}>- Compra mínima de R${item.minimo} sem taxa de entrega.</Col>
              <Col span={24}>- Esse cupom é válido até {formatarData(item.validade)}.</Col>
              <Col span={24}>- Disponível para pagamento online* ou na entrega.</Col>
              <Col span={24}>- Só pode-se utilizar um cupom por pedido.</Col>
              <Col span={24}>- Só pode ser utilizado em um pedido.</Col>
              <Col span={24}>- Cupons limitados.</Col>
            </Row>
          }} />
      </Table>
    </div>

  </div>

  function formatarData(data) {
    try {
      var arrDataExclusao = data.split('-');
      return arrDataExclusao[2].concat('/', arrDataExclusao[1]);
    } catch (erro) {
      return data;
    }
  }

  function cupomClick(item) {
    try {
      setCupom({ ...pedido, cupom: item })
      //setCupom(item)
      toash.addToast('O cupom foi aplicado ao seu pedido!.', { appearance: 'success', autoDismiss: true })
      props.showCupons(false)
      //  props.history.push("/restaurante/".concat(props.match.params.path))
    } catch (error) {

    }
  }

  function removeCupom() {
    try {
      setCupom({ ...pedido, cupom: undefined })
      toash.addToast('O cupom foi removido do seu pedido.', { appearance: 'info', autoDismiss: true })
    } catch (error) {
      console.log(error)
    }
  }

}

export default Cupons