'useClient'
import React, { useEffect, useState } from 'react'
import {
    Table, Message, ButtonToolbar, Button as ButtonRSuite, IconButton, Modal, Divider, FlexboxGrid,
    Col
} from 'rsuite';
import { DatePicker, Button, Space, App, Flex, ConfigProvider, Tag, Table as Table2 } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import ptBR from "antd/es/locale/pt_BR";
import IconSearching from '@rsuite/icons/legacy/Search';
import { ArrowDown, ArrowUp, EventDetail } from '@rsuite/icons'
import { isDesktop, isMobile } from 'react-device-detect'
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';


const { RangePicker } = DatePicker;
const { Column, HeaderCell, Cell } = Table

export const Caixas = (props) => {

    const navigate = useNavigate()
    const waiterUser = useRecoilValue(waiterUserState)
    const { message, notification, modal } = App.useApp();
    const [dates, setDates] = useState([dayjs().add(-100, 'days'), dayjs()])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [open, setOpen] = useState(false)
    const [caixa, setCaixa] = useState({})
    const [caixaData, setCaixaData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        pesquisar()
    }, [])





    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return current && current > dayjs().endOf('day')
    };

    async function pesquisar() {
        try {
            setLoading(true)
            socket.emit('caixas', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (value) => {
                if (value.status === 200)
                    setData(value.data)
                else
                    setData([])
                setLoading(false)
            });

        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }



    async function loadCaixa(id) {
        try {
            socket.emit('caixa', waiterUser?.database, {
                caixaid: id
            }, (value) => {
                if (value.status === 200)
                    setCaixaData(value.data)
                else
                setCaixaData({})
                setLoading(false)
            });

        } catch (error) {
            setStatus(-1)
        }
    }



    return (<>
        <main>
            <Flex vertical>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>

                    <Button size='small' type='primary' style={{ marginLeft: 5 }}
                        onClick={() => pesquisar()}>Pesquisar</Button>

                    <ConfigProvider locale={ptBR}>
                        <RangePicker
                            locale={ptBR}
                            size='small'
                            allowClear={false}
                            format={'DD-MM-YYYY'}
                            value={dates || null}
                            disabledDate={disabledDate}
                            onChange={(val) => {
                                setDates(val);
                            }}

                             />
                    </ConfigProvider>

                    <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>

                </div>


                <div style={{ marginTop: 10 }}>

                    {status === -1 && <Message showIcon type="error" header="Error">
                        Desculpe, não foi possível processar a solicitação.
                    </Message>}
                    {/* TABELA AQUI*/}

                    <Table2 size='small'
                        dataSource={data}
                        style={{ fontSize: '0.7em' }}
                        bordered
                        pagination={false}
                        loading={loading}>
                        <Table2.Column width={60} title={<span style={{fontSize:'0.8em'}}>ID</span>} dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <span style={{ fontSize: '0.8em' }}> {rowData.ABERTURA} </span>
                        }} />
                        <Table2.Column width={100} title={<span style={{fontSize:'0.8em'}}>DATA </span>}  dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <span style={{ fontSize: '0.8em' }}>{dayjs(rowData.DT_ABERTO).format('DD/MM/YYYY')}</span>
                        }} />
                        {isDesktop && <Table2.Column title={<span style={{fontSize:'0.8em'}}>OPERADOR </span>} dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <span style={{ fontSize: '0.8em' }}>{rowData.funNick}</span>
                        }} />}
                        {isDesktop && <Table2.Column title= {<span style={{fontSize:'0.8em'}}>CAIXA-PC </span>} dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <span style={{ fontSize: '0.8em' }}>{rowData.caixaNumero}</span>
                        }} />}
                        {isDesktop && <Table2.Column width={80} title={<span style={{fontSize:'0.8em'}}>INICIAL R$ </span>} dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <span style={{ fontSize: '0.8em' }}>{rowData.caixaValorInicial}</span>
                        }} />}
                        <Table2.Column width={85} title={<span style={{fontSize:'0.8em'}}>RECEITAS R$</span>} dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <span style={{ fontSize: '0.8em' }}>{rowData.TOTAL}</span>
                        }} />
                        <Table2.Column width={60} dataIndex={"ABERTURA"} render={(text, rowData, index) => {
                            return <Flex align='center'>
                                <Tag style={{ height: 20, width: 48, textAlign: 'center' }} color={rowData.caixaAberto === 0 ? '#108ee9' : '#87d068'} >
                                    <span style={{ fontSize: '0.8em' }}> {rowData.caixaAberto === 0 ? 'fechado' : 'aberto'} </span>
                                </Tag>
                                <ButtonToolbar style={{ marginLeft: 3 }}>
                                    <IconButton icon={<IconSearching />} size="sm"
                                        onClick={() => {
                                            setCaixa(rowData)
                                            loadCaixa(rowData.ABERTURA)
                                            setOpen(true)
                                        }} />
                                </ButtonToolbar>
                            </Flex>
                        }} />
                    </Table2>
                </div>

                <Modal backdrop={'static'} size='md' keyboard={false} open={open} onClose={() => setOpen(false)}>
                    <Modal.Header>
                        <Modal.Title>
                            <Flex gap={5} vertical>
                                <Space style={{ fontSize: isMobile ? '0.8em' : '0.9em' }}>
                                    ID: {caixa?.ABERTURA}
                                    <Divider vertical />
                                    Status: <Tag style={{ height: 22, width: 57 }} color={caixa?.caixaAberto === 0 ? '#108ee9' : '#87d068'} >
                                        {caixa?.caixaAberto === 0 ? 'fechado' : 'aberto'}
                                    </Tag>
                                    <Divider vertical />
                                    Caixa-PC: {caixa?.caixaNumero}
                                    <Divider vertical />
                                    Data: {dayjs(caixa?.caixaHora).format('DD/MM/YYYY HH:mm')}
                                </Space>

                            </Flex>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FlexboxGrid>
                            <FlexboxGrid.Item as={Col} colspan={24} sm={24} lg={12}>
                                <Flex vertical>
                                    <Space>
                                        <ArrowUp color='green' style={{ fontSize: 25 }} /> (+) Receitas
                                    </Space>
                                    <div>
                                        <Table
                                            style={{ marginRight: 10 }}
                                            hover
                                            autoHeight={true}
                                            rowHeight={30}
                                            showHeader={false}
                                            data={caixaData?.resumo}>
                                            <Column align="left" flexGrow={1}>
                                                <HeaderCell style={{ padding: 2 }} />
                                                <Cell dataKey="formaPagamentoID" style={{ padding: 2 }}>
                                                    {rowData => {
                                                        return rowData?.RECEBIMENTO === 'Total' ? rowData?.RECEBIMENTO :
                                                            rowData?.RECEBIMENTO.concat(' (', rowData?.transacoes, ')')
                                                    }}
                                                </Cell>
                                            </Column>
                                            <Column align="right" >
                                                <HeaderCell style={{ padding: 2 }} />
                                                <Cell dataKey="formaPagamentoID" style={{ padding: 2 }}>
                                                    {rowData => {
                                                        return "R$ ".concat(rowData?.TOTAL.toFixed(2))
                                                    }}
                                                </Cell>
                                            </Column>
                                        </Table>
                                    </div>
                                </Flex>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item as={Col} colspan={24} sm={24} lg={12}>
                                <Flex vertical>
                                    <Space>
                                        <ArrowDown color='red' style={{ fontSize: 25 }} /> (-) Despesas
                                    </Space>
                                    <div>
                                        <Table
                                            style={{ marginRight: 10, marginLeft: 10 }}
                                            hover
                                            autoHeight={true}
                                            rowHeight={30}
                                            showHeader={false}
                                            data={caixaData?.retiradas}>
                                            <Column align="left" flexGrow={1}>
                                                <HeaderCell style={{ padding: 2 }} />
                                                <Cell dataKey="reciboID" style={{ padding: 2 }}>
                                                    {rowData => {
                                                        return rowData?.RETIRADAS
                                                    }}
                                                </Cell>
                                            </Column>
                                            <Column align="right" >
                                                <HeaderCell style={{ padding: 2 }} />
                                                <Cell dataKey="reciboID" style={{ padding: 2 }}>
                                                    {rowData => {
                                                        return "R$ ".concat(parseFloat(rowData?.TOTAL).toFixed(2))
                                                    }}
                                                </Cell>
                                            </Column>
                                        </Table>
                                    </div>
                                </Flex>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item as={Col} colspan={24} sm={24} lg={24}>
                                <Space style={{ marginTop: 20 }}>
                                    <EventDetail style={{ fontSize: 25 }} /> Histórico do Movimento
                                </Space>
                                <div>
                                    <Table
                                        style={{ fontSize: '0.8em' }}
                                        hover
                                        headerHeight={30}
                                        autoHeight={true}
                                        rowHeight={30}
                                        data={caixaData?.historico}>
                                        <Column align="left" width={130}>
                                            <HeaderCell style={{ padding: 3, color: '#000' }}>DATA</HeaderCell>
                                            <Cell dataKey="Hora" style={{ padding: 2 }}>
                                                {rowData => {
                                                    return rowData?.Hora ? dayjs(rowData?.Hora).format("DD/MM/YYYY HH:mm") :
                                                        dayjs(rowData?.Data).format("DD/MM/YYYY")
                                                }}
                                            </Cell>
                                        </Column>
                                        {!isMobile && <Column align="left" flexGrow={1}>
                                            <HeaderCell style={{ padding: 3, color: '#000' }} >DESCRIÇÃO</HeaderCell>
                                            <Cell dataKey="Hora" style={{ padding: 2 }}>
                                                {rowData => {
                                                    return rowData?.Descricao
                                                }}
                                            </Cell>
                                        </Column>}
                                        <Column align="left" >
                                            <HeaderCell style={{ padding: 3, color: '#000' }}>ENTRADA</HeaderCell>
                                            <Cell dataKey="Hora" style={{ padding: 2 }}>
                                                {rowData => {
                                                    return rowData?.Entrada ? rowData?.Entrada.toFixed(2) : null
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column align="left" >
                                            <HeaderCell style={{ padding: 3, color: '#000' }}>SAÍDA</HeaderCell>
                                            <Cell dataKey="Hora" style={{ padding: 2 }}>
                                                {rowData => {
                                                    return rowData?.Saida ? rowData?.Saida.toFixed(2) : null
                                                }}
                                            </Cell>
                                        </Column>
                                    </Table>
                                </div>

                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Modal.Body>
                    <Modal.Footer>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: '0.9em', fontStyle: 'italic' }}> Operador:<b> {caixa?.funNick} </b></div>
                            <Button onClick={() => setOpen(false)} appearance="primary">
                                Fechar
                            </Button>

                        </div>


                    </Modal.Footer>
                </Modal>
            </Flex>
        </main>
    </>
    )
}

