import React, { useState } from 'react'
import './bairros.css'

export const Bairros = (props) => {

    const [bairros, setBairros] = useState(props.bairros)

    return <div className="div-bairros-main">
        <div className="div-bairros-header">Taxas de Entrega</div>
        <div className="div-bairros-list">
            {
                bairros.map((item, key) => {
                    return <div className="div-bairros-list-item" key={key}>
                        <div className="div-bairros-list-item-descricao">{item.descricao}</div>
                        <div className="div-bairros-list-item-valor"> { item.valor === 0 ? 'Grátis' :  'R$'.concat(item.valor.toFixed(2))}</div>
                    </div>
                })
            }

        </div>


    </div>

}

export default Bairros