import React, { useEffect, useState } from 'react'
import {
    Table, Message, Button as ButtonRSuite, Divider, FlexboxGrid,
    SelectPicker, InputGroup, Input as Inputrs, Badge, Stack
} from 'rsuite';
import { Space, Flex } from 'antd';
import { Search } from '@rsuite/icons'
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'

const { Column, HeaderCell, Cell } = Table

export const Produtos = (props) => {

    const waiterUser = useRecoilValue(waiterUserState)
    const { isInsumo } = useParams();
    const [categorias, setCategorias] = useState([])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [txt, setTxt] = useState('')
    const [catchave, setCatchave] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        loadCategorias()
    }, [isInsumo])

    async function loadCategorias() {
        try {
            setLoading(true)
            setData([])
            socket.emit('categorias', waiterUser?.database,
                {
                    isInsumo: isInsumo === '1' ? 1 : 0
                },
                (value) => {
                    const aux = [{ menuDescricao: 'Todas', value: 0 }].concat(value.data)
                    if (value.status === 200) {
                        setCategorias(aux.map(
                            item => ({ label: item.menuDescricao, value: item.menuChave })
                        ))
                        pesquisar(0)
                    } else {
                        setCategorias(aux)
                        setLoading(false)
                    }

                });
        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }


    async function pesquisar(cat) {
        try {
            setLoading(true)
            socket.emit('produtos', waiterUser?.database,
                {
                    tipos: 0,
                    produtoNome: '',//txt,
                    isInsumo: isInsumo === '1' ? 1 : 0,
                    categoria: cat ? cat : 0
                }, (value) => {
                    if (value.status === 200) {
                        setData(value.data)
                    } else {
                        setData([])
                    }
                    setLoading(false)
                })
        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }


    return (<main>
        <div style={{ marginTop: 10 }}>
            <Flex vertical gap={5}>
                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}

                <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                    <SelectPicker
                        size='sm'
                        label="Categoria"
                        data={categorias}
                        style={{ width: 280 }}
                        onChange={e => {
                            setCatchave(e)
                            pesquisar(e)
                        }} />

                    <Stack>
                        <InputGroup inside size='sm'>
                            <Inputrs size='sm' placeholder='Nome do produto' style={{ width: 280 }}
                                value={txt}
                                onChange={e => setTxt(e)} />
                            <InputGroup.Button size='sm' >
                                <Search />
                            </InputGroup.Button>
                        </InputGroup>
                        <ButtonRSuite size='sm' appearance='primary' style={{ marginLeft: 5 }}
                            onClick={() => pesquisar(catchave)}>Pesquisar</ButtonRSuite>
                    </Stack>
                </Space>


                <div >
                    <Table
                        autoHeight={true}
                        hover
                        loading={loading}
                        height={600}
                        rowHeight={isMobile ? 43 : 30}
                        showHeader={!isMobile}
                        headerHeight={30}
                        data={data.filter((item) =>
                            item?.produtoNome?.toLowerCase().includes(txt.toLowerCase()))}
                        onRowClick={rowData => {
                            // console.log(rowData);
                        }}>
                        {isMobile ? <>
                            <Column flexGrow={1} fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CATEGORIA</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <FlexboxGrid style={{ fontSize: '0.8em' }}>
                                            <FlexboxGrid.Item colspan={24}>
                                                <Space style={{ minWidth: 100 }}>{rowData.menuDescricao}</Space>
                                                <Divider vertical />
                                                <Space style={{ minWidth: 150 }}>
                                                    {rowData.produtoNome}
                                                </Space>
                                                <Divider vertical />
                                                <span style={{ fontSize: '0.8em' }}>{rowData.produtoAtivo === 1 ?
                                                    <Badge content="Ativo" style={{ width: 50, textAlign: 'center' }} color='green' /> : <Badge content="Inativo"
                                                        style={{ width: 50, textAlign: 'center' }}
                                                        color='red' />}</span>

                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item colspan={24}>
                                                <Stack direction='row' spacing={20}>
                                                    <span style={{ fontSize: '0.8em' }}> Estoque: {rowData.produtoEstoque}</span>
                                                    <span style={{ fontSize: '0.8em' }}>Custo: {rowData.produtoCusto}</span>
                                                    <span style={{ fontSize: '0.8em' }}>Venda:{rowData.produtoVenda}</span>
                                                </Stack>
                                            </FlexboxGrid.Item>

                                        </FlexboxGrid>
                                    }}
                                </Cell>
                            </Column>
                        </> : <>
                            <Column flexGrow={1} fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>PRODUTO</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoNome}</span>
                                    }}
                                </Cell>
                            </Column>
                            <Column flexGrow={1} fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CATEGORIA</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.menuDescricao}</span>
                                    }}
                                </Cell>
                            </Column>

                            {isInsumo !== '1' && <Column width={100} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>STATUS</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoAtivo === 1 ?
                                            <Badge content="Ativo" style={{ width: 50, textAlign: 'center' }} color='green' /> : <Badge content="Inativo"
                                                style={{ width: 50, textAlign: 'center' }}
                                                color='red' />}</span>
                                    }}
                                </Cell>
                            </Column>
                            }
                            <Column width={100} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>ESTOQUE</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoEstoque}</span>
                                    }}
                                </Cell>
                            </Column>
                            <Column width={130} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>EST. CONTROLADO?</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.estoqueControlado === 1 ?
                                            <Badge content="Sim" style={{ width: 50, textAlign: 'center' }} color='green' /> : <Badge content="Não"
                                                style={{ width: 50, textAlign: 'center' }}
                                                color='red' />}</span>
                                    }}
                                </Cell>
                            </Column>
                            <Column width={100} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CUSTO</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoCusto}</span>
                                    }}
                                </Cell>
                            </Column>
                            <Column width={100} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>VALOR</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoVenda}</span>
                                    }}
                                </Cell>
                            </Column>
                        </>}
                    </Table>
                </div>
            </Flex>
        </div>
    </main>
    )
}
