import React, { useEffect, useState } from 'react'
//import CallWaiter from '../../../component/cardapio/callWaiter'
import InputMask from "react-input-mask";
import { Input, App, Flex, Avatar, Button, Progress } from 'antd';
//import WaitCtrl from '../../../component/waitCtrl'
import { LockOutlined, ForkOutlined, AlertOutlined } from '@ant-design/icons'
import { mesaState, pedidoCardapioState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import './cardapioLogin.css';
import menuPNG from '../../img/menu.png'
import { api } from '../../service/api.service';
import Lottie from 'react-lottie';
import animationData from '../../lotties/food.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,

};


export const CardapioLogin = (props) => {



    const navigate = useNavigate()
    const location = useLocation()

    const { token, id, url } = useParams()
    const { notification } = App.useApp()
    const [pedido, setPedido] = useRecoilState(pedidoCardapioState)
    const [call, setCall] = useState(false)
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [msg, setMsg] = useState('')

    useEffect(() => {
        /*
        if (token) {
            setPedido({
                createdAt: new Date().getTime(),
                local: token,
                sala: token,
                token: token,
                itens: [],
                type: 'MESA',
                mesaID: id,
                clientenome: pedido?.clientenome,
                telefone: pedido?.telefone
            })
            cardapio()
        } else {

        }
        */
        if (url) {
            cardapio()
        } else
            setMsg("Escanei o QRcode novamente!")

    }, [])





    async function cardapio() {

        try {

            if (!url || openBackdrop) {
                return
            }


            var data = {
                url: url,
                //  mesaID: pedido?.mesaID,
                clientenome: 'não informado', //pedido?.clientenome,
                telefone: '00 00000-0000',//pedido?.telefone,
                // sala: pedido?.local
            }
            setOpenBackdrop(true)

            var result = await api.post('/api/v1.0/manager/cardapio/login', data)
            //var result = await api.post('api/v1.0/manager/qrcode', data)

            let aux = result.data
            aux.createdAt = new Date().getTime()
            aux.local = aux.data.sala
            aux.sala = aux.data.sala
            aux.mesaid = aux.data.mesaid
            aux.mesaID = aux.data.mesaid
            aux.itens = []
            aux.type = 'MESA'
            aux.clientenome = pedido?.clientenome
            aux.telefone = pedido?.telefone
            setPedido(aux)
          
            navigate('/cardapio', {
                replace: true
            })            

        } catch (error) {            
            navigate('/order', {
                replace: true
            })               

          setMsg('Não foi possível efetuar comunicação com servidor! Verifique se você está conectado na rede wifi do restaurante e tente novamente.')       
        }
        setOpenBackdrop(false)
    }


    return <Flex vertical
        style={{
            height: '100vh',
            width: '100%',
            backgroundColor: 'whitesmoke'

        }} align='center' justify='center'>

        <Flex vertical style={{
            width: '50%',
            borderRadius: 6,
        }} align='center' justify='center'>
            <Lottie
                options={defaultOptions}
                height={100}
                width={100}
            />
            {!msg ? 'Loading...' : <div>Leia o <b>QRcode</b> novamente! </div>}
            {!msg && <Progress percent={50} status="active" />}

        </Flex>
    </Flex>

}

/*
const mapStateToProps = (state) => ({
    mesa: state.mesaReducer.mesa
})

const mapDispatchToProps = dispatch => ({
    setCall: (call) => dispatch(WaiterAction.CALL_WAITER(call)),
    setMesa: (mesa) => dispatch(MesaAction.SET_MESA(mesa)),
    clear_pedido: (path) => dispatch(PedidoAction.clear_pedido(path)),
    saveBaseURL:(baseURL)=>dispatch(ConfigAction.SAVE_BASE_URL(baseURL))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
*/
