import React from 'react'
import './sacolaProdutos.css'
import { Row, Col, Button, Input, Typography, Card } from 'antd'
import {  PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { pedidoState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'

const { Text } = Typography

export const SacolaProdutos = (props) => {

    const [pedido, updatePedido ]= useRecoilState(pedidoState);


    function changeQuant(it, index, quant) {
      var item = JSON.parse(JSON.stringify(it))
        var carrinho = JSON.parse(JSON.stringify(pedido)) 
        var cart = carrinho.itens;
        item.Quant = item.Quant + quant;
        if (item.Quant < 1) {
            cart.splice(index, 1);
        } else {
            cart[index] = item;
        }
        carrinho.itens = cart;
        updatePedido(carrinho)
    }


    function renderItem(it, index) {

        try {
          var item = JSON.parse(JSON.stringify(it))
            var carrinho = JSON.parse(JSON.stringify(pedido)) 
            var cart = carrinho.itens;
            if (item?.Quant === 0) {
                item.Quant = 1
                cart[index] = item;
                carrinho.itens = cart;
                updatePedido(carrinho)
            }

        } catch (error) {

        }


        return <div key={"itemProddiv-".concat(index)}> 

            <Row key={"itemProd-".concat(index)}>
                <Col span={24}>
                    <div className="produto--line">
                        <div className="item--before">
                            <Button size="large" type={'link'} icon={<MinusCircleOutlined />}
                                onClick={() => changeQuant(item, index, -1)}></Button>
                            <Input value={item?.Quant}
                                style={{ width: 40, textAlign: 'center', justifyContent: 'center' }} />
                            <Button size="large" type={'link'} icon={<PlusCircleOutlined />}
                                onClick={() => changeQuant(item, index, 1)}></Button>
                        </div>
                        <div className="item--last">
                            <Row>
                                <Col span={24}>
                                    {item.NomeReduzido}
                                </Col>
                                {
                                    item.complementos && item.complementos?.map((comp, i) => (
                                        <Col span={24} key={"kc".concat(i)}>
                                            <Text italic style={{ color: 'gray', fontSize: 11 }}>{comp.Quant} x {comp.NomeReduzido}</Text>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                        <div className="item--price">
                            {(item.Quant * item.valorUnitarioFinal).toFixed(2)}
                        </div>
                    </div>
                </Col>
                {/*<Col span={24}><Divider style={{ marginTop: 3, marginBottom: 3 }} /></Col> */}
            </Row>

        </div>
    }


    return <Card size='small' style={{ marginTop: 5 }}>
        <div style={{ marginBottom: 3, backgroundColor: 'white', width: '100%' }}>
            {
                pedido?.itens?.map((item, index) => {
                    return renderItem(item, index)
                })
            }
        </div>
    </Card>

}

export default SacolaProdutos