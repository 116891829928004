import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import 'firebase/auth'
import 'firebase/database'
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";


//Produção
export const firebaseConfig = {
  apiKey: "AIzaSyCeoZ66v8nk2yHAXCgUBIKosV0BXsoy0ug",
  authDomain: "engfooddelivery.firebaseapp.com",
  databaseURL: "https://engfooddelivery.firebaseio.com",
  projectId: "engfooddelivery",
  storageBucket: "engfooddelivery.appspot.com",
  messagingSenderId: "538534307065",
  appId: "1:538534307065:web:1beb3799863acdb8ef6e28"
};

const VAPID = "BJSAGLGiGdYqESEQD0sE4GE6FSKd7XrKefveQ-tVD_npqO6TZo4vO3j3is7IE7mGhlglho368iMhpLMCtPLPoc0"


const app = firebase.initializeApp(firebaseConfig)
//const app = initializeApp(firebaseConfig)

//const db = app.firestore()
const db = getFirestore(app);
//const db = firebase.firestore();
const auth = firebase.auth()
const providerGoogle = new firebase.auth.GoogleAuthProvider()
const firebaseDatabase = getDatabase(app);

export {firebase}
export {db, auth, providerGoogle, firebaseDatabase}

//export const firebaseImpl = initializeApp(firebaseConfig);
//export const firebaseDatabase = getDatabase(firebaseImpl);
//export const db = firebaseImpl.fi()


//export const firebaseAuth = getAuth(firebaseImpl);

export async function getTokenFirebase() {
  try {
    let isSuport = await isSupported()
   // console.log('isSuport', isSuport)
    if (isSuport) {
      const messaging = getMessaging();
      getToken(messaging, { vapidKey: VAPID }).then((currentToken) => {
        if (currentToken) {
        //  console.log('currentToken', currentToken)
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });

      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // ...
      });
    }

  } catch (error4) {
    console.log('error4. '.concat(error4.message));
  }

  /*
    onBackgroundMessage(messaging, (payload) => {
      console.log('[firebase-messaging-sw.js] Received background message ', payload);
      // Customize notification here
      const notificationTitle = 'Background Message Title';
      const notificationOptions = {
        body: 'Background Message body.',
        icon: '/firebase-logo.png'
      };
    
    //  self.registration.showNotification(notificationTitle,notificationOptions);
    });
  */
}
