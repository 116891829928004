import { Space } from 'antd';


export const validateEmail = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase().trim())

}


export const calcularTaxa = (end, bairros, loja) => {
    try {
        var address = { ...end }
        if (!address) {
            address = {}
        }

        if (loja?.informacoes.taxtype === 1) {

            const km = distance(address.latitude, address.longitude, loja?.informacoes?.latitude, loja?.informacoes?.longitude)
            // console.log('km', km)
            var element = loja.taxes.find(e => km > 0 && km <= e.raio / 1000)
            if (element) {
                address.atendido = 1;
                address.taxa = parseFloat(element.tax);

            } else {
                address.taxa = -1;
                address.atendido = 0;
            }

        } else {
            address = JSON.parse(JSON.stringify(address))
            var result = bairros.filter(item => {
                return item.atendido === 1 && item.descricao.toUpperCase() === address.bairro.toUpperCase()
            })

            if (result.length > 0) {
                address.atendido = 1;
                address.taxa = result[0].valor;
            } else {
                address.taxa = -1;
                address.atendido = 0;
            }
        }

    } catch (error) {
        console.log(address)
        address.taxa = -1
        address.atendido = 0;
    }

    return address;

}









export const moneyMask = (value) => {
    try {
        return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } catch (error) {
        return value
    }
}

export const primeiraLetraMaiuscula = (text) => {
    try {
        const palavras = text.split(" ");
        for (let i = 0; i < palavras.length; i++) {
            palavras[i] = palavras[i][0].toUpperCase() + palavras[i].substr(1).toLowerCase();
        }

        return palavras.join(" ")
    } catch (error) {

        return text
    }
}


export const distance = (lat1, lon1, lat2, lon2) => {
    try {
        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344 // para converter para KM
        return dist;
    } catch (error) {
        return 0;
    }
}


export const getDiaSemana = (value) => {
    var dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
    var meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    var dia = new Date(value)
    return dias[dia.getDay()].concat(', ', dia.getDate(), ' ', meses[dia.getMonth()], ' ', dia.getFullYear())

}


export const renderButtonWhatshap = (number, pedido, transaction_id, userName, titulo) => {
 //   console.log(titulo)
    if (number) {
        if (pedido) {
            return <a href={'https://wa.me/'.concat(number, '?text=Olá, tudo bem? ',
                userName ? "Sou " + userName : " ",
                ", nº do pedido  ".concat(pedido),
                transaction_id > 0 ? "  pagamento nº ".concat(transaction_id) : " "
            )}
                target='_blank'>

                <Space direction='horizontal'>
                    <img src={require('../img/whatshap.png')} width={30} alt='fale conosco' title='fale conosco' />
                    {titulo ? titulo : ""}
                </Space>
            </a>
        } else {
            return <a href={'https://wa.me/'.concat(number, '?text=Olá, tudo bem? ',
                userName ? "Sou " + userName : " ", " gostaria de informações"
            )}
                target='_blank'>
               <Space direction='horizontal'>
                    <img src={require('../img/whatshap.png')} width={30} alt='fale conosco' title='fale conosco' />
                    {titulo ? titulo : ""}
                </Space>
            </a>
        }
    } else {
        return <span />
    }
}

