import React, { useEffect, useState } from 'react'
import './userComponent.css'
import { Row, Col, Avatar, Card, Modal, Tag, Input, Button, Space } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Account from '../account'
import InputMask from 'react-input-mask';
import { useRecoilState } from 'recoil'
import { userState } from '../../store/dataRecoil'
import { getAuth, signInAnonymously } from "firebase/auth";


export const UserComponent = (props) => {

    const [user, saveUser] = useRecoilState(userState)
    const [visibleLogin, setVisibleLogin] = useState(false);

    function showAccount(value) {
        setVisibleLogin(value)
    }

    useEffect(() => {
        //   console.log(user)
        loaduser()
    }, [])

    async function loaduser() {
        try {
            const auth = getAuth();
            if (!auth.currentUser) {
                var user2 = await signInAnonymously(auth)
                saveUser({ ...user, uid: user2.user.uid })
            } else {
                //  console.log('ja logado', user)
            }
        } catch (error) {

        }
    }

    return <div>
        {
            user?.isAnonymous === false ? <Card style={{ marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
                size="small"
                onClick={() => {
                    setVisibleLogin(true)
                }}>
                <div className="UserComponent-main">
                    <div className="UserComponent-body">
                        <div className="UserComponent-avater">
                            <Avatar src={user?.photoURL ? user?.photoURL :
                                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'} size="large" />
                        </div>
                        <div className="UserComponent-info">
                            <div><b>{user?.displayName ? user?.displayName : <Tag color="error">Informe seu nome</Tag>}</b></div>
                            <div>{user?.phoneNumber ? user?.phoneNumber : <Tag color="error">Telefone é obrigatório</Tag>}</div>
                            <div>{user?.email ? user?.email : <Tag color="warning">Aconselhamos cadastrar um e-mail</Tag>}</div>
                        </div>
                    </div>
                    <div className="UserComponent-icon">
                        <RightOutlined />

                    </div>
                </div>
            </Card> :
                <Card style={{ marginTop: 5, marginBottom: 5 }} size="small">
                    <Row gutter={[5, 5]}>
                        <Col span={4}>Nome<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></Col>
                        <Col span={20}>
                            <Input value={user?.displayName} maxLength={150} size="small"
                                onChange={(e) => saveUser({ ...user, displayName: e.target.value })} />
                        </Col>

                        <Col span={4}>Celular<span style={{ color: 'red', fontWeight: 'bold' }}>*</span></Col>
                        <Col span={20}>
                            <InputMask
                                mask="99 99999-9999"
                                maskChar=" "
                                value={user?.phoneNumber}
                                onChange={e => saveUser({ ...user, phoneNumber: e.target.value })}>
                                {() => <Input maxLength={16} size="small" />}
                            </InputMask>
                        </Col>
                        <Col span={24}>

                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Space direction='horizontal'>
                                registrar-se ou já possui conta?
                                <Button type='primary' size='small'
                                    style={{ fontSize: '0.85em' }}
                                    onClick={() => {
                                        setVisibleLogin(true)
                                    }}>clique aqui</Button>
                            </Space>

                        </Col>
                    </Row>
                </Card>
        }


        {/* MODAL DE LOGIN */}
        <Modal title={null}
            open={visibleLogin}
            destroyOnClose={true}
            footer={null}
            onOk={() => setVisibleLogin(false)}
            onCancel={() => setVisibleLogin(false)}>
            <Account showAccount={showAccount} />
        </Modal>
    </div>

}

export default UserComponent