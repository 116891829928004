import React, { useEffect, useState } from 'react'
import {
    Button, Flex, Image,
    App, Radio, Badge, Checkbox, Input, Divider, Drawer, InputNumber, 
} from 'antd'
import {  PlusOutlined, MessageOutlined, CheckOutlined, MinusOutlined } from '@ant-design/icons'
import { waiterUserState, mesaState, pedidoWaiterState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import '../waiterOutlet.css'
import { useNavigate, useLocation } from 'react-router-dom'
import devoltaPNG from '../../../../src/img/de-volta.png'
import balancePNG from '../../../../src/img/weight-scale.png'
import { FlexboxGrid } from 'rsuite'


export const WaiterProduto = (props) => {

    const { notification } = App.useApp()
    const navigate = useNavigate()
    const location = useLocation()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [pedido, setPedido] = useRecoilState(pedidoWaiterState)
    const [mesa, setMesa] = useRecoilState(mesaState)
    const [data, setData] = useState([]);
    const [observacoes, setObservacoes] = useState('');
    const [totalGrupos, setTotalGrupos] = useState(0);
    const [maiorValor, setMaiorValor] = useState(0);
    const [complementosSelecionados, setComplementosSelecionados] = useState(0);
    const [disableok, setDisableok] = useState(true);
    const [Quant, setQuant] = useState(1);
    const [open, setOpen] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(true)

    const [quantTemp, setQuantTemp] = useState(1)

    const [produto, setProduto] = useState(JSON.parse(JSON.stringify(location.state.produto)));

    useEffect(() => {
        if (produto.venda === undefined || produto.venda === null)
            produto.venda = 0;

        if (produto.Quant === undefined)
            produto.Quant = 1;

        if (produto.AtivarPromocao) {
            if (produto.AtivarPromocao === 1 && produto.Promo > 0) {
                produto.venda = produto.Promocao;
            }
        }

        var chave = 77777;
        if (produto.grupos) {
            produto.grupos.map(grupo => {
                grupo.data = grupo.itens;
                if (grupo.chave === 0) {
                    grupo.chave = chave;
                    grupo.data.map(subitem => {
                        subitem.idgrupo = chave;
                        return subitem
                    })

                    chave = chave + 1;
                }
                return grupo
            })
        } else {
            produto.grupos = []
        }
        var aux = produto.grupos.find(v => v.chave === 88888)
        if (!aux)
            produto.grupos.push({
                chave: 88888, descricao: 'Alguma observação?', maximo: 150, minimo: 0,
                obrigatorio: 0, isobs: true, itens: [], data: [{ selected: 0 }], produtoid: 0, selected: 0
            })

        setProduto(produto);
        setData(produto.grupos);
        validarProduto();
        return () => { }
    }, []);



    useEffect(() => {
        // console.log("PRODUTO", produto)
        validarProduto()
        return () => { }
    }, [data, Quant, forceUpdate])

    
    function validarProduto() {
        var obrigatorios = 0;
        var validados = 0;

        for (var i = 0; i < data.length; i++) {
            if (data[i].obrigatorio === 1) {
                obrigatorios++;
                var selected = 0;
                // eslint-disable-next-line no-loop-func
                data[i].itens.map(item => {
                    selected += item.selected;
                    return item
                });

                if (selected >= data[i].minimo)
                    validados++;
            }
        }
        var tem = obrigatorios <= validados ? false : true
        setDisableok(tem);
    }


    function checkBoxPress(item, section, index) {
        var optionMax = 0;
        var count = 0;
        var maiorVal = 0; //Utilizado caso this.state.produto.calcularComplenetos == 1
        item.selected = item.selected === 0 ? 1 : 0;
        item.quant = item.selected;
        item.Quant = item.selected
        section.selected = item.selected;

        for (var i = 0; i < section.data.length; i++) {
            if (i !== index) {
                if (section.chave !== 99999 && produto.calcularComplenetos > 1) {
                    section.data[i].selected = 0;
                    section.data[i].quant = 0;
                    section.data[i].Quant = 0;
                } else {
                    //section.data[i].selected = 0;
                }
                section.selected += section.data[i].selected;
            }
            else {
                section.data[i] = item;
            }

            //Verifica se é o de maior valor
            if (section.chave === 99999) {
                optionMax = section.data[i].selected + optionMax; //Conta os selecionados
                if (produto.calcularComplenetos < 2) {
                    if (section.data[i].selected > 0) {
                        count = count + 1;
                        if (maiorVal < section.data[i].valor) {
                            maiorVal = section.data[i].valor;
                        }
                    }
                }
            }

        }

        if (optionMax > section.maximo) {
            if (item.selected > 0) {
                item.selected = 0;
                item.quant = 0;
                item.Quant = 0
                count = count - 1;
            }
            section.selected = item.selected;
        }

        if (produto.calcularComplenetos < 2) {
            if (section.chave === 99999) {
                for (var i = 0; i < section.data.length; i++) {
                    if (section.data[i].selected > 0) {
                        section.data[i].quant = 1 / count;
                        section.data[i].Quant = 1 / count;
                        if (produto.calcularComplenetos === 1) {
                            section.data[i].valornew = maiorVal;
                        }
                    }
                }
            }
        }
        for (var i = 0; i < data.length; i++) {
            if (section.chave === data[i].chave) {
                data[i] = section;
                break;
            }
        }
        setData(data);
        setMaiorValor(maiorVal);
        setComplementosSelecionados(count);
        validarProduto();
        calcularTotal();
    }




    function clickAdd(item, section, index, valor) {
        var selecionada = 0;
        try {
            if (valor === 1) {
                for (var i = 0; i < section.data.length; i++) {
                    selecionada += section.data[i].selected;
                }

                if (selecionada < section.maximo) {
                    item.selected += 1;
                    section.selected += 1;
                }

            } else {
                for (var i = 0; i < section.data.length; i++) {
                    selecionada += section.data[i].selected;
                }

                if (item.selected >= 1) {
                    item.selected -= 1;
                    section.selected -= 1;
                }
            }

            item.quant = item.selected;
            item.Quant = item.selected;
            section.data[index] = item;
            for (var i = 0; i < data.length; i++) {
                if (section.chave === data[i].chave) {
                    data[i] = section;
                    break;
                }
            }

            setForceUpdate(!forceUpdate)
            setData(data);
            validarProduto();
            calcularTotal();


        } catch (erro) {
            notification.error({
                description: erro.message,
                duration: 3,
                message: 'Atenção!'
            })
        }
    }

    function changeQuant(valor) {
        produto.Quant = produto.Quant + valor;
        produto.quant = produto.Quant;
        if (produto.Quant < 1) {
            produto.Quant = 1;
            produto.quant = 1;
        }
        setQuant(produto.Quant);
        setProduto(produto);
        calcularTotal();
        //  console.log("produto.Quant",produto.Quant)
    }



    // Adiciona o produto ao carrinho
    async function addProduto() {
        try {
            // var produto = this.state.produto;
            produto.desconto = 0;
            // var data = this.state.data;
            var complementos = [];
            data.map(grupo => {
                if (grupo.chave !== 88888 && grupo.chave > 0) {
                    //----------- TAMANHOS ---------------
                    if (grupo.chave === 99998 || grupo.chave === 99999) {
                        var temp = 0;
                        if (produto.calcularComplenetos === 1) {
                            // console.log('produto.calcularComplenetos === 1')
                            grupo.data.map((item) => {
                                //   console.log(item)
                                if (item.selected > 0) {
                                    if (grupo.chave === 99999)
                                        item.valor = item.valornew;
                                    else {
                                        if (item.valor > temp)
                                            temp = item.valor;
                                    }
                                }
                                return item
                            });
                            if (grupo.chave === 99998) {
                                grupo.data.map((item) => {
                                    item.valor = temp;
                                    return item
                                });
                            }
                        }
                    }
                    //---------------------------
                    grupo.data.map((item) => {
                        if (item.selected > 0) {
                            complementos.push({
                                ProdutoID: item.produtoid,
                                NomeReduzido: item.nome,
                                pesoTotal: 1,
                                // Quant: item.quant, //removido em 04/02/2021 
                                Quant: item.quant * produto.Quant, //add 04/02/2021
                                quant: item.quant * produto.Quant, //add 04/02/2021
                                Venda: item.valor,
                                desconto: 0,
                                isCombo: grupo.chave < 99998 ? true : false
                            });
                        }
                        return item
                    }
                    )
                }
                return grupo
            })

            produto.complementos = complementos;
            produto.valorUnitarioFinal = (produto.venda + totalGrupos);
            produto.Obs = observacoes;

            await addItemPedido(produto, mesa.mesaID)
            navigate('/manager/categorias')

        } catch (erro) {
            console.log('error', erro)
        }

    }


    function addItemPedido(item, mesaid) {
        var value = new Date().getTime();
        var carrinho = JSON.parse(JSON.stringify(pedido));
        try {
            if (!carrinho) {
                carrinho = {
                    createdAt: value,
                    local: mesaid,
                    itens: [],
                    type: 'MESA'
                };
            }

            if (!carrinho.itens)
                carrinho.itens = []

            carrinho.createdAt = new Date().getTime();
            carrinho.itens.push(item);
            carrinho.path = mesaid;

        } catch (erro) {
            console.log(erro.message)
            carrinho = {
                createdAt: new Date().getTime(),
                local: mesaid,
                itens: [],
                type: 'MESA'
            }
        }

        //  console.log(carrinho)
        setPedido(carrinho)

    }



    function calcularTotal() {
        try {
            // console.log("Calcular", 'calculando...')
            var totalGrupos = 0;
            var totalComplementos = 0;
            var grupos = data;
            for (var i = 0; i < (grupos.length) - 1; i++) {
                if (grupos[i].chave > 0) {
                    if (grupos[i].chave < 88888) {
                        // eslint-disable-next-line no-loop-func
                        grupos[i].data.map((item) => {
                            if (item.quant === undefined) {
                                item.quant = 0;
                                item.Quant = 0
                            }
                            totalGrupos = totalGrupos + (item.valor * item.quant);
                            return item
                        });

                    } else if (grupos[i].chave === 99999) {
                        if (produto.calcularComplenetos === 2) { // adiciona o valor de cada complemento
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                if (item.quant === undefined) {
                                    item.quant = 0;
                                    item.Quant = 0
                                }
                                totalComplementos = totalComplementos + (item.valor * item.quant);
                                return item
                            });
                        } else if (produto.calcularComplenetos === 1) //apenas o de maior valor
                        {
                            var temp = 0;
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                if (item.selected === undefined)
                                    item.selected = 0;
                                if (item.selected > 0) {
                                    if (item.valor > temp)
                                        temp = item.valor;
                                }
                                return item
                            });
                            totalComplementos = temp;

                        } else { // media dos complementos
                            var media = 0;
                            var total = 0;
                            var itensSelecionado = 0;
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                if (item.selected > 0) {
                                    itensSelecionado += item.selected;
                                    total += item.valor;
                                    media = total / itensSelecionado;
                                }
                                return item
                            });
                            totalComplementos = media;
                        }

                    } else if (grupos[i].chave === 99998) {
                        // console.log("99998", 'entrou aqui')
                        if (produto.calcularComplenetos === 0 || produto.calcularComplenetos === 2) { // adiciona o valor de cada complemento
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                totalComplementos = totalComplementos + (item.valor * item.selected);
                                return item
                            });
                        } else if (produto.calcularComplenetos === 1) //adiciona o de maior valor
                        {
                            // console.log("produto.calcularComplenetos === 1","Linha 542")
                            var temp = 0;
                            var totalsavoresSel = 0;
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                totalsavoresSel = totalsavoresSel + item.selected;
                                if (item.selected > 0) {
                                    if (item.valor > temp)
                                        temp = item.valor;
                                }
                                return item
                            });
                            totalComplementos = (temp * totalsavoresSel);
                        }
                    }
                    else {
                        if (produto.calcularComplenetos === 0 || produto.calcularComplenetos === 2) { // adiciona o valor de cada complemento
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                totalComplementos = totalComplementos + (item.valor * item.selected);
                                return item
                            });
                        } else if (produto.calcularComplenetos === 1) //adiciona o de maior valor
                        {
                            var temp2 = 0;
                            var totalsavoresSel = 0;
                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                totalsavoresSel = totalsavoresSel + item.selected;
                                if (item.selected > 0) {
                                    if (item.valor > temp2)
                                        temp2 = item.valor;
                                }
                                return item
                            });

                            totalComplementos = (temp2 * totalsavoresSel);

                        } else {

                            // eslint-disable-next-line no-loop-func
                            grupos[i].data.map((item) => {
                                totalComplementos = totalComplementos + (item.valor * item.selected);
                                return item
                            });
                        }
                    }
                }
            }
            totalGrupos += totalComplementos;
            setTotalGrupos(totalGrupos)
            setProduto(produto);
            setForceUpdate(!forceUpdate)
        } catch (error) {
            console.log(error)
        }
    }

    //HEARDER  
    function renderHeader(sec) {
        // console.log("SECTION", section);
        var section = JSON.parse(JSON.stringify(sec))
        if (section.isobs)
            section.case = 1;
        else if (section.obrigatorio === 1 && section.selected !== section.maximo)
            section.case = 2;
        else if (section.data.length === 0)
            section.case = 0;
        switch (section.case) {
            case 0:
                return <div></div>
            case 1:
                return <Flex vertical>
                    <Flex gap={'small'} style={{ backgroundColor: 'whitesmoke' }}>
                        <MessageOutlined style={{ marginLeft: 5 }} />
                        <span>{section.descricao === '' ? 'Selecione' : section.descricao}</span>
                    </Flex>
                    <Flex style={{ marginTop: 2 }}>
                        <Input.TextArea
                            placeholder="Digite aqui"
                            value={observacoes}
                            onChange={(e) => setObservacoes(e.target.value)}
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}>
                        </Input.TextArea>
                    </Flex>

                </Flex>

            case 2:
                return <Badge.Ribbon
                    color={(section.obrigatorio === 1 && section.selected < section.minimo) ? "red" : 'green'}
                    text={(section.obrigatorio === 1 && section.selected < section.minimo) ?
                        <div style={{ fontSize: '0.8em', justifyContent: 'center' }}>
                            Obrigatório
                        </div> :
                        <div style={{ fontSize: '0.8em', justifyContent: 'center' }}>Opcional</div>}>
                    <Flex style={{ backgroundColor: 'whitesmoke' }}>
                        <Flex vertical style={{ marginLeft: 5 }}>
                            <span style={{ marginLeft: 5 }}> {section.descricao === '' ? 'Selecione' : section.descricao} </span>
                            <span style={{ marginLeft: 5, marginRight: 5, fontSize: '0.8em' }}>
                                {
                                    section.selected.toString().concat(" de ", section.maximo)
                                }
                            </span>
                        </Flex>

                    </Flex>
                </Badge.Ribbon>

            default:
                return <Flex vertical style={{ backgroundColor: 'whitesmoke' }}>
                    <Flex align='center' style={{ fontSize: '0.9em', marginLeft: 5 }}>
                        <span>{section.descricao === '' ? 'Selecione' : section.descricao}</span>
                    </Flex>
                    <Flex style={{ fontSize: '0.8em', marginLeft: 5 }}>{section.selected.toString().concat(" de ", section.maximo)}</Flex>
                </Flex>
        }
    }


    //RENDER ITEM
    function renderItem(item, section, index) {

        return (<Flex justify="space-around" align="center">
            <Flex style={{ flex: 1 }}>
                <Flex vertical style={{ fontSize: '0.9em' }}>
                    <div style={{ fontSize: '0.9em' }}>
                        {item.nome}
                    </div>
                    <div>
                        {item.valor !== 0 && <span style={{ fontSize: '0.85em', color: 'blue' }}>
                            {item?.valor && '+ R$'.concat(item?.valor?.toFixed(2).toString())}
                        </span>
                        }
                    </div>
                </Flex>
            </Flex>
            <Flex>
                {
                    (section.maximo === 1 || (produto.calcularComplenetos !== 2 && section.chave === 99999)) ?
                        <div style={{ flexDirection: 'row', alignItems: 'flex-end', marginRight: 0 }}>
                            <Checkbox checked={item.selected === 1 ? true : false}
                                onChange={() => checkBoxPress(item, section, index)} />
                        </div> :
                        <Flex align="center">
                            <Flex>
                                <MinusOutlined onClick={() => clickAdd(item, section, index, -1)}
                                    style={{
                                        color: item.selected === 0 ? 'transparent' : 'red',
                                        height: 32
                                    }} />
                            </Flex>
                            <Flex>
                                <div style={{ minWidth: 20, textAlign: 'center' }}>
                                    {item.selected === 0 ? '' : item.selected}
                                </div>
                            </Flex>
                            <Flex item>
                                <PlusOutlined style={{ color: 'green', height: 32 }}
                                    onClick={() => clickAdd(item, section, index, 1)} />
                            </Flex>
                        </Flex>
                }
            </Flex>
        </Flex>
        )
    }


    // console.log(data)
    return <>
        <main style={{flex:1, overflowY:'auto', overflowX:'hidden', padding:5}}>
            <Flex justify='space-between'>
                <div style={{ fontSize: '1.1em', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                    <Image
                        style={{ objectFit: 'contain' }}
                        preview={false}
                        height={56}
                        width={57}
                        src={produto?.tamanhoid === 0 ?
                            'https://menuengfood.com.br/images/mobile/'.concat(waiterUser?.id, '/produtos/p', produto.produtoID, '.png') :
                            'https://menuengfood.com.br/images/mobile/'.concat(waiterUser?.id, '/tamanhos/t', produto.tamanhoid, '.png')} />
                    <div style={{ marginLeft: 10 }}>
                        <div> {produto.nome} </div>
                        <div style={{ fontWeight: 'normal', color: 'green' }}>
                            {produto.venda > 0 && 'R$'.concat(produto.venda.toFixed(2))}
                        </div>
                    </div>

                </div>

                <Button icon={<Image
                    height={18}
                    style={{ objectFit: 'contain' }}
                    alt='volta'
                    preview={false}
                    src={devoltaPNG} />} type='text' onClick={() => navigate(-1)}> voltar</Button>

            </Flex>
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <Flex vertical>

                {data.map((section, index) => {
                    return <div key={section.descricao.concat(section.chave, '-', index)}>
                        {renderHeader(section)}
                        {
                            section.data.map((item, indexItem) => (
                                <div key={'grupo-'.concat(item.idappgrupoitens, '-', indexItem)}>
                                    {
                                        renderItem(item, section, indexItem)
                                    }
                                </div>
                            ))
                        }

                    </div>
                })}


            </Flex>
        </main>
        <footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12}>
                    <Radio.Group onChange={(e) => { }}>
                        <Radio.Button style={{ color: 'red', fontWeight: 'bold' }} value="-1"
                            onClick={() => changeQuant(-1)}>-</Radio.Button>
                        <Radio.Button value="0" onClick={() => {
                            if (produto.unidade !== "UN") {
                                setQuantTemp(produto.Quant)
                                setOpen(true)
                            }
                        }}>{produto.Quant}</Radio.Button>
                        <Radio.Button style={{ color: 'blue', fontWeight: 'bold' }} value="1"
                            onClick={() => changeQuant(1)}>+</Radio.Button>
                        {produto.unidade !== "UN" && <Radio.Button onClick={() => {
                            if (produto.unidade !== "UN") {
                                setQuantTemp(produto.Quant)
                                setOpen(true)
                            }
                        }}>
                            <Image
                                src={balancePNG}
                                height={15}
                                preview={false}
                                width={15}
                                style={{ objectFit: 'contain' }}
                                alt='balance' />
                        </Radio.Button>
                        }
                    </Radio.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <Button
                        block
                        type='primary'
                        icon={<CheckOutlined />}
                        onClick={() => addProduto()}>
                        {'ADICIONAR R$'.concat(((produto.venda + totalGrupos) * produto.Quant).toFixed(2).toString())}
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>



            
        </footer>

        <Drawer
            title={<div onClick={() => {

                produto.Quant = quantTemp;
                produto.quant = produto.Quant;
                if (produto.Quant <= 0) {
                    produto.Quant = 1;
                    produto.quant = 1;
                }
                setQuant(produto.Quant);
                setProduto(produto);
                calcularTotal();
                setOpen(false)

            }} style={{ color: 'white' }}>Salvar</div>}
            closeIcon={<Image src={devoltaPNG} height={22} width={22} style={{ objectFit: 'contain' }} preview={false}
            />}
            placement={"bottom"}
            height={200}
            closable={true}
            onClose={() => {
                produto.Quant = quantTemp;
                produto.quant = produto.Quant;
                if (produto.Quant <= 0) {
                    produto.Quant = 1;
                    produto.quant = 1;
                }
                setQuant(produto.Quant);
                setProduto(produto);
                calcularTotal();
                setOpen(false)
            }}
            open={open}>
            <Flex vertical>
                Quantidade:
                <Flex align='center'>
                    <InputNumber
                        style={{ marginRight: 5 }}
                        decimalSeparator=','
                        precision={3}
                        value={quantTemp}
                        onChange={(e) => setQuantTemp(e)} />

                    {' '.concat(produto.unidade)}

                </Flex>

            </Flex>
        </Drawer>
    </>

}
