
import * as CryptoJS from 'crypto-js'
const secretKey =  'JInrzsDakpVsyii'

export const valorMonetarioOnChange = (event) => {
    var valor = event.target.value.trim()                                                                
    if (valor.length > 0) {
      valor = valor.replace('.', '')
      if (valor.length === 2)
        valor = "0".concat(valor)
      if (valor === 1)
        valor = "00".concat(valor)
      valor = valor.slice(0, valor.length - 2) + "." + valor.slice(valor.length - 2);
    } else {
      if (valor.trim() === "")
        valor = "0.00"
    }
    if (valor === NaN)
      valor = 0;
 //   console.log("onChange", parseFloat(valor).toFixed(2))
    return parseFloat(valor).toFixed(2);

}



export const valorNumericoOnChange = (event) => {
    var valor = event.target.value.trim()                                                                
    if (valor.length > 0) {
      valor = valor.replace('.', '')
     // if (valor.length === 2)
     //   valor = "0".concat(valor)
    //  if (valor === 1)
    //    valor = "00".concat(valor)
    //  valor = valor.slice(0, valor.length - 2) + "." + valor.slice(valor.length - 2);
    } else {
      if (valor.trim() === "")
        valor = "0"
    }
    if (valor === NaN)
      valor = 0;
  //   console.log("onChange", parseFloat(valor).toFixed(2))
    return parseInt(valor);
  
  }


  export const titleize = (text) => {

    if(!text)
      text = ""

    var loweredText = text.toLowerCase().replace('  ', ' ');
    var words = loweredText.split(" ");
    for (var a = 0; a < words.length; a++) {
        var w = words[a];
        var firstLetter = w[0];
        if (w.length > 2) {
            w = firstLetter.toUpperCase() + w.slice(1);
        } else {
            w = firstLetter + w.slice(1);
        }

        words[a] = w;
    }
    return words.join(" ");
}


export const generateColor =() =>{
  const letters = '0123456789ABCDEF';
  let color = '#';    
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }    
  return color;
  
}



export const encrypt = ( plainText ) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, secretKey).toString()
  return cipherText
}

export const decrypt = ( cipherText ) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey )
  const plainText = bytes.toString(CryptoJS.enc.Utf8)
  return plainText
}



export const arrayCopy = (data) => {
  return data.map((item) => ({
      ...item ,
      x:false    
  }));
 }