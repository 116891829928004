import React, { useEffect, useState } from 'react'
import { Button, Flex, Table, Space, Typography, App, Popover, Image, Layout } from 'antd'
import { socket } from '../../../service/socket'
import { titleize } from '../../../service/util'
import { ArrowLeftOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons'
import { waiterUserState, mesaState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import '../waiterOutlet.css'
import complemntoPNG from '../../../img/abaixo-a-direita.png'
import keyPNG from '../../../img/lock2.png'
import { useNavigate } from 'react-router-dom'
import devoltaPNG from '../../../../src/img/de-volta.png'
import { FlexboxGrid } from 'rsuite'

export const WaiterMesa = (props) => {

    const { notification } = App.useApp()
    const navigate = useNavigate()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [mesa, setMesa] = useRecoilState(mesaState)

    const [cupom, setCupom] = useState(null)
    const [status, setStatus] = useState(0)
    const [cliente, setCliente] = useState(mesa.Cliente)


    useEffect(() => {
        console.log('conta')
        getConta()
    }, [])





    async function getConta() {
        socket.emit('getConta', waiterUser?.database, mesa, (value) => {
            value.data.itensjson.push({
                footer: true,
                descricao: '(=) SUBTOTAL',
                quant: 1,
                valorUnit: value.data.produtos
            })

            value.data.itensjson.push({
                footer: true,
                descricao: '(+) SERVICOS',
                quant: 1,
                valorUnit: value.data.servicos
            })
            if (value.data.couvert > 0) {
                value.data.itensjson.push({
                    footer: true,
                    descricao: '(+) COUVERT',
                    quant: 1,
                    valorUnit: value.data.couvert
                })
            }
            value.data.itensjson.push({
                footer: true,
                descricao: '(=) TOTAL',
                quant: 1,
                valorUnit: value.data.subtotal
            })
            setCliente(value.data.nomecliente)
            setCupom(value.data)
        });
    }


    async function SalvarNome(nome) {
        try {
            var com = {
                comandaID: mesa.comandaID,
                garconID: waiterUser.funid,
                ComandaNome: mesa.ComandaNome,
                tipoAtendimento: 2,
                vendaID: mesa.vendaID,
                cliente: nome,
                nomecliente: nome
            }
            socket.emit('setNomeCliente', waiterUser?.database, com, (value) => {
                setCliente(nome)
            });

        } catch (error) {
            notification.error({
                duration: 2,
                description: error.message
            })
        }
    }



    return <>
        <div style={{ flex: 1, overflow: 'auto' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>

                <Space direction='horizontal'>
                    <strong style={{ fontSize: '1.2em' }}>
                        {mesa.tipoAtendimento === 3 ? 'COMANDA ' : "MESA "}  {mesa.mesaID}
                    </strong>
                </Space>

                <Space direction='horizontal' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography.Paragraph> CLIENTE: </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginLeft: 10 }}
                        editable={{
                            onChange: SalvarNome
                        }}>
                        {cliente}
                    </Typography.Paragraph>
                </Space>

                {
                    mesa.status === 3 && <Typography.Text>
                        Reservada para: {mesa.reserva}
                    </Typography.Text>
                }

            </div>
            
                {renderCupom()}
            

        </div>

        <Layout.Footer style={{
            position: 'sticky',
            bottom: 0,
            padding:0
        }}>
            <FlexboxGrid >
                <FlexboxGrid.Item colspan={8}>
                    <Button icon={<Image
                        height={18}
                        style={{ objectFit: 'contain' }}
                        alt='volta'
                        preview={false}
                        src={devoltaPNG} />} block
                        onClick={() => navigate(mesa?.tipoAtendimento === 3 ? '/manager/comandas' : '/manager')}>
                        VOLTAR</Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <Button icon={<PrinterOutlined />} type='primary' block
                        onClick={() => pedirConta()}>IMPRIMIR</Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <Button icon={<PlusOutlined />} type='primary' style={{ backgroundColor: 'green' }}
                        block
                        onClick={() => adicionar()}>ADICIONAR</Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </Layout.Footer>
    </>


    async function adicionar() {
        try {
            if (mesa.status === 3) {

                socket.emit('setMesaOpen', waiterUser?.database, {
                    mesaID: mesa.chave,
                    garconID: waiterUser.funid,
                    mesaNome: mesa.numero,
                    tipoAtendimento: 2,

                }, (value) => {
                    //  console.log('value', value)
                    notification.info({
                        duration: 2,
                        closable: false,
                        description: 'Mesa aberta com sucesso!'
                    })
                });

                /*
            if (value.status === 200) {
                if (result.data.vendaID > 0) {
                    mesa.status = 1;
                    mesa.vendaID = value.data.vendaID;
                    setMesa(mesa)
                } else {
                    notification.warning({
                        description: 'Não foi possível efetuar a abertura da mesa!!',
                        duration: 2
                    })
                }

            } else {
                notification.warning({
                    description: 'Erro ao efetuar comunicação com servidor!',
                    duration: 2
                })
            }
            */
            } else
                navigate('/manager/categorias')
        } catch (error) {

        }
    }



    async function pedirConta() {



        try {
            socket.emit('getImprimir', waiterUser?.database, {
                mesaID: mesa.mesaID,
                vendaID: cupom.vendaID
            }, (value) => {
                console.log('getImprimir: ', value)
                notification.info({
                    duration: 2,
                    closable: false,
                    description: 'Conta solicitada com sucesso!'
                })
            });

        } catch (erro) {
            console.log("Error", erro)
            // setNotification("Atenção", erro.message, 'danger')
        }
    }


    function renderCupom() {

        return < Table dataSource={cupom?.itensjson} size='small' pagination={false} style={{ marginBottom: 15 }}>
            <Table.Column title="ITEM" dataIndex={'ID'} render={(text, record, index) => {

                if (record.footer === true) {
                    return ""
                } else
                    return <Flex> {record.iscomplemento &&
                        <img src={complemntoPNG} height={15} alt='item' style={{ marginRight: 5, marginLeft: 5 }} />}
                        {
                            <span style={{
                                fontSize: !record.iscomplemento ? '1em' : '0.85em',
                                fontStyle: record.iscomplemento ? 'italic' : 'normal'
                            }}> {titleize(record.descricao.replace('  ', ' ').replace('   ', ' ').trim())} </span>
                        }
                    </Flex>
            }} />

            <Table.Column title="" width={140} dataIndex={'ID'} render={(text, record, index) => {
                if (record.footer === true) {
                    return <div style={{ textAlign: 'right', fontWeight: 'bold' }}> {record.descricao} </div>
                } else {
                    return <div style={{ textAlign: 'right' }}> {record.quant.toFixed(Number.isInteger(record.quant) ? 0 : 3)} x </div>
                }

            }} />

            <Table.Column title="" width={150} dataIndex={'ID'} render={(text, record, index) => {
                if (record.footer === true) {
                    return <div style={{ textAlign: 'right', fontWeight: 'bold' }}> R$ {(record.valorUnit).toFixed(2)}  </div>
                } else
                    if (record.valorUnit === 0)
                        return ""
                    else
                        return <div style={{ textAlign: 'right' }}> {(record.quant * record.valorUnit).toFixed(2)}  </div>
            }} />
        </Table>




    }
}