import React, { useEffect, useState } from 'react'
import {
    Button, Flex, Col, Row, App,
    Breadcrumb, Divider,
    Input
} from 'antd'
import { titleize } from '../../service/util'
import { DeleteOutlined, PlusOutlined, CheckOutlined, MinusOutlined } from '@ant-design/icons'
import { waiterUserState, mesaState, pedidoCardapioState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'
//import '../../waiterOutlet.css'
import complemntoPNG from '../../img/abaixo-a-direita.png'
import notaPNG from '../../img/nota.png'
import { useNavigate } from 'react-router-dom'
import { api } from '../../service/api.service'
import { setMonth } from 'rsuite/esm/internals/utils/date'


export const PedidoCardapio = (props) => {


    const { notification } = App.useApp()
    const navigate = useNavigate()
    const [pedido, setPedido] = useRecoilState(pedidoCardapioState)
    

    const [subtotal, setSubtotal] = useState(0)
    const [processando, setProcessando] = useState(false)

    useEffect(() => {
        calcularSubTotal()
        if (pedido.itens.length === 0)
            setProcessando(true)
        else
            setProcessando(false)
    }, [pedido])



    function calcularSubTotal() {
        var total = 0;
        pedido.itens.map((item, index) => {
            total = (item.valorUnitarioFinal * item.Quant) + total;
            return item
        })
        setSubtotal(total)
    }

    function changeQuant(it, index, quant) {
        var item = JSON.parse(JSON.stringify(it));
        var itens = JSON.parse(JSON.stringify(pedido.itens));

        item.Quant = item.Quant + quant;
        item.quant = item.Quant;

        item.complementos.map((comp, index) => {
            comp.Quant = comp.Quant + quant
            return comp
        })
        if (item.Quant < 1) {
            itens.splice(index, 1);
        } else {
            itens[index] = item;
        }
        // pedido.itens = itens;
        setPedido({ ...pedido, itens: itens })
        // setForceUpdate(!forceUpdate)
    }


    return <>
        <main >
            <Flex align='center' style={{marginBottom:5, marginRight:-10}}>
                {"Nome: "}
                <Input size='small' placeholder='informe seu nome'
                value={pedido?.nome}
                style={{marginLeft:3}}
                onChange={(e)=> setPedido({...pedido, nome: e.target.value})}/>
            </Flex>

            <Flex vertical>
                <div style={{
                    backgroundColor: 'whitesmoke', marginTop: 0, marginLeft: -10, marginRight: -10,
                    padding: 5
                }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            {' Controle de Mesas'}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {'(mesa '.concat(pedido?.data?.mesaID, ')')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {pedido.itens.map((item, index) => (
                    <React.Fragment key={'pedido-'.concat(index, '-', item.nome)}>
                        <Row>
                            <Col flex={'auto'}><b> {titleize(item.nome)} </b> </Col>
                            <Col flex={'100px'}>

                                {item.Quant.toFixed(Number.isInteger(item.Quant) ? 0 : 3)} x

                            </Col>
                            <Col flex={'100px'} style={{ textAlign: 'right' }}>
                                R$ {item.complementos.length > 0 ? (item.Quant * item.venda).toFixed(2)
                                    :
                                    (item.Quant * item.valorUnitarioFinal).toFixed(2)
                                } </Col>
                        </Row>
                        {
                            item.complementos.map((item2, index2) => (
                                <Row>
                                    <Col flex={'auto'}>
                                        <img src={complemntoPNG} height={15} alt='item' style={{ marginRight: 5, marginLeft: 5 }} />
                                        {titleize(item2.NomeReduzido)} </Col>
                                    <Col flex={'100px'}> {item2.Quant.toFixed(Number.isInteger(item2.Quant) ? 0 : 3).concat(' x ')} </Col>
                                    <Col flex={'100px'} style={{ textAlign: 'right' }}> R$ {(item2.Quant * item2.Venda).toFixed(2)} </Col>
                                </Row>
                            ))}

                        {
                            item.Obs &&
                            <Row>
                                <Col flex={'auto'} style={{ color: 'darkgray', fontStyle: 'italic' }}>
                                    <img src={notaPNG} height={15} alt='item' style={{ marginRight: 5, marginLeft: 5 }} />
                                    {' '}
                                    {item.Obs}
                                </Col>
                            </Row>
                        }

                        {
                            item.complementos.length > 0 &&
                            <Row>
                                <Col flex={'auto'} style={{ textAlign: 'right' }}>
                                    R$ {(item.Quant * item.valorUnitarioFinal).toFixed(2)}
                                </Col>
                            </Row>
                        }

                        <Row >
                            <Col flex={'auto'}>
                                <Flex justify='center'>
                                    <Button onClick={() => changeQuant(item, index, -1)}
                                        type="primary"
                                        style={{ backgroundColor: 'rebeccapurple', marginRight: 5 }}
                                        size='small' >
                                        <MinusOutlined style={{ color: '#fff' }} />
                                    </Button>
                                    <Button onClick={() => changeQuant(item, index, 1)}
                                        type='primary'
                                        size='small'
                                        style={{ marginLeft: 5 }}>
                                        <PlusOutlined />
                                    </Button>
                                </Flex>

                            </Col>
                        </Row>


                        <Divider style={{ marginTop: 2, marginBottom: 2 }} />

                    </React.Fragment>
                ))}
                <Row>
                    <Col flex={'auto'} style={{ textAlign: 'right' }}>
                        <strong>  (=) SUBTOTAL </strong>
                    </Col>
                    <Col flex={'200px'} style={{ textAlign: 'right' }}>
                        <strong> R$ {subtotal.toFixed(2)} </strong>
                    </Col>
                </Row>

                <Flex vertical>
                    <Flex></Flex>
                </Flex>
            </Flex>

        </main>
    </>




}
