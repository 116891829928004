import React, { useState } from 'react'
import './informacoes.css'
import { Tag, Image, Table, Avatar, Divider, List } from 'antd'
import delivery from '../../img/delivery-man.png'
import balcao from '../../img/takeaway.png'
import { InfoCircleOutlined, InfoCircleFilled, CheckOutlined, CloseOutlined, ClockCircleOutlined } from '@ant-design/icons'
import ZAP_PNG from '../../img/whatshap.png'

const { Column } = Table


export const Informacoes = (props) => {


    const { open } = props;
    const [loja, setLoja] = useState(props.loja)

    const dia = new Date().getDay()

    return <div className="div-info-main">

        <div style={{
            display: 'flex', flexDirection: 'row',
            alignItems: 'center', justifyContent: 'space-between'
        }}>
            <div style={{ fontWeight: 700, fontSize: '1.3em' }}>{loja?.informacoes?.Fantasia}</div>
            <Avatar src={loja?.url} shape='square' />
        </div>

        {
            loja?.informacoes?.exibirEndereco === 1 && <div>               
                <div className="div-info-text" style={{ fontStyle: 'italic' }}>
                    {loja && loja?.informacoes?.Endereco.concat(', ', loja?.informacoes?.Bairro, ' - ', loja?.informacoes?.Cidade)}
                   {" / "} {loja && loja?.informacoes?.Telefone}
                </div>
            </div>
        }

       

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', marginBottom:5 }}>
            <img alt='zap' src={ZAP_PNG} height={25} style={{ marginRight: 10 }} />
            <Tag style={{ marginTop: 5, width: 70, textAlign: 'center' }}
                color={open ? '#87d068' : '#f50'}>
                {open ? 'aberto' : 'fechado'}
            </Tag>
        </div>

      <i>  Pedido mínimo R$ {loja?.informacoes?.minimo.toFixed(2)} </i>

        <Divider style={{ marginBottom: 10, marginTop:10 }} />
        <div style={{ fontWeight: 600, letterSpacing: 0.355, fontSize: '1.2em', marginBottom: 10 }}>
            Tipos de Serviço
        </div>

        <List itemLayout="horizontal" size='small' bordered >
            <List.Item
                actions={[<CheckOutlined style={{ color: 'blue' }} />]}>
                <List.Item.Meta
                    avatar={<Avatar src={delivery} size={'small'} />}
                    description={<span style={{ color: 'black' }}>Delivery </span>}
                />
            </List.Item>
        </List>
        {
            loja?.informacoes?.retirarLocal === 1 && <List itemLayout="horizontal" size='small' bordered style={{ marginTop: 5 }}>
                <List.Item
                    actions={[<CheckOutlined style={{ color: 'blue' }} />]}>
                    <List.Item.Meta
                        avatar={<Avatar src={balcao} size={'small'} />}
                        description={<span style={{ color: 'black' }}>Retirar na loja </span>}
                    />
                </List.Item>
            </List>
        }

        <div style={{ width: '100%', marginTop: 20 }}>
            <div className="div-info-header">Horario de Funcionamento</div>
            <div className="div-info-header">
                <Table showHeader={false}
                    size="small"
                    rowKey={'DiaSemana'}
                    pagination={false}
                    bordered dataSource={loja?.funcionamento}>
                    <Column key={'DiaSemana'} dataIndex={"DiaSemana"} width={100}
                        render={(text, record) => {
                            return <span style={{ color: dia === record.dia ? 'royalblue' : 'black' }}>
                                {text}
                            </span>
                        }} />
                    <Column key={'id'} dataIndex={"id"}
                        render={(text, item) => {
                            return <span style={{ color: dia === item.dia ? 'royalblue' : 'black' }}>
                                <ClockCircleOutlined style={{ fontSize: 12, marginRight: 10 }} />
                                {
                                    item.turnos === 2 ? (item.hora1.substring(0, 5).concat(' às ', item.hora2.substring(0, 5), ' e ', item.hora3.substring(0, 5), ' às ', item.hora4.substring(0, 5))) :
                                        item.turnos === 1 ? (item.hora1.substring(0, 5).concat(' às ', item.hora2.substring(0, 5))) :
                                            'Fechado'

                                } </span>
                        }}
                    />
                </Table>
            </div>


            <br />
        </div>

    </div>

}

export default Informacoes