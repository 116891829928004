import React, { useEffect, useState, useRef } from 'react'
import './orders.css'
import '../addressComponent/addressComponent.css'
import './style.css'
import {
    Tabs, Timeline, Row, Col, Divider, Tag, Button, Modal, Alert,
    Skeleton, Typography, Card, Avatar, Rate, Space, Drawer, Input, App
} from 'antd';
import { useParams } from 'react-router-dom';
import { api } from '../../service/api.service'
import { getDiaSemana, renderButtonWhatshap } from '../../service/dal.service'
import {
    ClockCircleOutlined, CloseCircleOutlined, CheckCircleOutlined,
    CarryOutOutlined, AimOutlined, LoadingOutlined, SearchOutlined
} from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState, lojaState } from '../../store/dataRecoil'
import { isMobile } from 'react-device-detect';

import { firebase } from '../../service/firebaseService';
//import { collection, getDocs, addDoc, doc, setDoc } from "firebase/firestore";
import { getDatabase, ref, onChildChanged, off, push, set, onChildAdded } from "firebase/database";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData, useCollection } from 'react-firebase-hooks/firestore';
//import { StatusScreen } from '@mercadopago/sdk-react';

const { TabPane } = Tabs;
const { Text } = Typography;


const auth = firebase.auth();
const firestore = firebase.firestore();


export const Orders = (props) => {

    const { notification } = App.useApp()
    const params = useParams()
    const [user, setUser] = useRecoilState(userState);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [modal, setModal] = useState(false);
    const [show2, setShow2] = useState(false);
    const [selected, setSelected] = useState(undefined);
    const [pedidoAvaliar, setPedidoAvaliar] = useState(undefined)
    const [comida, setComida] = useState(0)
    const [entrega, setEntrega] = useState(0)
    const [app, setApp] = useState(0)
    const [loading, setLoading] = useState(true)
    const db2 = getDatabase();
    const [openChat, setOpenChat] = useState(false)
    const loja = useRecoilValue(lojaState)

    useEffect(() => {
        try {
            //console.log("orders")
            load();
            const dbRef = ref(db2, params.restaurant.concat('/orders/', user.uid));
            if (user) {
                onChildChanged(dbRef, (data) => {
                    load();
                });
            }

            return () => {
                try {
                    off(dbRef)
                } catch (erro) {
                    console.log('erro', erro.message)
                }
            }

        } catch (error1) {
            console.log('error1', error1.message)
        }
    }, [])






    async function loadPedido(item) {
        try {
            // console.log('loadPedido')
            setSelected(undefined)
            setModal(true)
            const response2 = await api.get('api/v2.0/pedido2?uid='.concat(user.uid, '&pedid=', item.pedid, '&path=', item.path));
            var data2 = response2.data;
            setSelected(data2)

        } catch (erro) {
            //  console.log('pedidos screen erro', erro)
            notification.error({
                message: "Atenção!",
                description: "Falha ao carregar dados!"
            })
            setSelected(undefined)
        }
    }

    async function load() {
        try {
            // console.log('params.path', params.restaurant)
            //   console.log('load')
            setLoading(true)
            const response = await api.get('api/v2.0/pedidos/andamento?uid='.concat(user.uid, '&path=', params.restaurant));
            var data1 = response.data;
            // console.log("data1", data1)
            if (!data1) {
                data1 = []
            }

            const response2 = await api.get('api/v2.0/pedidos/anteriores?uid='
                .concat(user.uid));
            var data2 = response2.data;
            if (!data2) {
                data2 = []
            }
            setData1(data1)
            setData2(data2)

        } catch (erro) {
            notification.error({
                message: "Atenção!",
                description: "Falha ao carregar dados!"
            })
            setData1([])
            setData2([])
        }
        setLoading(false)
    }


    function getIcone(status) {
        switch (status) {
            case 0:
                return <ClockCircleOutlined />
            case 1:
                return <CheckCircleOutlined style={{ color: 'green' }} />

            case 2:
                return <CloseCircleOutlined style={{ color: 'red' }} />

            case 3:
                return <CloseCircleOutlined style={{ color: 'red' }} />


            case 4:
                return <CarryOutOutlined style={{ color: 'purple' }} />


            default:
                return <AimOutlined />

        }
    }

    return <div>
        <Tabs defaultActiveKey="1" centered>
            <TabPane tab="EM ANDAMENTO" key="1">
                {
                    loading === true ? <Skeleton paragraph={{ rows: 12 }} /> : data1?.map((item, index) => {
                        return <Row key={'pedand-'.concat(index)}>
                            <Col span={24} className="andamento-header">
                                <div >
                                    <Avatar size={'small'} src={item.url} />
                                    {' '} <b> {item.fantasia} </b>
                                </div>
                                <div>
                                    {'Pedido Nº: '} <Tag color="blue" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            loadPedido(item)
                                            //  setModal(true)
                                        }}>
                                        <b>{item.pedid}</b>
                                    </Tag>
                                </div>
                            </Col>
                            <Col span={24}>
                            </Col>
                            <Col span={24}
                                style={{
                                    display: 'flex', justifyContent: 'center',
                                    flexDirection: 'column', alignItems: 'center'
                                }}>

                                <Timeline mode="alternate">
                                    {
                                        item?.timeline.map(subitem => {
                                            return <Timeline.Item
                                                dot={getIcone(subitem.status)}
                                                key={'timeline-'.concat(subitem.id)}>
                                                <div>{subitem.time} hs</div>
                                                <div>
                                                    <b>  {subitem.title}</b>
                                                </div>
                                                <div>
                                                    {subitem.description}
                                                </div>

                                            </Timeline.Item>
                                        })}
                                </Timeline>
                            </Col>
                            <Col span={24}>
                                {item.status === 9 && <Alert style={{ marginBottom: 5 }} message={<div> O pagamento está pendente!

                                </div>} showIcon type="warning" />}
                            </Col>

                            <Col span={24} className="div-center">
                                <Space direction='vertical'>
                                    {
                                        (item.status === 0 || item.status === 1 || item.status === 2) && renderButtonWhatshap(
                                            "".concat(loja?.informacoes?.whatsappcode, loja?.informacoes?.whatsappnumber),
                                            item?.pedid,
                                            item?.transaction_id,
                                            user?.displayName,
                                            'Iniciar conversa')

                                    }

                                    <Button type={'link'} icon={<SearchOutlined />} onClick={() => {
                                        loadPedido(item)
                                    }}>
                                        Ver pedido
                                    </Button>
                                </Space>
                            </Col>



                            <Col span={24}><Divider className="divider-orders" /> </Col>

                        </Row>

                    })
                }



            </TabPane>
            <TabPane tab="ANTERIORES" key="2">
                <div style={{ display: 'block' }}>
                    {
                        loading === true ? <Skeleton paragraph={{ rows: 12 }} /> :
                            data2.map((pedido, index) => {
                                return <Card size="small"
                                    style={{ margin: 15 }}
                                    title={<div >
                                        <Avatar size={'small'} src={pedido.url} />
                                        {' '} {pedido.fantasia}
                                    </div>}>
                                    <Row style={{ color: 'gray' }}>
                                        <Col span={24}>{'Pedido Nº: '}{pedido.pedid}</Col>
                                        <Col span={24}>
                                            {getDiaSemana(pedido.datahora.substring(0, 19))}
                                        </Col>
                                        <Col span={24} style={{ cursor: 'pointer' }} onClick={() => {
                                            avaliar(pedido)
                                        }}>
                                            {pedido.nota > 0 ? 'Sua avaliação' : 'Avalie o Pedido '}
                                            <Rate allowClear={false}
                                                disabled={true}
                                                style={{ marginLeft: 10 }}
                                                value={pedido.nota}
                                                onClick={() => {/*avaliar(pedido)*/ }}>

                                            </Rate>
                                        </Col>
                                        <Col span={24} className="div-center">
                                            <Button type={'link'} onClick={() => {
                                                loadPedido(pedido)
                                            }}>
                                                Ver pedido
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            })
                    }
                </div>
            </TabPane>
        </Tabs>

        <Modal title={<div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <Space direction='horizontal' size={'small'}>
                <Avatar size={'small'} src={selected?.url} />
                <b> {selected?.fantasia} </b>
            </Space>
            <Space direction='horizontal' size={'small'}>
                <Tag color={'blue'}> {'Pedido: '} {selected?.pedid.toString()}</Tag>
            </Space>
        </div>}
            open={modal}
            footer={null}
            centered
            closable={false}
            onOk={() => setModal(false)}
            onCancel={() => setModal(false)}>
            {selected ? <Row style={{ marginTop: 20 }}>
                <Col span={24}>
                    {
                        selected?.status === 9
                        && <div>
                            <div>
                                <Alert style={{ marginBottom: 5 }} message={<div> O pagamento está pendente!
                                    <Button type='link' danger href={selected?.transactionMP?.point_of_interaction?.transaction_data?.ticket_url}
                                        target='_blank'>
                                        Pagar agora
                                    </Button>

                                </div>} showIcon type="warning" />
                            </div>
                            <div>

                            </div>

                        </div>
                    }
                </Col>


                <Col span={24}>


                    <div style={{ cursor: selected?.type === 'DELIVERY' ? 'pointer' : 'default' }} >
                        <div className="AddressComponent-body">
                            <div className="AddressComponent-avater">
                                <Avatar alt="location" size="large"
                                    src={selected?.type === "DELIVERY" ? require("../../img/map-location.png") : require("../../img/takeaway.png")} />
                            </div>
                            <div className="AddressComponent-info">
                                <div><b style={{ fontSize: 13 }}> {selected?.type === 'DELIVERY' ? 'ENDEREÇO ENTREGA' : 'BALCÃO'}</b></div>
                                <div style={{ color: 'gray' }}>
                                    {
                                        selected?.type === "DELIVERY" ? <div>
                                            {(selected?.endereco?.logradouro.concat(' ',
                                                selected?.endereco?.numero, selected?.endereco?.complemento ? ", ".concat(selected?.endereco?.complemento) : "")
                                            )}
                                        </div> : <div>Retirar na loja</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} className="pedido-detail-item-right">
                    {getDiaSemana(selected?.datahora.substring(0, 19))}
                </Col>
                <Col span={24}>
                    <Divider className="divider-orders" />
                </Col>
                <Col span={24} style={{ paddingLeft: 15 }}>
                    {
                        selected?.itens.map(it => {
                            return (
                                <Row>
                                    <Col span={24}>
                                        {parseInt(it.Quant).toString().concat('  X  ', it.NomeReduzido)}
                                    </Col>
                                    {
                                        it.complementos.map(c => {
                                            return <Col span={24}><Text italic> <span style={{ color: 'gray', marginLeft: 25 }}>
                                                {parseInt(c.Quant).toString().concat('  X  ', c.NomeReduzido)}
                                            </span>
                                            </Text>
                                            </Col>
                                        })
                                    }
                                </Row>
                            )
                        })
                    }
                </Col>
                <Col span={24}>
                    <Divider className="divider-orders" />
                </Col>
                <Col span={12}>
                    SUBTOTAL
                </Col>
                <Col span={12} className="pedido-detail-item-right">
                    R$ {(selected?.valor - selected?.taxa + (selected?.cupom !== undefined ? selected?.cupom.valor : 0)
                        + (selected?.fidelidade !== undefined ? selected?.fidelidade.valor : 0)).toFixed(2).toString()}
                </Col>
                <Col span={24}>
                    <Divider className="divider-orders" />
                </Col>
                {
                    selected.cupom !== undefined && <React.Fragment>
                        <Col span={12} >
                            CUPOM
                        </Col>
                        <Col span={12} className="pedido-detail-item-right">
                            - R$ {selected.cupom.valor.toFixed(2).toString()}
                        </Col>
                    </React.Fragment>
                }

                {
                    selected.fidelidade !== undefined && <React.Fragment>

                        <Col span={12}>
                            {selected.fidelidade.tag}
                        </Col>

                        <Col span={12} className="pedido-detail-item-right">
                            - R$ {selected.fidelidade.valor.toFixed(2).toString()}
                        </Col>
                        <Col span={24}>
                            <Divider className="divider-orders" />
                        </Col>
                    </React.Fragment>
                }
                <Col span={12}>
                    TAXA DELIVERY
                </Col>

                <Col span={12} className="pedido-detail-item-right" >
                    +R$ {selected.taxa.toFixed(2)}
                </Col>

                <Col span={24}>
                    <Divider className="divider-orders" />
                </Col>

                <Col span={12}>
                    <b>  TOTAL </b>
                </Col>
                <Col span={12} className="pedido-detail-item-right">
                    <b>  R$ {selected?.valor.toFixed(2)}</b>
                </Col>
                <Col span={12}>{selected?.payments[0].name}</Col>
                <Col span={12} className="pedido-detail-item-right">

                    <span style={{ color: 'royalblue' }}>
                        {selected?.payments[0].prepaid2 === 1 ? "Pago online" : "Pagar na entrega"}
                    </span>

                </Col>
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Divider />
                    <Button type='primary' style={{ width: 120 }} onClick={() => setModal(false)}> Fechar </Button>
                </Col>



            </Row> : <Col span={24} > <LoadingOutlined />  Carregando...</Col>
            }

        </Modal >



        <Modal title={null}
            open={show2}
            footer={null}
            centered
            closable={true}
            onOk={() => setShow2(false)}
            onCancel={() => setShow2(false)}>
            {
                pedidoAvaliar && <Row className="div-centered">
                    <Col span={24}>
                        <div>
                            <Avatar size={'small'} src={pedidoAvaliar?.url} />
                            <b> {' '} {pedidoAvaliar?.fantasia} </b>
                        </div>
                    </Col>
                    <Col span={24} className="div-centered-subtitle" style={{ color: 'gray' }}>
                        <div style={{ marginLeft: 30 }}>
                            {'PEDIDO Nº '}  {pedidoAvaliar.pedid}
                        </div>
                        <div>
                            {getDiaSemana(pedidoAvaliar.datahora.substring(0, 19))}
                        </div>
                    </Col>
                    <Col span={24}><Divider style={{ marginTop: 0, marginBottom: 0 }} /></Col>

                    {
                        pedidoAvaliar?.itens?.map(it => {
                            return <Row>
                                <Col span={24}>
                                    {parseInt(it.Quant).toString().concat('  X  ', it.NomeReduzido)}
                                </Col>
                                {
                                    it.complementos.map(c => {
                                        return <Col span={24}><Text italic> <span style={{ color: 'gray', marginLeft: 25 }}>
                                            {parseInt(c.Quant).toString().concat('  X  ', c.NomeReduzido)}
                                        </span>
                                        </Text>
                                        </Col>
                                    })
                                }
                            </Row>
                        })
                    }

                    <Col span={24} className="div-centered" style={{ marginTop: 15 }}>
                        <b style={{ fontSize: 16, fontWeight: '600' }}> Avalie a comida</b></Col>
                    <Col span={24} className="div-centered">Escolha de 1 a 5 estrelas para classificar.</Col>
                    <Col span={24} className="div-centered">
                        <Rate
                            allowHalf
                            value={comida}
                            onChange={(newValue) => {
                                setComida(newValue);
                            }}
                        />
                    </Col>

                    <Col span={24} className="div-centered" style={{ marginTop: 25 }}><b style={{ fontSize: 16, fontWeight: '600' }}>
                        Avalie o serviço de entrega</b></Col>
                    <Col span={24} className="div-centered">Escolha de 1 a 5 estrelas para classificar.</Col>

                    <Col span={24} className="div-centered">
                        <Rate
                            allowHalf
                            value={entrega}
                            onChange={(newValue) => {
                                setEntrega(newValue);
                            }}
                        />
                    </Col>

                    <Col span={24} className="div-centered" style={{ marginTop: 25 }}><b style={{ fontSize: 16, fontWeight: '600' }}> aplicativo/site</b></Col>
                    <Col span={24} className="div-centered">Escolha de 1 a 5 estrelas para classificar.</Col>

                    <Col span={24} className="div-centered">
                        <Rate
                            allowHalf
                            value={app}
                            onChange={(newValue) => {
                                setApp(newValue);
                            }}
                        />
                    </Col>

                    <Col span={24} className="div-centered" style={{ marginTop: 20 }}>
                        <Divider style={{ marginTop: 15, marginBottom: 0 }} />
                    </Col>
                    <Col span={24} className="div-centered"
                        style={{ marginTop: 25 }}><b style={{ fontSize: 18, fontWeight: '600' }}> Sua Avaliação</b></Col>
                    <Col span={24} className="div-centered">
                        <Rate
                            disabled
                            allowHalf
                            value={((app + comida + entrega) / 3)}
                        />
                    </Col>

                    <Col span={24} className="div-centered" style={{ marginTop: 10, textAlign: 'center' }}>
                        <span style={{ fontSize: 18, color: 'blue' }}>
                            NOTA ({((app + comida + entrega) / 3).toFixed(1)}) </span></Col>

                    <Col span={24} className="div-centered">
                        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                    </Col>

                    <Col span={24} className="div-centered-buttons" style={{ marginTop: 10 }}>
                        <Button type="primary" danger
                            onClick={() => setShow2(false)}>
                            Cancelar
                        </Button>
                        <Button type="primary"
                            disabled={comida === 0 || app === 0 || entrega === 0}
                            onClick={() => salvar()}>
                            {"  Salvar  "}
                        </Button>
                    </Col>


                </Row>
            }

        </Modal>

        <Drawer open={openChat}
            destroyOnClose={true}
            title={"CHAT PEDIDO: ".concat(selected?.pedid)}
            footer={null}
            placement={'right'}
            width={isMobile ? '100%' : 500}
            closable={true}
            onClose={() => { setOpenChat(false) }}
            bodyStyle={{ backgroundColor: 'whitesmoke' }}
            headerStyle={{ backgroundColor: 'white' }}
            key={'right'}>

            {
                ChatRoom()
                /*
        <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'}}>
            <div style={{ display: 'flex', flex: 1 }}>...</div>
            <Space.Compact
                style={{
                    width: '100%'
                }}>
                <Input defaultValue=""
                    placeholder='Digite o texto aqui'
                    value={inputMsg}
                    onChange={(e) => setInputMsg(e.target.value)} />
                <Button type="primary"
                    disabled={inputMsg.trim() === ''}
                    onClick={() => sendMsg()}>Enviar</Button>
            </Space.Compact>
        </div>
                */
            }

        </Drawer>

    </div >

    /*
        function getDiaSemana(value) {
            var dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
            var meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
            var dia = new Date(value)
            return dias[dia.getDay()].concat(', ', dia.getDate(), ' ', meses[dia.getMonth()], ' ', dia.getFullYear())
    
        }
        */

    function avaliar(item) {
        if (item.pedidoavaliado === 0) {
            setPedidoAvaliar(item)
            setApp(0)
            setComida(0)
            setEntrega(0)
            setShow2(true);
        }
    }


    async function salvar() {
        var post = {
            app: app,
            comida: comida,
            entrega: entrega,
            pedid: pedidoAvaliar.pedid,
            name: user.displayName,
            url: user?.photoURL ? user?.photoURL : '',
            path: pedidoAvaliar.path,
            uid: pedidoAvaliar.uid,
            origem: 'site'
        }

        try {
            await api.post('api/v2.0/pedidos/avaliacao', post);
            notification.success({
                message: "Obrigado.", description: 'A avaliação foi registrada com sucesso!'
            })
            setShow2(false)
            load()
        } catch (erro) {
            // console.log('erro', erro)
            notification.error({
                message: "Atenção!",
                description: "Hum, algo errado! Erro ao enviar sua avaliação! Tente novamente em instantes!"
            })
        }
    }








    function ChatRoom() {

        const dummy = useRef();

        const messagesRef = firestore.collection('chats')
            .doc(auth.currentUser?.uid)
            .collection(selected ? selected?.pedid?.toString() : '0000');
        const query = messagesRef.orderBy('createdAt').limit(100);

        const [messages] = useCollectionData(query, { idField: 'id' });

        const [formValue, setFormValue] = useState('');


        async function sendMessage() {
            // e.preventDefault();
            if (formValue.trim() === '')
                return

            const { uid, photoURL } = auth.currentUser;
            await messagesRef.add({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                type: 1
            })
            setFormValue('');
            dummy.current.scrollIntoView({ behavior: 'smooth' });
        }

        return (<>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}>
                <main style={{ display: 'flex', flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
                        <span ref={dummy}></span>
                    </div>
                </main>

                <form>
                    <Space.Compact
                        style={{
                            width: '100%'
                        }}>
                        <Input defaultValue=""
                            placeholder='Mensagem'
                            value={formValue}
                            onChange={(e) => setFormValue(e.target.value)} />
                        <Button type="primary"
                            onClick={() => sendMessage()}>Enviar</Button>
                    </Space.Compact>


                </form>
            </div>

        </>)
    }


    function ChatMessage(props) {
        const { text, uid, photoURL, type } = props.message;
        const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
        return <Space size={[0, 8]} wrap style={{
            display: 'flex',
            flexDirection: type === 1 ? 'row-reverse' : 'row'
        }} >
            <Tag color={type === 1 ? "#2db7f5" : 'default'} style={{ marginBottom: 3 }}>{text}</Tag>

        </Space>



    }



}

export default Orders