import React, { useState, useEffect } from 'react'
import './fidelidadeComponent.css'
import { Avatar, Card, Drawer, Alert } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Fidelidade from '../fidelidade'
import { isMobile } from 'react-device-detect'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState, pedidoState, lojaState } from '../../store/dataRecoil'
import { loadHistoricoFidelidade } from '../../service/api.service'
import { useParams } from 'react-router-dom';


export const FidelidadeComponent = (props) => {

  const params = useParams()
  const [visibleFidelidade, setVisibleFidelidade] = useState(false);
  const [pedido, updatePedido] = useRecoilState(pedidoState)
  const loja = useRecoilValue(lojaState)
  const user = useRecoilValue(userState)
  const [saldo, setSaldo] = useState(0)


  function showFidelidade(value) {
    setVisibleFidelidade(value)
  }

  useEffect(() => {
    loadSaldo()
  }, [])


  async function loadSaldo() {
    try {
      const data = await loadHistoricoFidelidade(user?.uid, params.restaurant)
      // console.log("data", data)
      if (data) {
        // data = Object.values(data)
        if (data.length > 0) {
          setSaldo(data[0].saldo);
        }
      } else {
        setSaldo(0)
      }
    } catch (error) {
      setSaldo(0)
    }

    console.log('Saldo: ', saldo)
  }

  return <div>
    {user?.isAnonymous === false && <Card
      size='small'
      style={{ marginTop: 10, marginBottom: 5, cursor: 'pointer' }} onClick={() => {
        setVisibleFidelidade(true)
      }}>
      <div className="fidelidadeComponent-main">
        <div className="fidelidadeComponent-body">
          <div className="fidelidadeComponent-avater">
            <Avatar src={require("../../img/shopping-bag.png")} size="large" />
          </div>
          <div className="fidelidadeComponent-info">

            <div><b>{pedido?.fidelidade ? pedido?.fidelidade.tag : 'PROGRAMA DE FIDELIDADE'}</b></div>
            <div>{pedido?.fidelidade !== undefined ? pedido?.fidelidade?.tag.concat(' resgatados') : loja?.engpoints?.config?.nome}</div>
            {
              (saldo >= loja?.engpoints?.config?.pontos &&
                !pedido?.fidelidade &&
                loja?.engpoints?.config?.ativo === 1
              ) && <div>
                <Alert message={<b>Ative seu bônus do programa de fidelidade</b>} banner showIcon={true} />
              </div>
            }
          </div>
        </div>
        <div className="fidelidadeComponent-icon">
          <RightOutlined />

        </div>
      </div>
    </Card>}


    <Drawer title={null} placement="right"
      width={isMobile ? '100%' : 500}
      onClose={() => setVisibleFidelidade(false)}
      open={visibleFidelidade}>
      <Fidelidade path={loja.path} showFidelidade={showFidelidade} />
    </Drawer>
  </div>

}

export default FidelidadeComponent