import React, { useEffect, useState } from 'react'
import './restaurantes.css'
import '../../admin.css'
import { Breadcrumb, Affix, Row, Col, Avatar, Card, Typography, Tag } from 'antd';
import { HomeOutlined, StarFilled } from '@ant-design/icons';
import { Footer } from '../../component/footer'
import { api } from '../../service/api.service'
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';

const { Meta } = Card;


export const Restaurantes = (props) => {

    const params = useParams()
    const navigate = useNavigate()
    const [lojas, setLojas] = useState([])
    const [cidade, setCidade] = useState('')


    useEffect(() => {
       // console.log(params)
        if (params.uf.length !== 2) {
            navigate('/notfound')
        } else
            load()
    }, [])

    async function load() {
        try {
            const response = await api.get('/api/v2.0/restaurantes', {
                params: {
                    uf: params.uf,
                    cidade: params.cidade
                }
            });
            if (response.status === 200) {
                //  console.log('restaurantes', response.data)
                setLojas(response.data)
                if (response.data.length > 0) {
                    setCidade(response.data[0].cidade)
                }

            } else {

            }
        } catch (error) {
            console.log(error.message)
        }
    }




    function renderDesktop() {
        return <Row gutter={[32, 32]}>
            {
                lojas.map((item, index) => {
                    return <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} key={index}>
                        <Card style={{ cursor: 'pointer', opacity: item.status === 'aberto' ? 1 : 0.3 }}
                            onClick={() => {
                               // console.log(params)
                                navigate('/'.concat(params.uf,'/',params.cidade,'/', item.path))
                               }}>

                            <Meta
                                avatar={<Avatar shape="square" size={88} style={{ margin: 0 }} src={item.url} />}
                                title={item.fantasia}
                                description={<div>
                                    <div className="loja-line" >
                                        {item.status === 'aberto' ? item.tempo.concat(' min') : 'fechado'}
                                    </div>
                                    <div className="loja-line" >
                                        <Tag color="#FFD700" >
                                            <StarFilled style={{ marginRight: 1 }} /> {item.nota ? item.nota : 'novo'}
                                        </Tag>
                                    </div>
                                </div>}/>
                            
                        </Card>
                    </Col>
                })
            }

        </Row >
    }


    return <div className="default-main">
        <Affix offsetTop={0}>
            <div className="default-header">
                <strong> Menuengfood </strong>
            </div>
        </Affix>
        <div className={isMobile ? 'default-content-mobile' : 'default-content'}>
            <div style={{ padding: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/cidades">
                        <HomeOutlined style={{ color: 'royalblue', fontSize: 20 }} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{params.uf}</Breadcrumb.Item>
                    <Breadcrumb.Item>{cidade}</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content-restaurants">
                    {
                        renderDesktop()
                    }
                </div>


            </div>
        </div>

        <Footer />
    </div>
}