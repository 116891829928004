import React, { useState, useEffect, useRef } from 'react'
import '../../admin.css'
import {
    Affix, Avatar, Button, Drawer, Image, Modal,
    Space
} from 'antd';
import './appBar.css'
import {
    LogoutOutlined, DownOutlined, LeftOutlined
} from '@ant-design/icons'
import { getAuth, signOut } from "firebase/auth";
import { isMobile } from 'react-device-detect';
import Cupons from '../../component/cupons'
import Fidelidade from '../../component/fidelidade'
import Notifications from '../../component/notifications'
import Orders from '../../component/orders'
import Account from '../../component/account'
import Address from '../../component/address'
import { userState, lojaState, selCurrentAddress } from '../../store/dataRecoil'
import { useParams, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil'
import ALFINE_PNG from '../../img/alfinete.png'



export const AppBar = (props) => {

    const location = useLocation()
    const params = useParams()
    const [visible, setVisible] = useState(false);
    const [visibleLogin, setVisibleLogin] = useState(false);
    const [nome, setNome] = useState('')
    const [user, saveUser] = useRecoilState(userState)
    const current = useRecoilValue(selCurrentAddress)

    const loja = useRecoilValue(lojaState)

    //endereço
    const [modalVisibleEndereco, setModalVisibleEndereco] = useState(false)

    //cupons
    const [visibleCupons, setVisibleCupons] = useState(false)

    const [modalFidadelidadeVisible, setModalFidelidadeVisible] = useState(false)

    const [modalNotificationVisible, setModalNotificationVisible] = useState(false)

    const [modalPedidosVisible, setModalPedidosVisible] = useState(false)

    const [isRestaurant, setIsRestaurant] = useState(false)





    useEffect(() => {
        if (checkUrlApp()) {
            if (params.restaurant)
                setIsRestaurant(true)
        } else {
            if (params.uf && params.cidade && params.restaurant)
                setIsRestaurant(true)
        }
        //   console.log('params', params)
    }, [])


    useEffect(() => {
        // console.log(user)
        if (user?.uid && user?.isAnonymous === undefined) {
            saveUser({ ...user, isAnonymous: true })
        }
    }, [user])

    useEffect(() => {
        //  console.log(loja)
        if (user?.uid) {
            if (user?.displayName && user?.displayName?.trim()) {
                const aux = user?.displayName.split(' ')
                // console.log(aux)
                if (aux) {
                    if (aux[0].trim().length > 0)
                        setNome('Olá '.concat(aux[0]))
                    else
                        setNome('Entrar')
                } else {
                    setNome('Entrar')
                }
            } else {
                setNome('Entrar')
            }
        } else {
            setNome('Entrar')
        }

    }, [user])

    function checkUrlApp() {
        return location.pathname.substring(0, 5) === "/app/"
    }

    function showAccount(value) {
        setVisibleLogin(value)
    }

    function showCupons(value) {
        setVisibleCupons(value)
    }

    function showFidelidade(value) {
        setModalFidelidadeVisible(value)
    }

    function showNotifications(value) {
        setModalNotificationVisible(value)
    }

    function showOrders(value) {
        setModalPedidosVisible(value)
    }

    function showAddress(value) {
        setModalVisibleEndereco(value)
    }


    return <div style={{ width: '100%' }}>
        {/* <Affix offsetTop={0}> */}
        <div className="default-header-appbar" style={{
            backgroundColor: checkUrlApp() ? loja?.cor : '#4c4ce4'
        }}>
            <div>
                {checkUrlApp() && <Space direction='horizontal'
                    style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        alignItems: 'center',
                        marginLeft: 5
                    }}>
                    {<Avatar src={ALFINE_PNG} size={'small'} />}
                    <Space direction='vertical'>
                        <Space direction='horizontal' onClick={() => showAddress(true)}
                            style={{ fontSize: '0.6em' }}>
                            {
                                current ? (current?.logradouro ? current.logradouro.concat(', ',
                                    current.numero) : 'Endereço não informado') : 'Endereço não informado'
                            }

                            <DownOutlined />
                        </Space>
                        <Space direction='horizontal'></Space>
                    </Space>
                </Space>
                }
            </div>
            <strong > {
                !checkUrlApp() && "Menuengfood"
            } </strong>
            <div className="appbar-options">
                <Button type="primary" danger

                    onClick={() => {
                        if (user?.uid && user?.displayName) {
                            setVisible(true)
                        } else {
                            setVisibleLogin(true)
                        }
                    }
                    }>
                    {nome}
                </Button>
            </div>

        </div>
        {/* </Affix> */}
        <Drawer
            zIndex={1000}
            headerStyle={{ backgroundColor: 'white' }}
            title={<Space direction='horizontal' size={'small'}>
                <Avatar size={32} src={user?.photoURL ? user?.photoURL :
                    'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'}
                />
                <div><b> {user?.displayName} </b></div>

            </Space>}
            placement="right"
            destroyOnClose={true}
            closeIcon={<LeftOutlined />}
            onClose={() => setVisible(false)} open={visible}>

            <div className="sidebar-list">
                {user?.isAnonymous === false && <div className="sidebar-list-item" onClick={() => {
                    setVisible(false)
                    setVisibleLogin(true)
                }}>
                    <img height={20} src={require('../../img/sideleft/resume.png')} style={{ marginRight: 20 }} />
                    <span style={{ fontSize: 16 }}> Editar dados </span>
                </div>
                }

                <div className="sidebar-list-item" onClick={() => {
                    setVisible(false)
                    // setModoEndereco(0)
                    setModalVisibleEndereco(true)
                }}>
                    <img height={20} src={require('../../img/sideleft/single-couch.png')} style={{ marginRight: 20, fontSize: 20 }} />
                    <span style={{ fontSize: 16 }}> Endereço </span>
                </div>
                {
                    user?.displayName && <div className="sidebar-list-item"
                        onClick={() => {
                            setVisible(false)
                            setModalPedidosVisible(true)
                        }}>
                        <img height={20} src={require('../../img/sideleft/spoon.png')}
                            style={{ marginRight: 20, fontSize: 20 }} /> <span style={{ fontSize: 16 }}> Pedidos </span>
                    </div>
                }

                {

                    isRestaurant && <div className="sidebar-list-item">
                        <img height={20} src={require('../../img/sideleft/coupon.png')} style={{ marginRight: 20, fontSize: 20 }} /> <span style={{ fontSize: 16 }}
                            onClick={() => {
                                setVisible(false)
                                setVisibleCupons(true)
                            }
                            }> Cupons </span>
                    </div>

                }

                {
                    isRestaurant && <div className="sidebar-list-item"
                        onClick={() => {
                            setVisible(false)
                            setModalFidelidadeVisible(true)
                        }}>
                        <img height={20} src={require('../../img/sideleft/gift-box.png')} style={{ marginRight: 20, fontSize: 20 }} /> <span style={{ fontSize: 16 }}> Fidelidade </span>
                    </div>
                }
                {
                    isRestaurant && <div className="sidebar-list-item"
                        onClick={() => {
                            setVisible(false)
                            setModalNotificationVisible(true)
                        }}>
                        <img height={20} src={require('../../img/sideleft/notification.png')}
                            style={{ marginRight: 20, fontSize: 20 }} /> <span style={{ fontSize: 16 }}>
                            Notificações </span>
                    </div>
                }

                {
                    isRestaurant && <div className="sidebar-list-item"
                        onClick={() => {
                            const auth = getAuth();
                            signOut(auth)
                            saveUser(undefined)
                            setVisible(false)
                        }}>
                        <LogoutOutlined size={26} style={{ fontSize: 18, marginLeft: 2 }} />
                        <span style={{ fontSize: 16, marginLeft: 20 }}>Sair</span>

                    </div>
                }


                <br />

                {
                    /*
                    (user?.displayName) && <Button block
                        icon={<LogoutOutlined />}
                        onClick={() => {
                            const auth = getAuth();
                            signOut(auth)
                            saveUser(undefined)
                            setVisible(false)
                        }}> Deslogar </Button>
                        */
                }


            </div>

        </Drawer>


        {/* MODAL DE LOGIN */}

        <Modal title={null}
            open={visibleLogin}
            footer={null}
            destroyOnClose={true}
            onOk={() => setVisibleLogin(false)}
            onCancel={() => setVisibleLogin(false)}>
            <Account path={params.restaurant} showAccount={showAccount} />
        </Modal>


        {/* MODAL DE ENDEREÇO */}
        <Drawer
        zIndex={1000}
            headerStyle={{ backgroundColor: 'white' }}
            title={
                <div >
                    <div style={{ fontSize: '0.8em' }}>{(current && current?.logradouro) && <div>  {current?.logradouro}{' '}{current?.numero}  </div>} </div>
                    <div style={{ fontSize: '0.7em' }}>{(current && current?.logradouro) && <div>  {current?.bairro}{' - '}{current?.localidade}</div>}</div>
                </div>
            }
            closeIcon={<LeftOutlined />}
            placement="right"
            width={isMobile ? '100%' : 500}
            open={modalVisibleEndereco}
            onClose={() => setModalVisibleEndereco(false)}>
            <Address path={params.restaurant} showAddress={showAddress} />
        </Drawer>


        <Drawer 
        zIndex={1000}
        title={"Cupons"} placement="right"
            headerStyle={{ backgroundColor: 'white' }}
            destroyOnClose={true}
            width={isMobile ? '100%' : 500}
            onClose={() => setVisibleCupons(false)}
            open={visibleCupons}>
            <Cupons path={params.restaurant} showCupons={showCupons} />
        </Drawer>


        <Drawer 
        zIndex={1000}
        title={null} placement="right"
            headerStyle={{ backgroundColor: 'white' }}
            destroyOnClose={true}
            width={isMobile ? '100%' : 500}
            onClose={() => setModalFidelidadeVisible(false)}
            open={modalFidadelidadeVisible}>
            <Fidelidade path={params.restaurant} showFidelidade={showFidelidade} />
        </Drawer>


        <Drawer 
        zIndex={1000}
        title={'Notificações'} placement="right"
            headerStyle={{ backgroundColor: 'white' }}
            width={isMobile ? '100%' : 500}
            onClose={() => setModalNotificationVisible(false)}
            open={modalNotificationVisible}>
            <Notifications path={params.restaurant} showNotifications={showNotifications} />
        </Drawer>


        <Drawer 
        zIndex={1000}
        title={'Meus Pedidos'} placement="right"
            headerStyle={{ backgroundColor: 'white' }}
            destroyOnClose={true}
            width={isMobile ? '100%' : 500}
            onClose={() => setModalPedidosVisible(false)}
            open={modalPedidosVisible}>
            <Orders path={params.restaurant} showOrders={showOrders} />
        </Drawer>


    </div>
}
/*
const mapStateToProps = state => ({
    loja: state.lojaReducer.loja,
    loading: state.lojaReducer.loading,
    menuengfood: state.lojaReducer.menuengfood,
    user: state.userReducer.user,
    menu: state.lojaReducer.menu,
    pedido: state.pedidoReducer,
    current: state.addressReducer.current,
    address: state.addressReducer.address,
})

const mapDispatchToProps = dispatch => ({
    saveUser: (user) => dispatch(ActionUser.save_user(user)),
    clearPedido: (path) => dispatch(ActionPedidos.clear_pedido(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppBar)
*/

export default AppBar