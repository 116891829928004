import React, { useEffect, useState } from 'react'
import {
    Table, Button as ButtonRSuite, Input as Inputrs
} from 'rsuite';
import { DatePicker,Space,  ConfigProvider } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import ptBR from "antd/es/locale/pt_BR";
import { ArrowDown, ArrowUp } from '@rsuite/icons'
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'

const { Column, HeaderCell, Cell } = Table

export const AnaliseFinanceira = (props) => {

    const { user } = props
    const waiterUser = useRecoilValue(waiterUserState)
    const [mes, setMes] = useState(dayjs())
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const onChange = (date, dateString) => {
        setMes(dayjs('01/'.concat(dateString), 'DD/MM/YYYY'))
        load(dayjs('01/'.concat(dateString), 'DD/MM/YYYY'))
    };

    useEffect(() => {
        load()
    }, [])


    async function load(data) {
        try {
            setLoading(true)
            setData([])
            socket.emit('analiseFinanceira', waiterUser?.database,
                {
                    mes: dayjs(data).format('MM'),
                    ano: dayjs(data).format('YYYY')
                },
                (value) => {                    
                    if (value.status === 200) {
                       // console.log(value.data)
                        setData(value.data.data)
                    } 
                    setLoading(false)
                }); 
           
        } catch (error) {
            setLoading(false)
        }
    }



    return (<main>
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <span></span>
                <Space direction="horizontal" style={{ marginBottom: 5 }}>
                    Selecione o período
                    <ConfigProvider locale={ptBR}>
                        <DatePicker value={mes}
                            onChange={onChange}
                            picker="month"
                            format={'MM/YYYY'}
                            allowClear={false} />
                    </ConfigProvider>
                </Space>
            </div>

            <Table
                autoHeight={true}
                hover
                loading={loading}
                style={{ fontSize: '0.9em' }}
                rowHeight={30}
                headerHeight={30}
                data={data}>
                <Column flexGrow={1} align="center" fixed >
                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.9em' }}>DATA</HeaderCell>
                    <Cell dataKey="data" style={{ padding: 3 }}>
                        {rowData => {
                            return <span style={{ fontSize: '0.9em' }}>{dayjs(rowData.data).format('DD/MM/YYYY')}</span>
                        }}
                    </Cell>
                </Column>
                <Column align="center" fixed flexGrow={1}>
                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.9em' }}>SALDO</HeaderCell>
                    <Cell dataKey="data" style={{ padding: 3 }}>
                        {rowData => {
                            return <span style={{ fontSize: '0.9em' }}>{rowData.saldoInicial?.toFixed(2)}</span>
                        }}
                    </Cell>
                </Column>
                <Column align="center" fixed flexGrow={1}>
                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.9em' }}>
                        (+) RECEITA
                        <ArrowUp color='green' style={{ fontSize: 25 }} />
                    </HeaderCell>
                    <Cell dataKey="data" style={{ padding: 3 }}>
                        {rowData => {
                            return <span style={{ fontSize: '0.9em' }}>{rowData.receitas?.toFixed(2)}</span>
                        }}
                    </Cell>
                </Column>
                <Column align="center" fixed flexGrow={1}>
                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.9em' }}>
                        (-) DESPESA
                        <ArrowDown color='red' style={{ fontSize: 25 }} />
                    </HeaderCell>
                    <Cell dataKey="data" style={{ padding: 3 }}>
                        {rowData => {
                            return <span style={{ fontSize: '0.9em' }}>{rowData.despesas?.toFixed(2)}</span>
                        }}
                    </Cell>
                </Column>
                <Column align="center" fixed flexGrow={1}>
                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.9em' }}>SALDO DIA</HeaderCell>
                    <Cell dataKey="data" style={{ padding: 3 }}>
                        {rowData => {
                            return <span style={{ fontSize: '0.9em' }}>{rowData.saldoDia?.toFixed(2)}</span>
                        }}
                    </Cell>
                </Column>
                <Column align="center" fixed flexGrow={1}>
                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.9em' }}>ACUMULADO</HeaderCell>
                    <Cell dataKey="data" style={{ padding: 3 }}>
                        {rowData => {
                            return <span style={{ fontSize: '0.9em' }}>{rowData.acumulado?.toFixed(2)}

                            </span>
                        }}
                    </Cell>
                </Column>
            </Table>
        </div>
    </main>
    )
}
