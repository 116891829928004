
import React from 'react'
import './home.css'
import { Affix, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {Footer} from '../../component/footer'

export default function Home(props){

    const navigate = useNavigate()

    return <div className="home-main">
    <Affix offsetTop={0}>
        <div className="home-header">
            <strong> Menuengfood </strong>
        </div>
    </Affix>
    <div className={ isMobile ? 'home-content-mobile' : 'home-content'}>
        <div className="home-div-image">
            <div className="home-div-image-text">
                <div style={{fontSize: isMobile ? 16 : 23,  }}>
                    Olá, bem vindo ao Menuengfood, que tal procurarmos um local bacana agora?
                </div>
            </div>
            <div className="home-div-image-buttons">                
                <Button style={{ width: 300, marginTop: isMobile ? 15 : 0 }} 
                type="primary" danger
                onClick={()=> navigate('/cidades')}> ESCOLHER CIDADE </Button>

            </div>
        </div>
    </div>
   <Footer/>

</div>

}