import React, { useEffect, useState } from 'react'
import { Card, Radio, Avatar, Drawer, Tag, Badge, Alert } from 'antd'
import './addressComponent.css'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { useRecoilState, useRecoilValue } from 'recoil'
import { pedidoState, selCurrentAddress, lojaState } from '../../store/dataRecoil'
import Address from '../address'

export const AddressComponent = (props) => {


  const loja = useRecoilValue(lojaState)
  const [pedido, updatePedido] = useRecoilState(pedidoState)
  const current = useRecoilValue(selCurrentAddress)
  const [modalVisibleEndereco, setModalVisibleEndereco] = useState(false)

  useEffect(() => {
    // console.log(current, loja)
  }, [])

  function showModalEndereco(value) {
    setModalVisibleEndereco(value)
  }

  const handleChange = (event) => {
    var carrinho = JSON.parse(JSON.stringify(pedido))
    carrinho.type = event.target.value
    updatePedido(carrinho)
  };


  function checkEndereco() {
    if (!current?.bairro) {
      return <Tag color="magenta" onClick={() =>
        setModalVisibleEndereco(true)
      }> Cadastre um endereço para entrega. </Tag>
    }
    else if (parseInt(current?.atendido) === 0)
      return <Tag color="magenta" onClick={() =>
        setModalVisibleEndereco(true)
      }> verifique se o bairro informado é<br />atendido pelo restaurante. </Tag>
    else {
      //  console.log("ELSE Executado")
      return <span>  </span>
    }
  }

  function renderCard() {
    return <Card size='small'>
      <div style={{ marginBottom: 8 }}>
        <Radio.Group name="tipopedido" value={pedido.type} onChange={handleChange}>
          <Radio value={'DELIVERY'}>Delivery</Radio>
          <Radio value={'PICKUP'}>Retirar no local</Radio>
        </Radio.Group>
      </div>

      <div>
        {
          (current?.atendido === 0 && pedido?.type === 'DELIVERY') && <Alert
            message="Endereço não atendido!"
            type='error'
            showIcon
          />
        }
      </div>

      <div className="AddressComponent-main"
        style={{ cursor: pedido?.type === 'DELIVERY' ? 'pointer' : 'default' }}
        onClick={() => {
          if (pedido?.type === 'DELIVERY') {
            setModalVisibleEndereco(true)
          }
        }}>
        <div className="AddressComponent-body">
          <div className="AddressComponent-avater">
            <Avatar alt="location" size="large"
              src={pedido?.type === "DELIVERY" ? require("../../img/map-location.png") : require("../../img/takeaway.png")} />
          </div>
          <div className="AddressComponent-info">
            <div><b style={{ fontSize: 13 }}> {pedido?.type === 'DELIVERY' ? 'ENDEREÇO ENTREGA' : 'BALCÃO'}</b></div>
            <div style={{ color: 'gray' }}>
              {
                pedido.type === "DELIVERY" ? <div>
                  {current ? (current?.logradouro ? current.logradouro.concat(' ',
                    current.numero, current?.complemento ? ", ".concat(current?.complemento) : "") : checkEndereco()) : checkEndereco()}
                </div> : <div>Retirar na loja</div>
              }
            </div>
          </div>
        </div>
        <div className="AddressComponent-icon">
          {pedido?.type === 'DELIVERY' && <RightOutlined />}
        </div>
      </div>
    </Card>
  }




  return <div style={{ marginTop: 5, marginBottom: 5 }}>

    {renderCard()}
    <Drawer
      zIndex={1000}
      closeIcon={<LeftOutlined />}
      title={<div >
        <div style={{ fontSize: '0.8em' }}>{(current && current?.logradouro) && <div>
          {current?.logradouro}{' '}{current?.numero} {"".concat(current?.complemento ? current?.complemento : "")} </div>} </div>
        <div style={{ fontSize: '0.7em' }}>{(current && current?.logradouro) && <div>
          {current?.bairro}{' - '}{current?.localidade}</div>}</div>
      </div>}
      destroyOnClose={true}
      placement="right"
      width={isMobile ? '100%' : 500}
      open={modalVisibleEndereco}
      onClose={() => {
        setModalVisibleEndereco(false)
      }}>
      <Address showModalEndereco={showModalEndereco} />
    </Drawer>
  </div>
}

export default AddressComponent