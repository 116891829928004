import logo from './logo.svg';
import './App.css';
import { Outlet } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import { ToastProvider } from 'react-toast-notifications';
import Rotas from './rotas';
import { App as App2 } from 'antd';
import 'rsuite/dist/rsuite.min.css'

function App() {

  return (<App2 >
    <ToastProvider>
      <RecoilRoot>
        <Rotas />
      </RecoilRoot>
    </ToastProvider>
  </App2>);
}

export default App;
