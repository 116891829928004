import React, { useEffect, useState } from 'react'
import { Button, Flex, App, Badge, Breadcrumb, Image, Card } from 'antd'
import { titleize } from '../../../service/util'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { waiterUserState, pedidoWaiterState, mesaState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import '../waiterOutlet.css'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../service/api.service'
import devoltaPNG from '../../../../src/img/de-volta.png'
import { FlexboxGrid } from 'rsuite'
import { isMobile } from 'react-device-detect'

const { Meta } = Card


export const WaiterCategorias = (props) => {

    const { notification } = App.useApp()
    const navigate = useNavigate()
    const [categorias, setCategorias] = useState([])
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [pedido, setPedido] = useRecoilState(pedidoWaiterState)
    const [mesa, setMesa] = useRecoilState(mesaState)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getCategorias()

        return () => {

        }

    }, [])



    async function getCategorias() {
        try {
            setLoading(true)
            const value = await api.get('api/v1.0/manager/categorias', {
                params: {
                    sala: waiterUser?.database
                },
                headers: {
                    'x-access-token': waiterUser?.token,
                }
            });
            // console.log(value)
            if (value.data.status == 200)
                setCategorias(value.data.data)
            else
                notification.error({
                    duration: 3,
                    description: 'Erro ao carregar as categorias!'
                })

            // socket.emit('getCategorias', waiterUser?.database, (value) => {
            // console.log(value)
            //    setCategorias(value)
            // });
        } catch (error) {
            notification.error({
                duration: 3,
                description: error.message
            })
        }
        setLoading(false)
    }

    function revisar() {
        if (pedido?.itens.length > 0) {
            navigate('/manager/pedido')
        } else {
            notification.warning({
                description: 'Não existem itens adicionados ao pedido para revisão',
                duration: 2
            })
        }
    }


    return <>
        <main style={{flex:1, overflowY:'auto', overflowX:'hidden'}}>
            <Flex vertical>
                <div style={{
                    backgroundColor: 'whitesmoke', marginTop: 0, 
                    padding: 5
                }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            Categorias
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <b>{mesa.tipoAtendimento === 3 ? mesa?.ComandaNome : mesa?.numero} </b>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </Flex>
            <div style={{ display: 'flex', justifyContent: 'center', maxWidth: '100%', marginTop: 10 }}>
                <Flex wrap gap={'small'} justify='center'>
                    {
                        loading === true ? <Flex style={{ minHeight: 300 }} align='center'>
                            <LoadingOutlined /> loading...
                        </Flex> : <FlexboxGrid> {categorias?.map((item, index) => {
                            return <FlexboxGrid.Item
                                key={'cat_'.concat(item.catChave)}
                                style={{ padding: 2 }} colspan={isMobile ? 12 : 4}>
                                <Card
                                    onClick={() => navigate('/manager/produtos', {
                                        state: {
                                            categoria: item
                                        }
                                    })}

                                    hoverable
                                    style={{
                                        minHeight: 45,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <div style={{ fontSize: '0.8em', fontWeight: 700 }}>  {item.catDescricao.trim()} </div>

                                </Card>
                            </FlexboxGrid.Item>
                        })
                        }
                        </FlexboxGrid>
                    }
                </Flex>
            </div>
        </main>
        <footer>

            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12}>
                    <Button
                        icon={<Image
                            style={{ objectFit: 'contain' }}
                            preview={false}
                            height={18}
                            alt='volta'
                            src={devoltaPNG} />}
                        block
                        onClick={() => navigate('/manager')}>
                        VOLTAR
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <Button
                        icon={<CheckOutlined />}
                        type='primary'
                        block
                        onClick={() => revisar()}
                        disabled={pedido?.itens.length > 0 ? false : true}>
                        <Badge count={pedido?.itens?.length} >
                            <span style={{ color: pedido?.itens.length > 0 ? 'white' : 'darkgray' }}> REVISAR </span>
                        </Badge>
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>


        </footer>
    </>
}
