import React, { useEffect, useState } from 'react'
import { api } from '../../service/api.service'
import { Image, List, Space, Card } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import './notifications.css'
import noimage from '../../img/notimage.png'
import { useRecoilValue } from 'recoil'
import { userState } from '../../store/dataRecoil'
import { useToasts } from 'react-toast-notifications';

const { Meta } = Card

export const Notifications = (props) => {

  const toash = useToasts()
  const user = useRecoilValue(userState)
  const params = useParams()
  const [data, setData] = useState([])
  const navigate = useNavigate()


  useEffect(() => {
    if (!params.restaurant)
      navigate('/notfound')
    else
      load()
  }, [])


  async function load() {
    try {
      const resp = await api.get("/api/v2.0/notification/user?uid="
        .concat(user.uid, '&path=', params.restaurant))
      if (resp.status === 200) {
        setData(resp.data)
        //   console.log(resp.data)
      }
      else
        setData([])
    } catch (error) {
      toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }


  return <div>
    {data.map((item, index) => {
      return <Card
        key={item.id}
        hoverable
        style={{
          width: '100%',
          marginBottom:15
        }}
        cover={item.urlapp ? <img alt={"img".concat(item.id)}
          src={item.urlapp ? item.urlapp : noimage} /> : null }>
        <Meta title={item.titul} description={item.msg} />
      </Card>
    })
    }
  </div>


  return <List
    className="demo-loadmore-list"
    itemLayout="horizontal"
    dataSource={data}
    renderItem={item => (
      <List.Item key={item.id}>
        <Space direction='vertical' size={'small'}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>
            <Image style={{ width: 120, height: 80 }} alt="img"
              src={item.urlapp ? item.urlapp : noimage}
              preview={false} />

            <Space direction='vertical' size={'small'}>
              <div>{item.titulo}</div>
              <div>{item.msg}</div>
            </Space>
          </div>
        </Space>

      </List.Item>
    )}
  />
}

export default Notifications