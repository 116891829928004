import React, { useEffect, useState } from 'react'
import { waiterUserState, waitDataBaseState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { socket } from '../../../service/socket'

import { Input, Button, Space, Flex, App, Radio, Segmented, Avatar } from 'antd'
//import { DownOutlined } from '@ant-design/icons'
import { api } from '../../../service/api.service'
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import '../estilo.css'
import borrachaPNG from '../../../img/apagador.png'
import packageJson from '../../../../package.json';




export const WaiterLogin = (props) => {

  const { message, modal, notification } = App.useApp();

  const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
  const [waitDataBase, setWaitDataBase] = useRecoilState(waitDataBaseState)
  const [token, setToken] = useState(undefined)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [nome, setNome] = useState('')
  const [senha, setSenha] = useState('')
  const [msgError, setmsgError] = useState('')
  const [loading, setLoading] = useState(false)
  const [servicos, setServicos] = useState([])


  const navigate = useNavigate()

  const [value, setValue] = useState(0);



  useEffect(() => {
    if (waiterUser)
      if (value === 3)
        navigate('/manager/comandas')
      else if (value === 10)
        navigate('/manager/monitor')
      else
        navigate('/manager')

  }, [waiterUser])


  useEffect(() => {
    if (waitDataBase)
      continuar()
  }, [])



  async function continuar() {
    try {
      setLoading(true)
      setmsgError('')
      const result = await api.get('waiter/manager', {
        params: {
          token: waitDataBase ? waitDataBase?.database : email,
          email: waitDataBase ? waitDataBase?.database : email
        }
      })

      let serv = []
      if (result.data.mesas !== 0)
        serv.push('Mesas')
      if (result.data.comandas !== 0)
        serv.push('Comandas')
      if (result.data.monitor !== 0)
        serv.push('Monitor')

      setServicos(serv)

      setWaitDataBase(result.data)
    } catch (error) {
    }
    setLoading(false)
  }




  async function login() {
    try {
      //  console.log(waitDataBase)
      setLoading(true)
      setmsgError('')
      const database = waitDataBase?.database
      var user = {
        token: email,
        pwd: senha,
        senha: senha,
        nome: nome,
        database: database,
        sala: database,
      }

      const result = await api.post('api/v1.0/manager/login', user)
      if (result.data.status === 200) {
        let user_ = result.data.data
        user_.id = waitDataBase.id
        user_.mesas = waitDataBase?.mesas
        user_.comandas = waitDataBase?.comandas
        user_.monitor = waitDataBase?.monitor
        console.log(user_)
        setWaiterUser(user_)
      } else if (result.data.status === 503) {
        setWaiterUser(undefined)
        notification.error({
          style: { borderRadius: 10 },
          message: 'Erro de Servidor',
          duration: 4,
          description: 'Não foi possivel fazer comunicação com o servidor da loja, verifique se realmente o computador está ligado e conectado a internet!'
        })
      } else {
        setWaiterUser(undefined)
        notification.error({
          duration: 2,
          description: 'Usuário e/ou senha errado!'
        })
      }

    } catch (error) {
      console.log(error.message)
      notification.error({
        duration: 2,
        description: 'Algo errado, verifique se o computador na loja está realmente ligado!'
      })
    }
    setLoading(false)
  }


  function renderModo1() {
    return <>
      <strong style={{ fontSize: '1.7em', color: 'white' }}>Manager App</strong>
      <div style={{ fontSize: '0.75em', color: 'whitesmoke' }}>
        {waitDataBase ? "entre com seu usuário e senha" : "informe o token(e-mail cadastrado) do estabelecimento"}

      </div>
      <Input style={{ marginTop: 35 }} size='large' placeholder='Informe seu Token(e-mail)'
        prefix={<UserOutlined />}
        value={email}
        onChange={(e) => setEmail(e.target.value)} />

      <Button
        style={{ marginTop: 35 }}
        block
        size='large'
        loading={loading}
        type="primary"
        onClick={() => {
          continuar()
        }}>Continuar</Button>


    </>
  }


  function renderModo2() {
    return <>
      <Avatar size={54} src={'https://menuengfood.com.br/images/mobile/'.concat(waitDataBase?.id, '/logo/logo.png')} />
      <strong style={{ fontSize: '1.7em', color: 'white' }}>Manager Online</strong>
      <div style={{ fontSize: '0.75em', color: 'whitesmoke' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {waitDataBase?.fantasia} <img height={20} src={borrachaPNG} style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => setWaitDataBase(undefined)} />
        </div>


      </div>
      <Input style={{ marginTop: 35 }} size='large' placeholder='Informe seu usuário'
        autoComplete={'new-user'}
        prefix={<UserOutlined />}
        value={nome}
        onChange={(e) => setNome(e.target.value)} />
      <Input.Password style={{ marginTop: 10 }} size='large' placeholder='Informe a senha'
        prefix={<LockOutlined />}
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            login()
          }
        }} />



      <Flex gap="small" align="flex-start" vertical>
        <Segmented
          size="small"
          style={{ marginTop: 5 }}
          value={() => {
            if (value === 3)
              return 'Comandas'
            else if (value === 10)
              return 'Monitor'
            else
              return 'Mesas'
          }}
          onChange={(e) => {
            console.log(e)
            switch (e) {
              case 'Comandas': setValue(3); break;
              case 'Monito': setValue(10); break;
              default: setValue(0); break;
            }
          }}
          options={servicos} />
      </Flex>

      {
        /*
      <Space direction='horizontal' style={{ marginTop: 15, color: 'white' }}>
              <Radio.Group onChange={onChange} value={value}>
                <Radio style={{ color: 'white', minWidth: 80 }} value={0}> Mesa </Radio>
                <Radio style={{ color: 'white', minWidth: 80 }} value={3}> Comanda </Radio>
                <Radio style={{ color: 'white', minWidth: 80 }} value={10}> Monitor </Radio>
              </Radio.Group>
            </Space>
        */
      }



      <Button
        style={{ marginTop: 35 }}
        block
        size='large'
        loading={loading}
        type="primary"
        onClick={() => {
          login()
        }}>Entrar</Button>


    </>
  }

  return <div className='plano-de-fundo-login'
    style={{ backgroundColor: waitDataBase?.cor ? waitDataBase.cor : 'navy' }}>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      justifyContent: 'center',
      width: 433,
      alignItems: 'center',

    }}>
      {waitDataBase ? renderModo2() : renderModo1()}
      <div style={{ marginTop: 35 }}>
        <Flex justify='center' style={{ fontSize: '0.85em', color: 'whitesmoke' }}>
          versão: {packageJson.version}

        </Flex>
      </div>

    </div>

  </div>

}

