import React, { useEffect, useState } from 'react'
import { Tabs, Typography, Card, Col, Row, Tag, Avatar, Button, Select, Modal, Divider, Alert, App, Flex, Badge } from 'antd';

import { ClockCircleOutlined, LogoutOutlined, LoadingOutlined } from '@ant-design/icons'
import clienteImg from '../../../img/boy.png'
import garcomImg from '../../../img/garcom.png'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { socket } from '../../../service/socket'
import complementoPNG from '../../../img/abaixo-a-direita.png'
const { Title, Text } = Typography


export const WaiterMonitor = (props) => {

    const { notification } = App.useApp()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])
    const [setor, setSetor] = useState('C')
    const [loading, setLoading] = useState(true)
    const [erro, setErro] = useState(false)
    const [erroMsg, setErroMsg] = useState('')

    const [pedido, setPedido] = useState(undefined)


    useEffect(() => {
        const createClock = setInterval(() => {
            getStatus()
        }, 25000);
        return () => {
            clearInterval(createClock);
            socket.off('monitor')
        }
    }, [])



    useEffect(() => {
        socket.off('monitor')
        getStatus()
        //getStatus('P')
        //getStatus('B')
        //getStatus('5')
    }, [setor])

    async function getStatus(s) {
        try {
            const aux = s === undefined ? setor : s
            socket.emit('monitor', waiterUser?.database, setor, (response) => {
              //  console.log(response.data)
                if (response.status === 200 && response.data) {
                    switch (aux) {
                        case 'C':
                            setData1(response.data)
                            break;
                        case 'P':
                            setData2(response.data)
                            break;
                        case 'B':
                            setData3(response.data)
                            break;
                        case '5':
                            setData4(response.data)
                            break;
                    }
                    setErro(false)
                    setLoading(false)
                } else {
                    // notification.error({ description: 'Erro ao carregar dados' })
                    setErro(true)
                    setLoading(false)
                }
            })

        } catch (error) {
            //notification.error({ description: error.message })
            setErroMsg(error.message)
            setErro(true)
            setLoading(false)
        }
    }



    async function setPronto(item) {
        try {
            socket.emit('monitorPronto', waiterUser?.database, item.Chave, (response) => {
                // const response = await api3.get('/monitor/pronto?chave='.concat(item.Chave, '&status=PRONTO'));
                console.log(response)
                if (response.status === 200) {
                    notification.info({ description: 'Status alterado com sucesso', message: 'Alteração de status!', duration: 2 })
                    getStatus()
                } else {
                    notification.error({ description: 'Não foi possível processar a solicitação!' })
                }
            })

        } catch (error) {
            notification.error({ description: 'Não foi possível processar a solicitação,'.concat(error.message) })
        }
    }



    function changed_value(value) {
        setLoading(true)
        setSetor(value)
    }


    function renderMonitorando(){
        switch(setor){
            case 'C': return 'Monitorando setor verde...'
            case 'P': return 'Monitorando setor azul...'
            case 'B': return 'Monitorando setor violeta...'
            case '5': return 'Monitorando setor vermelho...'
        }
    }


    return <>
        <main style={{flex:1, overflowY:'auto', overflowX:'hidden'}}>
            <div>
                {erro && <Alert message={erroMsg ? erroMsg : "Erro ao carregar dados!"} type="error" showIcon />}

                <Alert message={<Flex> <LoadingOutlined style={{marginRight:5}}/> {renderMonitorando()} </Flex>} type="info" />
                
                <Tabs defaultActiveKey={setor} centered onChange={changed_value} style={{ margin: 5 }}>
                    <Tabs.TabPane tab={<Flex>
                        <Badge status="success" text={"SETOR VERDE".concat(setor === 'C' ? ' ('.concat(data1?.length, ')') : '')} />
                    </Flex>} key="C">
                        <Row>
                            {
                                data1?.map((item, index) => {
                                    return <Col xs={{ span: 12 }} lg={{ span: 8 }} key={'verde-'.concat(index)}>
                                        <Card size="small"
                                            style={{ margin: 2 }}
                                            key={index}
                                            title={item.Stipo}
                                            extra={
                                                <div className="monitor-painel-extra">
                                                    <ClockCircleOutlined /> {item.Fila}
                                                </div>}>

                                            <Row>
                                                <Col span={12}>
                                                    {'Pedido: '}{item.PedidoItemID}
                                                </Col>
                                                <Col span={12}>
                                                    {item.Cancelado === true && <Tag color="error">Cancelado</Tag>}
                                                </Col>
                                                <Col span={24} style={{ borderColor: 'whitesmoke', borderWidth: 1, borderStyle: 'solid', padding: 5 }}>
                                                    <Typography.Text type="success" strong delete={item.Cancelado === true}>
                                                        {item.Quantidade.concat(' ', item.ProdutoNome)}
                                                        <Row>
                                                            {
                                                                item.complementosMobile.map((item2, index2) => {
                                                                    return <Col key={'S'.concat(index2)} span={23} offset={1}
                                                                        style={{ color: '#c2c2c2' }}>
                                                                        <Typography.Text italic style={{ fontSize: '0.85em', color: 'darkgray' }}>
                                                                            <img src={complementoPNG} height={12} alt='compl' />  {item2.Quantidate2.concat('  x  ', item2.descricao)}
                                                                        </Typography.Text>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </Typography.Text>
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={garcomImg} />  {item.Funcionario}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={clienteImg} />  {item.Clientenome}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <Flex gap={'small'}>

                                                        <Button size="small" type="primary" danger style={{ minWidth: 70 }}
                                                            onClick={() => {
                                                                console.log('pedido', item)
                                                                setPedido(item)
                                                            }}>
                                                            Ver preparo
                                                        </Button>

                                                        <Button size="small" type="primary" style={{ minWidth: 70 }}
                                                            onClick={() => {
                                                                setPronto(item)
                                                            }}>
                                                            Pronto
                                                        </Button>

                                                    </Flex>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </Col>
                                })
                            }
                            {
                                (data1.length === 0 && loading === false) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>
                                    <Alert
                                        message="Atenção"
                                        description={"Nenhum pedido localizado para o setor de produção ".concat('setor verde')}
                                        type="info"
                                        showIcon
                                    />
                                </div>
                                </Col>
                            }

                            {
                                (data1.length === 0 && loading === true) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>  <LoadingOutlined />
                                </div>
                                </Col>
                            }

                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Badge color='blue' text={"SETOR AZUL".concat(setor === 'P' ? ' ('.concat(data2?.length, ')') : '')}  />} key="P">
                        <Row gutter={[8, 8]} style={{ marginLeft: 5, marginRight: 5 }}>
                            {
                                data2.map((item, index) => {
                                    return <Col xs={{ span: 12 }} lg={{ span: 8 }} key={'P'.concat(index)}>
                                        <Card size="small"
                                            style={{ width: '100%' }}
                                            key={index}
                                            title={item.Stipo}
                                            extra={
                                                <div>
                                                    <ClockCircleOutlined /> {item.Fila}
                                                </div>}>

                                            <Row>
                                                <Col span={12}>
                                                    {'Pedido: '}{item.PedidoItemID}
                                                </Col>
                                                <Col span={12}>
                                                    {item.Cancelado === true && <Tag color="error">Cancelado</Tag>}
                                                </Col>
                                                <Col span={24} style={{ borderColor: 'whitesmoke', borderWidth: 1, borderStyle: 'solid', padding: 5 }}>
                                                    <Text type="success" delete={item.Cancelado === true}>
                                                        {item.Quantidade.concat(' ', item.ProdutoNome)}
                                                        <Row>
                                                            {
                                                                item.complementosMobile.map((item2, index2) => {
                                                                    return <Col key={'S'.concat(index2)} span={23} offset={1}
                                                                        style={{ color: '#c2c2c2', fontSize: 10 }}>
                                                                        <Typography.Text italic style={{ fontSize: '0.85em', color: 'darkgray' }}>
                                                                            <img src={complementoPNG} height={12} alt='compl' />  {item2.Quantidate2.concat('  x  ', item2.descricao)}
                                                                        </Typography.Text>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </Text>
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={garcomImg} /> {item.Funcionario}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={clienteImg} /> {item.Clientenome}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <Flex gap={'small'}>

                                                        <Button size="small" type="primary" danger style={{ minWidth: 70 }}
                                                            onClick={() => {
                                                                console.log('pedido', item)
                                                                setPedido(item)
                                                            }}>
                                                            Ver preparo
                                                        </Button>

                                                        <Button size="small" type="primary" style={{ minWidth: 70 }}
                                                            onClick={() => setPronto(item)}>
                                                            Pronto
                                                        </Button>

                                                    </Flex>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </Col>
                                })
                            }
                            {
                                (data2.length === 0 && loading === false) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>
                                    <Alert
                                        message="Atenção"
                                        description={"Nenhum pedido localizado para o setor de produção ".concat('setor azul')}
                                        type="info"
                                        showIcon
                                    />
                                </div>
                                </Col>
                            }
                            {
                                (data2.length === 0 && loading === true) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>  <LoadingOutlined />
                                </div>
                                </Col>
                            }
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Badge color='purple' text={"SETOR VIOLETA".concat(setor === 'B' ? ' ('.concat(data3?.length, ')') : '')}  />} key="B">
                        <Row gutter={[8, 8]} style={{ marginLeft: 5, marginRight: 5 }}>
                            {
                                data3.map((item, index) => {
                                    return <Col xs={{ span: 12 }} lg={{ span: 8 }} key={'B'.concat(index)}>
                                        <Card size="small"
                                            style={{ width: '100%' }}
                                            key={index}
                                            title={item.Stipo}
                                            extra={
                                                <div>
                                                    <ClockCircleOutlined /> {item.Fila}
                                                </div>}>

                                            <Row>
                                                <Col span={12}>
                                                    {'Pedido: '}{item.PedidoItemID}
                                                </Col>
                                                <Col span={12}>
                                                    {item.Cancelado === true && <Tag color="error">Cancelado</Tag>}
                                                </Col>
                                                <Col span={24} style={{ borderColor: 'whitesmoke', borderWidth: 1, borderStyle: 'solid', padding: 5 }}>
                                                    <Typography.Text type="success" delete={item.Cancelado === true}>
                                                        {item.Quantidade.concat(' ', item.ProdutoNome)}
                                                        <Row>
                                                            {
                                                                item.complementosMobile.map((item2, index2) => {
                                                                    return <Col key={'S'.concat(index2)} span={23} offset={1}
                                                                        style={{ color: '#c2c2c2', fontSize: 10 }}>
                                                                        <Typography.Text italic style={{ fontSize: '0.85em', color: 'darkgray' }}>
                                                                            <img src={complementoPNG} height={12} alt='compl' />  {item2.Quantidate2.concat('  x  ', item2.descricao)}
                                                                        </Typography.Text>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </Typography.Text>
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={garcomImg} /> {item.Funcionario}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={clienteImg} /> {item.Clientenome}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <Flex gap={'small'}>

                                                        <Button size="small" type="primary" danger style={{ minWidth: 70 }}
                                                            onClick={() => {
                                                                console.log('pedido', item)
                                                                setPedido(item)
                                                            }}>
                                                            Ver preparo
                                                        </Button>

                                                        <Button size="small" type="primary" style={{ minWidth: 70 }}
                                                            onClick={() => setPronto(item)}>
                                                            Pronto
                                                        </Button>

                                                    </Flex>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </Col>
                                })
                            }
                            {
                                (data3.length === 0 && loading === false) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>
                                    <Alert
                                        message="Atenção"
                                        description={"Nenhum pedido localizado para o setor de produção ".concat('setor violeta')}
                                        type="info"
                                        showIcon
                                    />
                                </div>
                                </Col>
                            }
                            {
                                (data3.length === 0 && loading === true) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>  <LoadingOutlined />
                                </div>
                                </Col>
                            }
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Badge color='red' text={"SETOR VERMELHO".concat(setor === '5' ? ' ('.concat(data4?.length, ')') : '')}  />} key="5">
                        <Row gutter={[8, 8]} style={{ marginLeft: 5, marginRight: 5 }}>
                            {
                                data4.map((item, index) => {
                                    return <Col xs={{ span: 12 }} lg={{ span: 8 }} key={'I5'.concat(index)}>
                                        <Card size="small"
                                            style={{ width: '100%' }}
                                            key={index}
                                            title={item.Stipo}
                                            extra={
                                                <div>
                                                    <ClockCircleOutlined /> {item.Fila}
                                                </div>}>

                                            <Row>
                                                <Col span={12}>
                                                    {'Pedido: '}{item.PedidoItemID}
                                                </Col>
                                                <Col span={12}>
                                                    {item.Cancelado === true && <Tag color="error">Cancelado</Tag>}
                                                </Col>
                                                <Col span={24} style={{ borderColor: 'whitesmoke', borderWidth: 1, borderStyle: 'solid', padding: 5 }}>
                                                    <Typography.Text type="success" delete={item.Cancelado === true}>
                                                        {item.Quantidade.concat(' ', item.ProdutoNome)}
                                                        <Row>
                                                            {
                                                                item.complementosMobile.map((item2, index2) => {
                                                                    return <Col key={'S'.concat(index2)} span={23} offset={1}
                                                                        style={{ color: '#c2c2c2', fontSize: 10 }}>
                                                                        <Typography.Text italic style={{fontSize:'0.85em', color:'darkgray'}}>
                                                                          <img src={complementoPNG} height={12} alt='compl'/>  {item2.Quantidate2.concat('  x  ', item2.descricao)}
                                                                        </Typography.Text>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </Typography.Text>
                                                </Col>
                                                <Col span={24}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={garcomImg} /> {item.Funcionario}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <div style={{ fontSize: '0.85em' }}>
                                                        <Avatar size={18} src={clienteImg} /> {item.Clientenome}
                                                    </div>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 8 }}>
                                                    <Flex gap={'small'}>

                                                        <Button size="small" type="primary" danger style={{ minWidth: 70 }}
                                                            onClick={() => {
                                                                console.log('pedido', item)
                                                                setPedido(item)
                                                            }}>
                                                            Ver preparo
                                                        </Button>

                                                        <Button size="small" type="primary" style={{ minWidth: 70 }}
                                                            onClick={() => setPronto(item)}>
                                                            Pronto
                                                        </Button>

                                                    </Flex>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </Col>
                                })
                            }
                            {
                                (data4.length === 0 && loading === false) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>
                                    <Alert
                                        message="Atenção"
                                        description={"Nenhum pedido localizado para o setor de produção ".concat('setor vermelho')}
                                        type="info"
                                        showIcon
                                    />
                                </div>
                                </Col>
                            }
                            {
                                (data4.length === 0 && loading === true) && <Col span={24}> <div
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 20 }}>  <LoadingOutlined />
                                </div>
                                </Col>
                            }
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>


            <Modal title={null}
                footer={null}
                destroyOnClose={true}
                closable={false}
                open={pedido}
                onOk={() => setPedido(undefined)} onCancel={() => setPedido(undefined)}>
                <Row>
                    <Col span={24}>
                        <Title level={4}> Modo de Preparo</Title>
                    </Col>
                    <Col span={24}>
                        {pedido?.ModoPreparo ? pedido.ModoPreparo : 'O produto não possui informações de preparação.'}
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button type="primary" size="small" onClick={() => setPedido(undefined)}>Fechar</Button>
                    </Col>
                </Row>
            </Modal>

        </main>
    </>

}


