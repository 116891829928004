import React, { useState, useEffect } from 'react'
import {
    Table, Message, Button as ButtonRSuite, Divider, FlexboxGrid, Col, Stack
} from 'rsuite';
import { DatePicker, Button, Space, App, ConfigProvider, Popover } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import ptBR from "antd/es/locale/pt_BR";
import TagIcon from '@rsuite/icons/Tag';
import { isMobile } from 'react-device-detect';
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Column, HeaderCell, Cell } = Table

export const ContasPagar = (props) => {



    const navigate = useNavigate()
    const waiterUser = useRecoilValue(waiterUserState)
    const [dates, setDates] = useState([dayjs().add(-30, 'days'), dayjs()])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [valores, setValores] = useState({
        pago: 0,
        vencido: 0,
        vencer: 0
    })
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        pesquisar()
    }, [])


    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return false//current && current > dayjs().endOf('day')
    };


    async function pesquisar() {
        try {
            setLoading(true)
            socket.emit('contasPagar', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {
                let v = {
                    pago: 0,
                    vencer: 0,
                    vencido: 0
                }

                result.data.map(item => {
                    if (item.pagamentoPago === 1)
                        v.pago = v.pago + parseFloat(item.reciboValorPago)
                    else {
                        if (dayjs("".concat(item.pagamentoDtVencimento).substring(0, 10)).diff(dayjs(), 'days') < 0)
                            v.vencido = v.vencido + parseFloat(item.pagamentoValor)
                        else
                            v.vencer = v.vencer + parseFloat(item.pagamentoValor)
                    }
                })
                setValores(v)
                setLoading(false)
                setData(result.data)
            });


        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }


    return (<main>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                <Space size={'small'}>
                    <Popover content={"Pagos"} >
                        <TagIcon style={{ color: 'green' }} />
                    </Popover>
                    {valores.pago?.toFixed(2)}
                    <Divider vertical />
                    <Popover content={"A vencer"} >
                        <TagIcon style={{ color: 'orange' }} />
                    </Popover>
                    {valores.vencer?.toFixed(2)}
                    <Divider vertical />
                    <Popover content={"Vencidos"} >
                        <TagIcon style={{ color: 'red' }} />
                    </Popover>
                    {valores.vencido?.toFixed(2)}
                </Space>

                <Space>

                    <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>

                    <ConfigProvider locale={ptBR}>
                        <RangePicker
                            locale={ptBR}
                            size='small'
                            allowClear={false}
                            format={'DD-MM-YYYY'}
                            value={dates || null}
                            disabledDate={disabledDate}

                            onChange={(val) => {
                                setDates(val);
                            }}

                            changeOnBlur />
                    </ConfigProvider>
                    <Button size='small' type='primary' style={{ marginLeft: 5 }}
                        onClick={() => pesquisar()}>Pesquisar</Button>



                </Space>

            </div>

            <div style={{ marginTop: 10 }}>

                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}

                <Table
                    autoHeight={true}
                    style={{ fontSize: '0.8em' }}
                    hover
                    loading={loading}
                    rowHeight={isMobile ? 43 : 30}
                    headerHeight={30}
                    showHeader={isMobile ? false : true}
                    data={data}
                    onRowClick={rowData => {
                        // pesquisar()
                    }}>

                    {isMobile ? <>

                        <Column flexGrow={1} fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>VENCIMENTO</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    const dias = dayjs("".concat(rowData.pagamentoDtVencimento).substring(0, 10)).diff(dayjs(), 'days')
                                    return <FlexboxGrid>
                                        <FlexboxGrid.Item colspan={24}>
                                            <Stack>
                                                {dayjs("".concat(rowData.pagamentoDtVencimento).substring(0, 10)).format('DD/MM/YYYY')}
                                                <Divider vertical />
                                                {rowData.pagamentoValor}
                                                <Divider vertical />
                                                <div>
                                                    <TagIcon style={{ color: rowData.pagamentoPago === 1 ? 'green' : (dias < 0 ? 'red' : 'orange') }} />
                                                    {rowData.pagamentoPago === 0 ? (dias < 0 ? ' Vencido' : ' A vencer') : ' Pago'}
                                                </div>
                                            </Stack>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={24}>
                                            {rowData.pconNome}
                                            <Divider vertical />
                                            {rowData.pagamentoDescricao}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                }}
                            </Cell>
                        </Column>

                    </> : <>
                        <Column width={100} align="center" fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>VENCIMENTO</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    // console.log(rowData)
                                    return dayjs("".concat(rowData.pagamentoDtVencimento).substring(0, 10)).format('DD/MM/YYYY')
                                }}
                            </Cell>
                        </Column>
                        <Column width={250}>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>CONTA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.pconNome

                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={1} fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>DESCRIÇÃO</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.pagamentoDescricao

                                }}
                            </Cell>
                        </Column>
                        <Column width={100} align="center" fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>VALOR R$</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.pagamentoValor

                                }}
                            </Cell>
                        </Column>
                        <Column width={100} align="left" fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>STATUS</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    const dias = dayjs("".concat(rowData.pagamentoDtVencimento).substring(0, 10)).diff(dayjs(), 'days')
                                    return <div>
                                        <TagIcon style={{ color: rowData.pagamentoPago === 1 ? 'green' : (dias < 0 ? 'red' : 'orange') }} />
                                        {rowData.pagamentoPago === 0 ? (dias < 0 ? ' Vencido' : ' A vencer') : ' Pago'}
                                    </div>
                                }}
                            </Cell>
                        </Column>

                        <Column width={100} align="right" fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>PAGO EM</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.reciboData ?
                                        dayjs("".concat(rowData.reciboData).substring(0, 10)).format('DD/MM/YYYY') : ''

                                }}
                            </Cell>
                        </Column>
                        <Column width={100} align="center" fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3, fontSize: '0.8em' }}>VALOR PAGO</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.reciboValorPago

                                }}
                            </Cell>
                        </Column>
                    </>
                    }
                </Table>

            </div>
        </div>
    </main>
    )
}

