import React, { useState, useEffect, useRef } from 'react'
import '../../admin.css'
import '../address/address.css'
import './addressMapPoint.css'
import {
    Row, Col, Button, Table, Select, Input, App, Typography, Space, Popover
} from 'antd';
import '../appBar/appBar.css'
import { EditOutlined, DeleteOutlined, StarFilled, LeftOutlined } from '@ant-design/icons'
import { api, GOOGLE_KEY } from '../../service/api.service'
import axios from 'axios'
import { calcularTaxa, distance } from '../../service/dal.service'
import { useParams } from 'react-router-dom';
import { current_address, lojaState, userState } from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import { getAuth, signInAnonymously } from "firebase/auth";
import { GoogleMap, useJsApiLoader, Marker, useLoadScript, MarkerF, InfoWindow } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';




export const AddressMapPoint = (props) => {

    const [salvando, setSalvando] = useState(false)
    const loja = useRecoilValue(lojaState)
    const inputRef = useRef();
    const params = useParams()
    const [user, saveUser] = useRecoilState(userState)
    const { message } = App.useApp();
    const [libraries] = useState(['places']);
    const [mapref, setMapRef] = useState(null);
    const [marker, serMarker] = useState(null);
    const [center, setCenter] = useState({
        lat: props.enderecoTemp?.latitude,
        lng: props.enderecoTemp?.longitude
    })
    const [markerVisible, setMarkerVisible] = useState(true)
    //  const isLoaded = true

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_KEY,
        libraries: libraries

    });


    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    useEffect(() => {
        disableButtonSalvar()
    }, [props.enderecoTemp])


    useEffect(() => {
        loaduser()
    }, [])

    async function loaduser() {
        try {
            const auth = getAuth();
            if (!auth.currentUser) {
                var user2 = await signInAnonymously(auth)
                user.address = []
                saveUser({ ...user, uid: user2.user.uid })
            }
        } catch (error) {

        }
    }




    function disableButtonSalvar() {
        try {
            // console.log('Endereco: ', props.enderecoTemp)
            if (!props.enderecoTemp?.uf)
                return true
            if (!props.enderecoTemp?.localidade)
                return true
            if (!props.enderecoTemp?.bairro)
                return true
            if (!props.enderecoTemp?.logradouro)
                return true
            if (!props.enderecoTemp?.numero)
                return true
            if (!props.enderecoTemp?.referencia)
                return true

            return false

        } catch (error) {
            return true
        }
    }

    const handleOnLoad = map => {
        setMapRef(map);
    };

    const onLoadMarker = marker => {
        serMarker(marker);
    };


    async function salvar() {
        try {
            setSalvando(true)
            await props.salvar()

        } catch (error) {
            message.error(error.message)
        }
        setSalvando(false)
    }

    function handleCenterChanged() {
        if (mapref) {
            const newCenter = mapref.getCenter();
            //  console.log(newCenter);
            /*
            setCenter({
                lat: newCenter.lat(),
                lng: newCenter.lng()
            })
            */
        }
    };



    return <div style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    }}>
            {isLoaded && <GoogleMap
                onLoad={handleOnLoad}
                mapContainerStyle={containerStyle}
                center={center}
                options={{
                    disableDefaultUI: true,
                    clickableIcons: false
                }}
                zoom={17}
                onCenterChanged={handleCenterChanged}
                onDragStart={() => setMarkerVisible(false)}
                onDragEnd={() => {
                    if (mapref) {
                        const newCenter = mapref.getCenter();
                        var ed = { ...props.enderecoTemp }
                        ed.latitude = newCenter.lat()
                        ed.longitude = newCenter.lng()
                        props.setEnderecoTemp(ed)
                        setCenter({
                            lat: ed.latitude,
                            lng: ed.longitude
                        })
                    }
                    setMarkerVisible(true)
                }}>

                <MarkerF
                    onLoad={onLoadMarker}
                    visible={markerVisible}
                    position={center}
                >

                </MarkerF>


            </GoogleMap>
            }



            <img src={require('../../img/pinpreto.png')}
                height={36}
                width={36}
                hidden={markerVisible}
                style={{
                    left: '50%',
                    marginLeft: -18,
                    marginTop: -65, //34
                    position: 'absolute',
                    top: '50%'
                }}
            />

            <span className='link'>
                <form className='form' action='#'
                    hidden={!markerVisible}>
                    <div className='form-div'>Você está aqui?</div>
                    <div className='form-div-2'>Ajuste a localização</div>
                </form>
            </span>


            <div className='address-title'>
                <div className='address-title-1'>
                    {props?.enderecoTemp?.logradouro.concat(" ", props?.enderecoTemp?.numero)}
                </div>
                <div className='address-title-2'>
                    {props?.enderecoTemp?.bairro.concat(', ', props?.enderecoTemp?.localidade, "-", props?.enderecoTemp?.uf)}
                </div>
            </div>

            <div className='button-confirm'
                hidden={!markerVisible}>

                <Button
                    size='large'
                    style={{ marginRight: 10 }}
                    icon={<LeftOutlined />}
                    onClick={() => props.setModoEndereco(1)}
                >
                    Voltar
                </Button>

                <Button
                    type='primary'
                    size='large'
                    danger
                    loading={salvando}
                    disabled={disableButtonSalvar()}
                    onClick={() => salvar()}>
                    Confirmar localização
                </Button>
            </div>
    </div>

}

export default AddressMapPoint