import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import './cardapioLogin.css'
import './mainCardapio.css'
import {
  LeftCircleTwoTone, RightCircleTwoTone, LoadingOutlined,
  MessageOutlined, MinusOutlined, PlusOutlined, CheckOutlined
} from '@ant-design/icons'
import {
  Flex, App, Image, Button as Button2, List, Skeleton, Avatar,
  Typography, Drawer, Input, Checkbox, InputNumber, Radio, Divider, Alert
} from 'antd'
import { titleize } from '../../service/util'
import { useNavigate } from 'react-router-dom'
import { api } from '../../service/api.service'
import { pedidoCardapioState, callCardapioState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import './slick.css'
//import { socket } from '../../service/socket'
import devoltaPNG from '../../../src/img/de-volta.png'
import balancePNG from '../../../src/img/weight-scale.png'
import garcomPNG from '../../../src/img/garcom.png'
import { FlexboxGrid, Badge as BadgeSuite } from 'rsuite'




export const MainCardapio = (props) => {

  const [cardapio, setCardapio] = useRecoilState(pedidoCardapioState)
  const [categorias, setCategorias] = useState([])
  const [loading, setLoading] = useState(false)
  const { notification } = App.useApp()

  const [produto, setProduto] = useState(undefined)

  const [data, setData] = useState([]);
  const [observacoes, setObservacoes] = useState('');
  const [totalGrupos, setTotalGrupos] = useState(0);
  const [Quant, setQuant] = useState(1);
  const [forceUpdate, setForceUpdate] = useState(true)
  const [disableok, setDisableok] = useState(true);
  const [quantTemp, setQuantTemp] = useState(1)
  const [open, setOpen] = useState(false)
  const [openProd, setOpenProd] = useState(false)

  const [call, setCall] = useRecoilState(callCardapioState)
  const [openPedido, setOpenPedido] = useState(false)



  const settings = {

    className: "slider-container",
    dots: false,
    infinite: true,
    centerMode: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: cardapio?.data?.cardapio?.length > 2 ? 2 : 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />

  };


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return <div
      style={{
        ...style,
        top: '50%',
        display: 'block',
        padding: 0,
        right: -25,
        marginRight: 0,
        width: 20,
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        color: cardapio?.data?.cor,
        position: 'absolute',
        backgroundColor: 'transparent'
      }}
      onClick={onClick} >
      <RightCircleTwoTone twoToneColor={cardapio?.data?.cor} style={{ fontSize: 20 }} />
    </div>
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;

    return <div
      style={{
        ...style,
        top: '50%',
        display: 'block',
        padding: 0,
        left: 0,
        marginLeft: -25,
        width: 20,
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        color: cardapio?.data?.cor,
        position: 'absolute',
        backgroundColor: 'transparent'
      }}
      onClick={onClick} >
      <LeftCircleTwoTone twoToneColor={cardapio?.data?.cor} style={{ fontSize: 20 }} />
    </div>
  }




  useEffect(() => {
  //  window.history.replaceState(null, null, "/cardapio");
    var ar = Object.values(cardapio?.data?.cardapio)
    setCategorias([...ar])
  }, [])


  useEffect(() => {
    // console.log('prod', produto)
    validarProduto()
    return () => { }
  }, [data, Quant, forceUpdate])




  function validarProduto() {
    var obrigatorios = 0;
    var validados = 0;

    for (var i = 0; i < data.length; i++) {
      if (data[i].obrigatorio === 1) {
        obrigatorios++;
        var selected = 0;
        data[i].itens.map(item => {
          selected += item.selected;
          return item
        });

        if (selected >= data[i].minimo)
          validados++;
      }
    }
    var tem = obrigatorios <= validados ? false : true
    setDisableok(tem);
  }


  async function callGarcom() {
    try {
      let mesa = {
        sala: cardapio?.sala,
        mesaID: cardapio?.data?.mesaID,
        clienteok: 1
      }

      await api.post('/api/v1.0/manager/cardapio/callGarcom', mesa, {
        headers: {
          'x-access-token': cardapio?.data.token,
          "sala": cardapio?.sala,

        }
      })


    } catch (error) { }
  }


  async function produtoClicado(item) {
    try {
      var _produto = JSON.parse(JSON.stringify(item))
      _produto.sala = cardapio?.sala

      if (item.Tipo === 0) {
        setLoading(true)
        var result = await api.post('/api/v1.0/manager/cardapio/produto', _produto, {
          headers: {
            'x-access-token': cardapio?.data.token,
            "sala": cardapio?.sala
          }
        })

        if (result.data.status === 200) {
          //  console.log(200, result.data.data, _produto)
          _produto = result.data.data
        } else {
          notification.error({
            duration: 3,
            description: 'Erro ao carregar dados do produto!'
          })
        }
      }


      if (item.Tipo > 0) {
        _produto.nomeTipo = _produto.nomeTipo ? _produto.nomeTipo : "";
        _produto.descricao = _produto.nomeTipo.concat(' ', item.nome).trim();

      }


      if (!_produto.venda)
        _produto.venda = 0;

      if (!_produto.Quant)
        _produto.Quant = 1;
      if (!_produto.quant)
        _produto.quant = 1;

      if (_produto.AtivarPromocao) {
        if (_produto.AtivarPromocao === 1 && _produto.Promo > 0) {
          _produto.venda = _produto.Promocao;
        }
      }

      var chave = 77777;
      if (_produto.grupos) {
        _produto.grupos.map(grupo => {
          grupo.data = grupo.itens;
          if (grupo.chave === 0) {
            grupo.chave = chave;
            grupo.data.map(subitem => {
              subitem.idgrupo = chave;
              return subitem
            })

            chave = chave + 1;
          }
          return grupo
        })
      } else {
        _produto.grupos = []
      }
      var aux = _produto.grupos.find(v => v.chave === 88888)
      if (!aux)
        _produto.grupos.push({
          chave: 88888, descricao: 'Alguma observação?', maximo: 150, minimo: 0,
          obrigatorio: 0, isobs: true, itens: [], data: [{ selected: 0 }], produtoid: 0, selected: 0
        })

      setProduto(_produto);
      setData(_produto.grupos);
      validarProduto();
    } catch (error) {
      console.log(error.message)
      notification.error({
        duration: 3,
        description: error.message
      })
    }

    setLoading(false)

  }




  function renderPreco(item) {

    if (item.tamanhoid > 0)
      return <div></div>

    var isPromocao = item.AtivarPromocao === 1 ? true : false; //Se é promoção
    if (item.from) {
      return <span>{item.from}</span>
    } else {
      if (item.venda && item.venda > 0) {
        return <span>
          {isPromocao && <span>
            {"Por R$".concat(item.Promo.toFixed(2))} </span>}
          <span style={Object.assign({
            fontSize: isPromocao ? 11 : 13, color: isPromocao ? 'gray' : '#4F4F4F',
          }, isPromocao ? { textDecorationLine: "line-through" } : {})}>

            {
              item.venda && (item.venda > 0 && ''
                .concat(isPromocao ? 'De ' : '', 'R$', item.venda.toFixed(2).toString()))
            }
          </span>
        </span>

      } else {
        var from = item.venda;
        if (item.grupos) {
          item.grupos.map(grupo => {
            if (grupo.obrigatorio === 1) {
              grupo.itens.map(itv => {
                itv.valor = parseFloat((itv.valor / grupo.minimo).toFixed(2));
                if (grupo.minimo > 1)
                  itv.nome = '1/'.concat(grupo.minimo, ' ', itv.nome);
                return itv
              })
              from = from + (grupo.itens.reduce((a, b) => Math.min(a, b.valor), 10000000) * grupo.minimo);
            }
            return grupo
          })
          item.from = 'a partir de R$'.concat(from.toFixed(2))
        }
        return <span style={{ color: 'white' }}>{item.from}</span>
      }
    }
  }


  function renderImagem(item) {

    if (item === undefined)
      return

    if (item?.error)
      return "https://menuengfood.com.br/images/noimage.png"
    else if (item?.Tipo === 0) {
      return "https://menuengfood.com.br/images/mobile/"
        .concat(cardapio.data.id, '/produtos/p', item.produtoID, '.png')
    } else {
      return "https://menuengfood.com.br/images/mobile/"
        .concat(cardapio.data.id, '/tamanhos/t', item?.tamanhoid, '.png')
    }
  }


  return (
    <div>
      <div style={{
        position: 'sticky',
        top: 64,
        backgroundColor: 'whitesmoke',
        borderBottomWidth: 0.3,
        borderBottomStyle: 'solid',
        borderBottomColor: 'lightgray',
        zIndex: 10000,
        paddingTop: 12,
        paddingBottom: 12

      }}
      >
        <div className="slider-container"
          style={{
            width: window.innerWidth - 60, marginLeft: 30, marginRight: 30
          }}>

          {
            <Slider {...settings} >
              {
                Array.isArray(categorias) && categorias?.map((item, index) => {
                  return <div key={item.catDescricao.concat('-', index, '-', item.catChave)}>
                    <a href={"#cat-".concat(item.catChave)}
                      style={{
                        margin: 5,
                        padding: 5,
                        color: cardapio?.data?.cor,
                        fontWeight: 600,
                        textDecoration: 'none'
                      }}>
                      {titleize(item.catDescricao)}
                    </a>

                  </div>
                })
              }
            </Slider>
          }

        </div>
      </div>

      {(call?.garcom && call?.clienteok === 0) && <Alert message={<div>
        <Flex align="center">
          <img src={garcomPNG} alt="g" style={{ marginRight: 5 }} height={20} />
          {<b> {titleize(call?.garcom)} </b>} : já estou indo
        </Flex>
      </div>} type="info"
        closable style={{ margin: 5 }}
        onClick={() => {
          setCall({ ...call, clienteok: 1 })
          callGarcom()
        }} />
      }

      {
        Array.isArray(categorias) && categorias?.map((itemC, index) => {
          return <Flex vertical style={{ margin: 10, zIndex: -1 }} key={'cat-'.concat(index, '-', itemC.catChave)}
            id={"cat-".concat(itemC.catChave)}
          >
            <Typography.Title level={4} style={{ color: cardapio?.data?.cor }}>
              {titleize(itemC.catDescricao)}
            </Typography.Title>

            <List
              style={{ zIndex: 2 }}
              className="demo-loadmore-list"
              loading={false}
              itemLayout="horizontal"
              dataSource={itemC?.produtos}

              renderItem={(item, indexP) => (

                <List.Item style={{ zIndex: 0 }} onClick={() => {
                  setProduto(item)

                  setOpenProd(true)
                  produtoClicado(item)
                }}>
                  <Skeleton avatar title={false} loading={item.loading} active style={{ zIndex: 0 }}>
                    <List.Item.Meta style={{ zIndex: 0 }}
                      avatar={!item.error && <Avatar shape="square" size={70} style={{ zIndex: 200 }}
                        src={renderImagem(item)}
                        onError={() => {
                          var itemCC = { ...itemC }
                          var cats = [...categorias]
                          var itemCopy = { ...item }
                          itemCC.produtos = [...itemCC.produtos]
                          itemCopy.error = true
                          //  console.log(itemC.produtos[indexP], indexP, itemCopy)
                          itemCC.produtos[indexP] = itemCopy
                          cats[index] = itemCC

                          setCategorias(cats)

                        }}



                      />}
                      title={item.nome}
                      description={item.informacao}
                    />
                    <div style={{ marginLeft: 5 }}>
                      {renderPreco(item)}
                    </div>
                  </Skeleton>
                </List.Item>
              )}
            />
          </Flex>
        })
      }


      <Drawer
        zIndex={100001}
        width={'100%'}
        title={null}
        destroyOnClose={true}
        open={openProd}
        closable={false}
        onClose={() => {
          setOpenProd(false)
          setProduto(undefined)
          setData([])
          setQuant(1)
        }}
        afterOpenChange={(value) => {
          if (value === false) {
            setOpenProd(false)
            setProduto(undefined)
            setData([])
            setQuant(1)
            setTotalGrupos(0)
          }
        }}
        style={{ margin: 0, padding: 0 }}>

        <>
          <div style={{
            display: 'grid',
            width: '100%'
          }}>
            <div style={{
              overflowY: 'auto',
              backgroundColor: 'white',
            }}>
              <Flex justify='space-between'>
                <div style={{
                  fontSize: '1.1em',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <Flex align="center" justify="center"
                  >


                    <Image
                      style={{ objectFit: 'contain', marginRight: 10 }}
                      preview={false}
                      height={25}
                      width={25}
                      src={devoltaPNG}
                      onClick={() => {
                        setOpenProd(false)
                        setProduto(undefined)
                        setData([])
                        setQuant(1)
                      }} />

                    <Image
                      style={{ objectFit: 'contain', marginLeft: 10 }}
                      preview={false}
                      height={56}
                      width={57}
                      src={renderImagem(produto)} />

                  </Flex>

                  <div style={{ marginLeft: 20 }}>
                    <div> {produto?.nome} </div>

                    {produto?.informacao &&
                      <div style={{ color: 'darkgray', fontSize: '0.8em', fontStyle: 'italic' }}>
                        {produto?.informacao}
                      </div>
                    }
                    {produto?.venda > 0 && <div style={{
                      fontWeight: 'normal',
                      color: 'green'
                    }}>
                      {'R$'.concat(produto?.venda?.toFixed(2))}
                    </div>
                    }
                  </div>

                </div>


              </Flex>
              <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              <Flex vertical >

                {loading && <Flex align="center" justify="center">
                  <LoadingOutlined loop={true} /> loading...
                </Flex>
                }
                {data.map((section, index) => {
                  return <div key={section.descricao.concat(section.chave, '-', index)}
                  >
                    {renderHeader(section)}
                    {
                      section.data.map((item, indexItem) => (
                        <div key={'grupo-'.concat(item.idappgrupoitens, '-', indexItem)}>
                          {
                            renderItem(item, section, indexItem)
                          }
                        </div>
                      ))
                    }

                  </div>
                })}


              </Flex>
            </div>
            <div style={{
              display: 'flex',
              padding: 30,
              alignItems: 'center',
              position: 'fixed',
              bottom: 0,
              left: 0,
              transition: '500ms',
              zIndex: 10000,
              width: '100%'
            }}>
              <FlexboxGrid style={{ width: '100%' }}>
                <FlexboxGrid.Item colspan={12}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <Flex>
                    <Radio.Group onChange={(e) => { }}>
                      <Radio.Button style={{
                        color: 'red',
                        fontWeight: 'bold'
                      }} value="-1"
                        onClick={() => changeQuant(-1)}>-</Radio.Button>

                      <Radio.Button
                        value="0"
                        onClick={() => {
                          if (produto.unidade !== "UN") {
                            setQuantTemp(Quant)
                            setProduto(produto)
                          }
                        }}>{produto?.Quant}</Radio.Button>

                      <Radio.Button style={{
                        color: 'blue',
                        fontWeight: 'bold'
                      }} value="1"
                        onClick={() => changeQuant(1)}>+</Radio.Button>

                      {produto?.unidade !== "UN" && <Radio.Button onClick={() => {
                        if (produto?.unidade !== "UN") {
                          setQuantTemp(produto?.Quant)
                          setProduto(produto)
                        }
                      }}>
                        <Image
                          src={balancePNG}
                          height={15}
                          preview={false}
                          width={15}
                          style={{ objectFit: 'contain' }}
                          alt='balance' />
                      </Radio.Button>
                      }
                    </Radio.Group>
                  </Flex>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}
                >
                  <Button2
                    disabled={disableok}
                    block
                    style={{ fontSize: '0.8em' }}
                    type='primary'
                    icon={<CheckOutlined />}
                    onClick={() => addProduto()}>
                    {'Adicionar R$'
                      .concat(((produto?.venda + totalGrupos) * produto?.Quant).toFixed(2).toString())}

                  </Button2>
                </FlexboxGrid.Item>
              </FlexboxGrid>




            </div>
          </div>

          <Drawer
            title={<div onClick={() => {
              produto.Quant = quantTemp;
              produto.quant = produto.Quant;
              if (produto.Quant <= 0) {
                produto.Quant = 1;
                produto.quant = 1;
              }
              setQuant(produto.Quant);
              setProduto(produto);
              calcularTotal();
              setOpen(false)

            }} style={{ color: 'white' }}>Salvar</div>}
            closeIcon={<Image src={devoltaPNG} height={22} width={22} style={{ objectFit: 'contain' }} preview={false}
            />}
            placement={"bottom"}
            height={200}
            closable={true}
            onClose={() => {
              produto.Quant = quantTemp;
              produto.quant = produto.Quant;
              if (produto.Quant <= 0) {
                produto.Quant = 1;
                produto.quant = 1;
              }
              setQuant(produto.Quant);
              setProduto(produto);
              calcularTotal();
              setOpen(false)
            }}
            open={open}>
            <Flex vertical>
              Quantidade:
              <Flex align='center'>
                <InputNumber
                  style={{ marginRight: 5 }}
                  decimalSeparator=','
                  precision={3}
                  value={quantTemp}
                  onChange={(e) => setQuantTemp(e)} />

                {' '.concat(produto?.unidade)}

              </Flex>

            </Flex>
          </Drawer>
        </>

      </Drawer>


     

    </div>
  );




  function checkBoxPress(item, section, index) {
    var optionMax = 0;
    var count = 0;
    var maiorVal = 0; //Utilizado caso this.state.produto.calcularComplenetos == 1
    item.selected = item.selected === 0 ? 1 : 0;
    item.quant = item.selected;
    item.Quant = item.selected
    section.selected = item.selected;

    for (var i = 0; i < section.data.length; i++) {
      if (i !== index) {
        if (section.chave !== 99999 && produto.calcularComplenetos > 1) {
          section.data[i].selected = 0;
          section.data[i].quant = 0;
          section.data[i].Quant = 0;
        } else {
          //section.data[i].selected = 0;
        }
        section.selected += section.data[i].selected;
      }
      else {
        section.data[i] = item;
      }

      //Verifica se é o de maior valor
      if (section.chave === 99999) {
        optionMax = section.data[i].selected + optionMax; //Conta os selecionados
        if (produto?.calcularComplenetos < 2) {
          if (section.data[i].selected > 0) {
            count = count + 1;
            if (maiorVal < section.data[i].valor) {
              maiorVal = section.data[i].valor;
            }
          }
        }
      }

    }

    if (optionMax > section.maximo) {
      if (item.selected > 0) {
        item.selected = 0;
        item.quant = 0;
        item.Quant = 0
        count = count - 1;
      }
      section.selected = item.selected;
    }

    if (produto?.calcularComplenetos < 2) {
      if (section.chave === 99999) {
        for (var i = 0; i < section.data.length; i++) {
          if (section.data[i].selected > 0) {
            section.data[i].quant = 1 / count;
            section.data[i].Quant = 1 / count;
            if (produto.calcularComplenetos === 1) {
              section.data[i].valornew = maiorVal;
            }
          }
        }
      }
    }
    for (var i = 0; i < data.length; i++) {
      if (section.chave === data[i].chave) {
        data[i] = section;
        break;
      }
    }
    setData(data);
    validarProduto();
    calcularTotal();
  }




  function clickAdd(item, section, index, valor) {

    var selecionada = 0;
    try {
      if (valor === 1) {
        for (var i = 0; i < section.data.length; i++) {
          selecionada += section.data[i].selected;
        }

        if (selecionada < section.maximo) {
          item.selected += 1;
          section.selected += 1;
        }

      } else {
        for (var i = 0; i < section.data.length; i++) {
          selecionada += section.data[i].selected;
        }

        if (item.selected >= 1) {
          item.selected -= 1;
          section.selected -= 1;
        }
      }

      item.quant = item.selected;
      item.Quant = item.selected;
      section.data[index] = item;
      for (var i = 0; i < data.length; i++) {
        if (section.chave === data[i].chave) {
          data[i] = section;
          break;
        }
      }

      setForceUpdate(!forceUpdate)
      setData(data);
      validarProduto();
      calcularTotal();


    } catch (erro) {
      notification.error({
        description: erro.message,
        duration: 3,
        message: 'Atenção!'
      })
    }
  }

  function changeQuant(valor) {
    produto.Quant = produto.Quant + valor;
    produto.quant = produto.Quant;
    if (produto.Quant < 1) {
      produto.Quant = 1;
      produto.quant = 1;
    }
    setQuant(produto.Quant);
    setProduto(produto);
    calcularTotal();
  }



  // Adiciona o produto ao carrinho
  async function addProduto() {
    try {
      produto.desconto = 0;
      var complementos = [];
      data.map(grupo => {
        if (grupo.chave !== 88888 && grupo.chave > 0) {
          //----------- TAMANHOS ---------------
          if (grupo.chave === 99998 || grupo.chave === 99999) {
            var temp = 0;
            if (produto.calcularComplenetos === 1) {
              grupo.data.map((item) => {
                if (item.selected > 0) {
                  if (grupo.chave === 99999)
                    item.valor = item.valornew;
                  else {
                    if (item.valor > temp)
                      temp = item.valor;
                  }
                }
                return item
              });
              if (grupo.chave === 99998) {
                grupo.data.map((item) => {
                  item.valor = temp;
                  return item
                });
              }
            }
          }
          //---------------------------
          grupo.data.map((item) => {
            if (item.selected > 0) {
              complementos.push({
                ProdutoID: item.produtoid,
                NomeReduzido: item.nome,
                pesoTotal: 1,
                // Quant: item.quant, //removido em 04/02/2021 
                Quant: item.quant * produto.Quant, //add 04/02/2021
                quant: item.quant * produto.Quant, //add 04/02/2021
                Venda: item.valor,
                desconto: 0,
                isCombo: grupo.chave < 99998 ? true : false
              });
            }
            return item
          }
          )
        }
        return grupo
      })

      produto.complementos = complementos;
      produto.valorUnitarioFinal = (produto.venda + totalGrupos);
      produto.Obs = observacoes;

      var cdp = JSON.parse(JSON.stringify(cardapio))
      cdp.itens.push(produto)
      setCardapio(cdp)
      setOpenProd(false)

    } catch (erro) {
      console.log('error', erro)
    }

  }


  function addItemPedido(item, mesaid) {
    var cdp = { ...cardapio }
    cdp.itens.push(item)
    setCardapio(cdp)

  }



  function calcularTotal() {
    try {
      var totalGrupos = 0;
      var totalComplementos = 0;
      var grupos = data;
      for (var i = 0; i < (grupos.length) - 1; i++) {
        if (grupos[i].chave > 0) {
          if (grupos[i].chave < 88888) {
            grupos[i].data.map((item) => {
              if (item.quant === undefined) {
                item.quant = 0;
                item.Quant = 0
              }
              totalGrupos = totalGrupos + (item.valor * item.quant);
              return item
            });

          } else if (grupos[i].chave === 99999) {
            if (produto.calcularComplenetos === 2) { // adiciona o valor de cada complemento
              grupos[i].data.map((item) => {
                if (item.quant === undefined) {
                  item.quant = 0;
                  item.Quant = 0
                }
                totalComplementos = totalComplementos + (item.valor * item.quant);
                return item
              });
            } else if (produto.calcularComplenetos === 1) //apenas o de maior valor
            {
              var temp = 0;
              grupos[i].data.map((item) => {
                if (item.selected === undefined)
                  item.selected = 0;
                if (item.selected > 0) {
                  if (item.valor > temp)
                    temp = item.valor;
                }
                return item
              });
              totalComplementos = temp;

            } else { // media dos complementos
              var media = 0;
              var total = 0;
              var itensSelecionado = 0;
              grupos[i].data.map((item) => {
                if (item.selected > 0) {
                  itensSelecionado += item.selected;
                  total += item.valor;
                  media = total / itensSelecionado;
                }
                return item
              });
              totalComplementos = media;
            }

          } else if (grupos[i].chave === 99998) {
            if (produto.calcularComplenetos === 0 || produto.calcularComplenetos === 2) { // adiciona o valor de cada complemento
              grupos[i].data.map((item) => {
                totalComplementos = totalComplementos + (item.valor * item.selected);
                return item
              });
            } else if (produto.calcularComplenetos === 1) //adiciona o de maior valor
            {
              var temp = 0;
              var totalsavoresSel = 0;
              grupos[i].data.map((item) => {
                totalsavoresSel = totalsavoresSel + item.selected;
                if (item.selected > 0) {
                  if (item.valor > temp)
                    temp = item.valor;
                }
                return item
              });
              totalComplementos = (temp * totalsavoresSel);
            }
          }
          else {
            if (produto.calcularComplenetos === 0 || produto.calcularComplenetos === 2) { // adiciona o valor de cada complemento
              // eslint-disable-next-line no-loop-func
              grupos[i].data.map((item) => {
                totalComplementos = totalComplementos + (item.valor * item.selected);
                return item
              });
            } else if (produto.calcularComplenetos === 1) //adiciona o de maior valor
            {
              var temp2 = 0;
              var totalsavoresSel = 0;
              // eslint-disable-next-line no-loop-func
              grupos[i].data.map((item) => {
                totalsavoresSel = totalsavoresSel + item.selected;
                if (item.selected > 0) {
                  if (item.valor > temp2)
                    temp2 = item.valor;
                }
                return item
              });

              totalComplementos = (temp2 * totalsavoresSel);

            } else {

              // eslint-disable-next-line no-loop-func
              grupos[i].data.map((item) => {
                totalComplementos = totalComplementos + (item.valor * item.selected);
                return item
              });
            }
          }
        }
      }
      totalGrupos += totalComplementos;
      setTotalGrupos(totalGrupos)
      setProduto(produto);
      setForceUpdate(!forceUpdate)
      //  console.log(produto)
    } catch (error) {
      console.log(error)
    }
  }

  //HEARDER  
  function renderHeader(sec) {
    // console.log("SECTION", section);
    var section = JSON.parse(JSON.stringify(sec))
    if (section.isobs)
      section.case = 1;
    else if (section.obrigatorio === 1 && section.selected !== section.maximo)
      section.case = 2;
    else if (section.data.length === 0)
      section.case = 0;
    switch (section.case) {
      case 0:
        return <div></div>
      case 1:
        return <Flex vertical>
          <Flex gap={'small'} style={{ backgroundColor: 'whitesmoke' }}>
            <MessageOutlined style={{ marginLeft: 5 }} />
            <span>{section.descricao === '' ? 'Selecione' : section.descricao}</span>
          </Flex>
          <Flex style={{ marginTop: 2 }}>
            <Input.TextArea
              placeholder="Digite aqui"
              value={observacoes}
              onChange={(e) => setObservacoes(e.target.value)}
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}>
            </Input.TextArea>
          </Flex>

        </Flex>

      case 2:
        return <Flex style={{ backgroundColor: 'whitesmoke', justifyContent: 'space-between', alignItems: 'center' }}>
          <Flex vertical style={{ fontSize: '0.8em' }}>
            <span style={{ marginLeft: 5 }}> {section.descricao === '' ? 'Selecione' : section.descricao} </span>
            <span style={{ marginLeft: 5, marginRight: 5, fontSize: 10 }}>
              {
                section.selected.toString().concat(" de ", section.maximo)
              }
            </span>
          </Flex>

          <BadgeSuite
            style={{ alignItems: 'center', justifyContent: 'center' }}
            color={(section.obrigatorio === 1 && section.selected < section.minimo) ? "red" : 'green'}
            content={(section.obrigatorio === 1 && section.selected < section.minimo) ?
              <div style={{ fontSize: '0.8em', justifyContent: 'center' }}>
                Obrigatório
              </div> :
              <div style={{
                fontSize: '0.8em', justifyContent: 'center'
              }}>Opcional</div>}
          ></BadgeSuite>

        </Flex>

      default:
        return <Flex vertical style={{ backgroundColor: 'whitesmoke' }}>
          <Flex align='center' style={{ fontSize: '0.8em', marginLeft: 5 }}>
            <span>{section.descricao === '' ? 'Selecione' : section.descricao}</span>
          </Flex>
          <Flex style={{ fontSize: 10, marginLeft: 5, marginRight: 5 }}>
            {section.selected.toString().concat(" de ", section.maximo)}
          </Flex>
        </Flex>
    }
  }


  //RENDER ITEM
  function renderItem(item, section, index) {

    return (<Flex justify="space-around" align="center">
      <Flex style={{ flex: 1 }}>
        <Flex vertical style={{ fontSize: '0.9em' }}>
          <div style={{ fontSize: '0.9em' }}>
            {item.nome}
          </div>
          <div>
            {item.valor !== 0 && <span style={{ fontSize: '0.85em', color: 'blue' }}>
              {item?.valor && '+ R$'.concat(item?.valor?.toFixed(2).toString())}
            </span>
            }
          </div>
        </Flex>
      </Flex>
      <Flex>
        {
          (section.maximo === 1 || (produto?.calcularComplenetos !== 2 && section.chave === 99999)) ?
            <div style={{ flexDirection: 'row', alignItems: 'flex-end', marginRight: 0 }}>
              <Checkbox checked={item.selected === 1 ? true : false}
                onChange={() => checkBoxPress(item, section, index)} />
            </div> :
            <Flex align="center" style={{
              display: item?.nome ? 'flex' : 'none'
            }}>
              <Flex>
                <MinusOutlined onClick={() => clickAdd(item, section, index, -1)}
                  style={{
                    color: item.selected === 0 ? 'transparent' : 'red',
                    height: 32
                  }} />
              </Flex>
              <Flex>
                <div style={{ minWidth: 20, textAlign: 'center' }}>
                  {item.selected === 0 ? '' : item.selected}
                </div>
              </Flex>
              <Flex item>
                <PlusOutlined style={{ color: item?.nome ? 'green' : 'transparent', height: 32 }}
                  onClick={() => clickAdd(item, section, index, 1)} />
              </Flex>
            </Flex>
        }
      </Flex>
    </Flex>
    )
  }





}


