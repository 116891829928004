import React from 'react'
import { Button, Space, Row, Col, Avatar, Collapse } from 'antd'
import { Footer } from '../../component/footer'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

export const Parceiros = (props) => {

    const navigate = useNavigate()


    return <div style={{ backgroundColor: 'white' }}>
        <div style={{
            height: 70, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 30,
            marginRight: 30, justifyContent: 'space-between'
        }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                onClick={() => navigate('/')}>
                <img height={60} src={require('../../img/logomenu.png')} style={{cursor:'pointer'}}/>
                <span style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'royalblue', cursor:'pointer' }}>Menuengfood</span>
            </div>
            <Button size='large' type='default'
                onClick={() => {
                    window.open("https://portal.menuengfood.com.br")
                }}>Entrar no Portal</Button>
        </div>
        <div style={{
            borderTopColor: 'whitesmoke',
            borderTopWidth: 0.5,
            borderTopStyle: 'double',
            backgroundColor: '#ADD8E6'
        }}>
            <div style={{
                fontSize: '1.8em', letterSpacing: 0.266, textAlign: 'center',
                padding: 30, fontWeight: 600
            }}>
                <div>
                    Cadastre sua loja e comece a vender no Menuengfood
                </div>
                <div style={{ fontSize: 15, color: 'gray' }}>
                    100% grátis, pedidos ilimitados, sem anuidade, sem taxas ou comissões por pedidos
                </div>




                <Row justify="center" gutter={[24, 24]} style={{ justifyContent: 'center', marginTop: 10 }}>
                    <Col xs={12} md={5} xl={5} >
                        <Space size={'small'} direction='vertical' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Avatar size={70} src={require('../../img/icones/icon-acai.png')} />
                            Açaiterias
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-hamburguer.png')} />
                            Hamburguerias
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-padaria.png')} />
                            Padarias
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-pastel.png')} />
                            Pastelarias
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-pizza.png')} />
                            Pizzarias
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-salgados.png')} />
                            Lanchonetes
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-sorvete.png')} />
                            Sorveterias
                        </Space>
                    </Col>
                    <Col xs={12} md={5} xl={5}>
                        <Space size={'small'} direction='vertical'>
                            <Avatar size={70} src={require('../../img/icones/icon-sushi.png')} />
                            Culinária japonesa
                        </Space>
                    </Col>

                </Row>

            </div>

        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{
                fontSize: 18, letterSpacing: 0.266, textAlign: 'center',
                padding: 30, fontWeight: 600
            }}>
                Para todos os tipos de negócio, inclusive o seu
            </div>

            <div>
                <Button size='large' type='primary' style={{ marginTop: 5 }}
                    onClick={() => {
                        window.open("https://portal.menuengfood.com.br/register")
                    }}>Cadastre-se agora</Button>
            </div>


            <div style={{ marginTop: 60, fontSize: '1.5em', fontWeight: 500 }}>
                Perguntas frequentes
            </div>
            <div style={{ marginTop: 20 }}>
                Tire suas principais dúvidas
            </div>


            <Collapse style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: isMobile ? '90%' : '70%', marginTop: 30 }}>
                <Collapse.Panel header="O que é o Menuengfood?" key="1" style={{ width: '100%' }}>
                    <p>
                        O Menuengfood é uma plataforma que faz parcerias com estabelecimentos principalmente do ramo alimentício e
                        disponibiliza seu cardápio online, sem custos, oferecendo visibilidade a novos clientes.<br />

                        Procurando dar oportunidade de crescimento principalmente a quem está começando no ramo do empreendedorismo
                        e não consegue arcar com as altas taxas e comissões que as plataformas de delivery do mercado cobram.<br />

                        O Menuengfood é uma extensão do seu negócio físico no mundo online.
                        Ou seja, com a mesma estrutura que você já tem na sua empresa, pode atender ainda mais clientes e vender
                        muito mais!
                    </p>
                </Collapse.Panel>
            </Collapse>

            <Collapse style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: isMobile ? '90%' : '70%', marginTop: 10 }}>
                <Collapse.Panel header="O Menuengfood é grátis?" key="2" style={{ width: '100%' }}>
                    <p>
                        Sim, você pode utilizar ele gratuitamente sem limite de pedidos recebidos, apenas algumas funcionalidades que
                        só estão disponíveis com a ativação dos pagamentos online, direto no APP ou site.<br />
                    </p>
                </Collapse.Panel>
            </Collapse>

            <Collapse style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: isMobile ? '90%' : '70%', marginTop: 10 }}>
                <Collapse.Panel header="Como o Menuengfood ganha algo se ele é grátis?" key="3" style={{ width: '100%' }}>
                    <p>
                        O Menuengfood é grátis para qualquer estabelecimento que assim queira utilizar.<br />
                        Cobramos apenas uma comissão(3%) sobre os pagamentos feitos online caso o cliente ative essa opção.
                    </p>
                </Collapse.Panel>
            </Collapse>

            <Collapse style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: isMobile ? '90%' : '70%', marginTop: 10 }}>
                <Collapse.Panel header="Como acessar o Portal do Parceiro?" key="4" style={{ width: '100%' }}>
                    <p>
                        <a href='https://portal.menuengfood.com.br' target='_blank'>Clique aqui </a>
                        e entre com o e-mail e senha que você cadastrou. <br />

                        Se você tiver esquecido a sua senha, é só clicar em "Esqueci minha senha" e enviaremos um link por
                        e-mail para que você cadastre uma nova.
                    </p>
                </Collapse.Panel>
            </Collapse>

        </div>




        <Footer />
    </div>

}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default Parceiros