import React, { useEffect, useState } from 'react'
import { Button, Flex, Table, Affix, Space, Typography, Grid, Col, Row, App, Badge, Card, Image } from 'antd'
import { socket } from '../../../service/socket'
import { titleize } from '../../../service/util'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'
import { waiterUserState, pedidoWaiterState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import '../waiterOutlet.css'
import { useNavigate, useLocation } from 'react-router-dom'
import './produtosWaiter.css'
import devoltaPNG from '../../../../src/img/de-volta.png'
import { isMobile } from 'react-device-detect'
import { FlexboxGrid } from 'rsuite'

const { Meta } = Card

export const WaiterProdutos = (props) => {

    const location = useLocation()
    const { notification } = App.useApp()
    const navigate = useNavigate()
    const [produtos, setProdutos] = useState([])
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [pedido, setPedido] = useRecoilState(pedidoWaiterState)
    const categoria = location.state.categoria;
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        //  console.log(waiterUser)
        getProdutos()
    }, [])



    async function getProdutos() {
        try {
            setLoading(true)
            socket.emit('getProdutos', waiterUser?.database, categoria, (value) => {
                setLoading(false)
                setProdutos(value)
            });
        } catch (error) {
            setLoading(false)
            notification.error({
                duration: 3,
                description: error.message
            })
        }
    }

    function revisar() {
        if (pedido?.itens.length > 0) {
            navigate('/manager/pedido')
        } else {
            notification.warning({
                description: 'Não existem itens adicionados ao pedido para revisão',
                duration: 2
            })
        }
    }

    function renderPreco(item) {

        if (item.tamanhoid > 0)
            return <div></div>

        var isPromocao = item.AtivarPromocao === 1 ? true : false; //Se é promoção
        if (item.from) {
            return <span>{item.from}</span>
        } else {
            if (item.venda && item.venda > 0) {
                return <span>
                    {isPromocao && <span>
                        {"Por R$".concat(item.Promo.toFixed(2))} </span>}
                    <span style={Object.assign({
                        fontSize: isPromocao ? 11 : 13, color: isPromocao ? 'gray' : 'darkgray',
                    }, isPromocao ? { textDecorationLine: "line-through" } : {})}>

                        {
                            item.venda && (item.venda > 0 && ''.concat(isPromocao ? 'De ' : '', 'R$', item.venda.toFixed(2).toString()))
                        }
                    </span>
                </span>

            } else {
                var from = item.venda;
                if (item.grupos) {
                    item.grupos.map(grupo => {
                        if (grupo.obrigatorio === 1) {
                            grupo.itens.map(itv => {
                                itv.valor = parseFloat((itv.valor / grupo.minimo).toFixed(2));
                                if (grupo.minimo > 1)
                                    itv.nome = '1/'.concat(grupo.minimo, ' ', itv.nome);
                                return itv
                            })
                            from = from + (grupo.itens.reduce((a, b) => Math.min(a, b.valor), 10000000) * grupo.minimo);
                        }
                        return grupo
                    })
                    item.from = 'a partir de R$'.concat(from.toFixed(2))
                }
                return <span style={{ color: 'white' }}>{item.from}</span>
            }
        }
    }


    async function produtoClicado(item) {
        try {
           
            if (item.Tipo === 0) {
                socket.emit('getProduto', waiterUser?.database, item, (value) => {
                    //console.log(value)

                    if (value.status === 200) {
                        if (item.Tipo > 0) {
                            value.data.nomeTipo = item.nomeTipo;
                            value.data.descricao = value.data.nomeTipo.concat(' ', item.nome);
                        }

                        navigate('/manager/produto', {
                            state: {
                                produto: value.data
                            }
                        })
                    } else {
                        notification.error({
                            duration: 3,
                            description: 'Erro ao carregar dados do produto!'
                        })
                    }
                });


            } else {
                navigate('/manager/produto', {
                    state: {
                        produto: item
                    }
                })
            }
        } catch (error) {
            console.error(error.message)
            notification.error({
                duration: 3,
                description: error.message
            })
        }



    }



    return <>
        <main style={{flex:1, overflowY:'auto', overflowX:'hidden'}}>
            <div >
                {loading === true ? <Flex style={{ minHeight: 300 }} align='center'>
                    <LoadingOutlined /> loading...
                </Flex> : <FlexboxGrid >
                    {produtos?.map((value, index) => {
                        return <FlexboxGrid.Item
                            style={{ padding: 2 }} colspan={isMobile ? 12 : 4}
                            key={'prod_'.concat(value.produtoID, '-', value.tamanhoid)}>
                            <Card

                                bordered
                                onClick={() => produtoClicado(value)}
                                hoverable
                                cover={<div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', paddingTop: 5 }}>
                                    <Image alt="example"
                                        height={55}
                                        width={57}
                                        preview={false}
                                        style={{ objectFit: 'contain' }}
                                        src={
                                            value?.tamanhoid === 0 ?
                                                'https://menuengfood.com.br/images/mobile/'.concat(waiterUser?.id, '/produtos/p', value.produtoID, '.png') :
                                                'https://menuengfood.com.br/images/mobile/'.concat(waiterUser?.id, '/tamanhos/t', value.tamanhoid, '.png')
                                        } />
                                </div>}
                            >
                                <Meta style={{ fontSize: '0.8em', padding: -20 }}
                                    title={<div style={{ fontSize: '0.8em', margin: 0 }}>  {titleize(value.nome)} </div>}
                                    description={value.tamanhoid === 0 ? renderPreco(value) : ""} />
                            </Card>
                        </FlexboxGrid.Item>
                    })
                    }
                </FlexboxGrid>
                }

            </div>
        </main>
        <footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12}>
                    <Button
                        icon={<Image
                            style={{ objectFit: 'contain' }}
                            preview={false}
                            height={18}
                            alt='volta'
                            src={devoltaPNG} />}
                        block
                        onClick={() => navigate('/manager/categorias')}>
                        VOLTAR
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <Button
                        icon={<CheckOutlined />}
                        type='primary'
                        block
                        onClick={() => revisar()}
                        disabled={pedido?.itens.length > 0 ? false : true}>
                        <Badge count={pedido?.itens?.length} >
                            <span style={{ color: pedido?.itens.length > 0 ? 'white' : 'darkgray' }}> REVISAR </span>
                        </Badge>
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </footer>
    </>
}
