import React, { useEffect, useState } from 'react'
import {
    Table, Message, Divider, FlexboxGrid,
    SelectPicker, Input as Inputrs, Badge
} from 'rsuite';
import { Space, Flex } from 'antd';
import { isMobile } from 'react-device-detect';
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'


const { Column, HeaderCell, Cell } = Table

export const EstoqueCritico = (props) => {


    const waiterUser = useRecoilValue(waiterUserState)
    const [categorias, setCategorias] = useState([])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [txt, setTxt] = useState('')
    const [catchave, setCatchave] = useState(null)
    const [isInsumo, setIsInsumo] = useState(-1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadCategorias()
    }, [isInsumo])

    async function loadCategorias() {
        try {
            setStatus(0)
            setData([])

            socket.emit('categorias', waiterUser?.database,
                {
                    isInsumo: isInsumo
                },
                (value) => {
                    const aux = [{ menuDescricao: 'Todas', value: 0 }].concat(value.data)
                    if (value.status === 200) {
                        setCategorias(aux.map(
                            item => ({ label: item.menuDescricao, value: item.menuChave })
                        ))
                        pesquisar(catchave, isInsumo)
                    } else {
                        setCategorias(aux)
                      //  setLoading(false)
                    }

                });

        } catch (error) {
            setStatus(-1)
        }
    }


    async function pesquisar(cat, insumo) {
        try {
            setLoading(true)
            setStatus(0)
            socket.emit('estoqueCritico', waiterUser?.database,
                {
                    tipos: 0,
                    produtoNome: txt,
                    isInsumo: insumo,
                    categoria: cat ? cat : 0
                },
                (value) => {
                    if (value.status === 200) {
                        setData(value.data)
                    } else {

                    }
                    setStatus(1)
                    setLoading(false)
                })
        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }


    return ( <main>
        <div style={{ marginTop: 10 }}>
            <Flex vertical gap={5}>
                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}
                <Space direction={isMobile ? 'vertical' : 'horizontal'}>

                    <SelectPicker
                        size='sm'
                        label="Tipo"
                        data={[{ label: 'Todos', value: -1 }, { label: 'Normal', value: 0 }, { label: 'Insumos', value: 1 }]}
                        style={{ width: 280 }}
                        onChange={e => {
                            setIsInsumo(e)
                            // pesquisar(catchave, e)
                        }} />


                    <SelectPicker
                        size='sm'
                        label="Categoria"
                        data={categorias}
                        style={{ width: 280 }}
                        onChange={e => {
                            setCatchave(e)
                            pesquisar(e, isInsumo)
                        }} />



                </Space>


                <div>
                    <Table
                        style={{ fontSize: '0.8em' }}
                        autoHeight={true}
                        hover
                        loading={loading}                        
                        showHeader={!isMobile}
                        rowHeight={isMobile ? 43 : 30}
                        headerHeight={30}
                        data={data}
                        onRowClick={rowData => {
                        }}>
                        {isMobile ? <>
                            <Column flexGrow={1} fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CATEGORIA</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={24}>
                                                <Space style={{ minWidth: 100 }}>{rowData.menuDescricao}</Space>
                                                <Divider vertical />
                                                <span>{rowData.produtoNome}</span>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={24}>
                                                <Space direction='horizontal'>
                                                    <span>{rowData.isInsumo === 0 ?
                                                        <Badge content="Normal" style={{ width: 50, textAlign: 'center' }} color='blue' /> : <Badge content="Insumo"
                                                            style={{ width: 50, textAlign: 'center' }}
                                                            color='orange' />}</span>

                                                    <span>Minimo: {rowData.produtoEstoqueMin}</span>
                                                    <span>Estoque atual: {rowData.produtoEstoque}</span>
                                                </Space>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    }}
                                </Cell>
                            </Column>
                        </> : <>

                        <Column flexGrow={2} fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>PRODUTO</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoNome}</span>
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={1} fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CATEGORIA</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.menuDescricao}</span>
                                    }}
                                </Cell>
                            </Column>
                            <Column width={130} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>TIPO</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.isInsumo === 0 ?
                                            <Badge content="Normal" style={{ width: 50, textAlign: 'center' }} color='blue' /> : <Badge content="Insumo"
                                                style={{ width: 50, textAlign: 'center' }}
                                                color='orange' />}</span>
                                    }}
                                </Cell>
                            </Column>
                           
                            <Column width={130} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>STATUS</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoAtivo === 1 ?
                                            <Badge content="Ativo" style={{ width: 50, textAlign: 'center' }} color='green' /> : <Badge content="Inativo"
                                                style={{ width: 50, textAlign: 'center' }}
                                                color='red' />}</span>
                                    }}
                                </Cell>
                            </Column>
                            <Column width={130} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>EST. MINIMO</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoEstoqueMin}</span>
                                    }}
                                </Cell>
                            </Column>

                            <Column width={130} align="center" fixed >
                                <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>ESTOQUE ATUAL</HeaderCell>
                                <Cell dataKey="data" style={{ padding: 3 }}>
                                    {rowData => {
                                        return <span style={{ fontSize: '0.8em' }}>{rowData.produtoEstoque}</span>
                                    }}
                                </Cell>
                            </Column>
                        </>}
                    </Table>
                </div>
            </Flex>
        </div>
        </main>
    )
}

