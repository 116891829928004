import React, { useEffect, useState } from 'react'
import { Avatar, Col, Row, Tag, Input, Divider, Checkbox, Button, Space, App } from 'antd'
import { CheckOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import './produto.css'
import { pedidoState } from '../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { useToasts } from 'react-toast-notifications'

const { TextArea } = Input

export const Produto = (props) => {

  const [pedido, setPedido] = useRecoilState(pedidoState)
  const [produto, setProduto] = useState(JSON.parse(JSON.stringify(props.produto)))
  const [data, setData] = useState([]);
  const [disableok, setDisableok] = useState(true);
  const [observacoes, setObservacoes] = useState('');
  const [totalGrupos, setTotalGrupos] = useState(0);
  const toash = useToasts()
  const { notification } = App.useApp()

  useEffect(() => {
    validarProduto()
    return () => { }
  }, [data, produto])

  useEffect(() => {
    if (!produto.Venda)
      produto.Venda = 0;

    if (produto.ispattern === 0)
      produto.Venda = parseFloat(produto.Venda)

    if (!produto.Quant)
      produto.Quant = 1;
    if (produto.AtivarPromocao) {
      if (produto.AtivarPromocao === 1 && produto.ValorPromocao > 0) {
        if (!produto.Venda2)
          produto.Venda2 = produto.Venda
        produto.Venda = produto.ValorPromocao;
      }
    } else {
      //  console.log('aqui')
      produto.Venda2 = produto.Venda
    }

    var chave = 77777;
    if (produto.grupos) {
      produto.grupos.map(grupo => {
        grupo.data = grupo.itens;
        if (grupo.chave === 0) {
          grupo.chave = chave;
          grupo.data.map(subitem => {
            subitem.idgrupo = chave;
          })
          chave = chave + 1;
        }
      })
    } else {
      produto.grupos = []
    }


    produto.grupos.push({
      chave: 88888, descricao: 'Alguma observação?', maximo: 150, minimo: 0,
      obrigatorio: 0, isobs: true, itens: [], data: [{ selected: 0 }], produtoid: 0, selected: 0
    })


    setProduto(produto);
    setData(produto.grupos);

    validarProduto();

    return () => { /*props.shoModaProduto(false);*/ }
  }, []);


  // Adiciona o produto ao carrinho
  async function addProduto() {
    try {
      produto.desconto = 0;
      var complementos = [];
      var data9 = JSON.parse(JSON.stringify(data))
      data9.map(grupo => {
        if (grupo.chave !== 88888 && grupo.chave > 0) {
          //----------- TAMANHOS ---------------
          if (grupo.chave === 99998 || grupo.chave === 99999) {
            var temp = 0;
            if (produto.CalcularComplenetos === 1) {
              grupo.data.map((item) => {
                if (item.selected > 0) {
                  if (grupo.chave === 99999)
                    item.valor = item.valornew;
                  else {
                    if (item.valor > temp)
                      temp = item.valor;
                  }
                }
              });
              if (grupo.chave === 99998) {
                grupo.data.map((item) => {
                  item.valor = temp;
                });
              }
            }
          }

          grupo.data.map(item => {
            if (item.selected > 0) {
              complementos.push({
                ProdutoID: item.produtoid,
                NomeReduzido: item.nome,
                pesoTotal: 1,
                Quant: item.quant,
                Venda: item.valor,
                desconto: 0,
                isCombo: grupo.chave < 99998 ? true : false
              });
            }
          })
        }
      })

      produto.complementos = complementos;
      produto.valorUnitarioFinal = (produto.Venda + totalGrupos);
      produto.Obs = observacoes;

      var value = new Date().getTime();
      var carrinho = JSON.parse(JSON.stringify(pedido));
      if (!carrinho) {
        carrinho = {
          createdAt: value,
          local: produto.path,
          itens: [],
          type: 'DELIVERY'
        };
      }

      if (!carrinho.itens)
        carrinho.itens = []

      carrinho.createAt = new Date().getTime()
      carrinho.itens.push(produto);
      carrinho.path = produto.path;
      carrinho.createdAt = value
      setPedido(carrinho)

      props.shoModaProduto(false)

      toash.addToast('Item adicionado a sacola.', { appearance: 'success', autoDismiss: true })
    } catch (erro) {
      toash.addToast(erro.message, { appearance: 'error', autoDismiss: true })
      notification.error({
        description: erro.message,
        message:"Atenção!"
      })
    }
  }




  function checkBoxPress(item, section, index) {
    item.valor = parseFloat(item.valor)
    var optionMax = 0;
    var count = 0;
    var maiorVal = 0; //Utilizado caso this.state.produto.calcularComplenetos == 1
    item.selected = item.selected === 0 ? 1 : 0;
    item.quant = item.selected;
    section.selected = item.selected;

    for (var i = 0; i < section.data.length; i++) {
      if (i !== index) {
        if (section.chave !== 99999 && (produto.CalcularComplenetos > 1 || !produto.CalcularComplenetos)) {
          section.data[i].selected = 0;
          section.data[i].quant = 0;
        } else {
          //section.data[i].selected = 0;
        }
        section.selected += section.data[i].selected;
      }
      else {
        section.data[i] = item;
      }

      //Verifica se é o de maior valor
      if (section.chave === 99999) {
        optionMax = section.data[i].selected + optionMax; //Conta os selecionados
        if (produto.CalcularComplenetos < 2) {
          if (section.data[i].selected > 0) {
            count = count + 1;
            if (maiorVal < section.data[i].valor) {
              maiorVal = section.data[i].valor;
            }
          }
        }
      }
    }

    if (optionMax > section.maximo) {
      if (item.selected > 0) {
        item.selected = 0;
        item.quant = 0;
        count = count - 1;
      }
      section.selected = item.selected;
    }

    if (produto.CalcularComplenetos < 2) {
      if (section.chave === 99999) {
        for (var m = 0; m < section.data.length; m++) {
          if (section.data[m].selected > 0) {
            section.data[m].quant = 1 / count;
            if (produto.CalcularComplenetos === 1) {
              section.data[m].valornew = maiorVal;
            }
          }
        }
      }
    }
    for (var i = 0; i < data.length; i++) {
      if (section.chave === data[i].chave) {
        data[i] = section;
        break;
      }
    }
    setData(Object.values(data));
    validarProduto();
    calcularTotal();
  }


  function clickAdd(item, section, index, valor) {
    var selecionada = 0;
    try {
      if (valor == 1) {
        for (var i = 0; i < section.data.length; i++) {
          selecionada += section.data[i].selected;
        }

        if (selecionada < section.maximo) {
          item.selected += 1;
          section.selected += 1;
        }

      } else {
        for (var i = 0; i < section.data.length; i++) {
          selecionada += section.data[i].selected;
        }

        if (item.selected >= 1) {
          item.selected -= 1;
          section.selected -= 1;
        }
      }

      item.quant = item.selected;
      section.data[index] = item;
      for (var i = 0; i < data.length; i++) {
        if (section.chave === data[i].chave) {
          data[i] = section;
          break;
        }
      }

      // setForceUpdate(!forceUpdate)
      setData(Object.values(data));
      validarProduto();
      calcularTotal();


    } catch (erro) {
      console.log(erro)
    }
  }


  function changeQuant(valor) {

    produto.Quant = produto.Quant + valor;
    if (produto.Quant < 1)
      produto.Quant = 1;
    //  setQuant(produto.Quant);
    setProduto(Object.assign({}, produto));
    calcularTotal();
  }



  function calcularTotal() {
    try {
      //  console.log("Calcular", 'calculando...')
      var totalGrupos = 0;
      var totalComplementos = 0;
      var grupos = data;
      for (var i = 0; i < (grupos.length) - 1; i++) {
        if (grupos[i].chave > 0) {
          if (grupos[i].chave < 88888) {
            grupos[i].data.map((item) => {
              if (item.quant === undefined)
                item.quant = 0;
              totalGrupos = totalGrupos + (item.valor * item.quant);
            });

          } else if (grupos[i].chave === 99999) {
            if (produto.CalcularComplenetos === 2) { // adiciona o valor de cada complemento
              grupos[i].data.map((item) => {
                if (item.quant === undefined)
                  item.quant = 0;
                totalComplementos = totalComplementos + (item.valor * item.quant);
              });
            } else if (produto.CalcularComplenetos === 1) //apenas o de maior valor
            {
              var temp = 0;
              grupos[i].data.map((item) => {
                if (item.selected === undefined)
                  item.selected = 0;
                if (item.selected > 0) {
                  if (item.valor > temp)
                    temp = item.valor;
                }
              });
              totalComplementos = temp;

            } else { // media dos complementos
              var media = 0;
              var total = 0;
              var itensSelecionado = 0;
              grupos[i].data.map((item) => {
                if (item.selected > 0) {
                  itensSelecionado += item.selected;
                  total += item.valor;
                  media = total / itensSelecionado;
                }
              });
              totalComplementos = media;
            }

          } else if (grupos[i].chave === 99998) {
            //  console.log("99998", 'entrou aqui', produto)
            if (produto.CalcularComplenetos === 0 || produto.CalcularComplenetos === 2 || !produto.CalcularComplenetos) { // adiciona o valor de cada complemento
              grupos[i].data.map((item) => {
                totalComplementos = totalComplementos + (item.valor * item.selected);
              });
              //   console.log("totalComplementos", totalComplementos)
            } else if (produto.CalcularComplenetos === 1) //adiciona o de maior valor
            {
              //  console.log("produto.CalcularComplenetos === 1","Linha 340")
              var temp = 0;
              var totalsavoresSel = 0;
              grupos[i].data.map((item) => {
                totalsavoresSel = totalsavoresSel + item.selected;
                if (item.selected > 0) {
                  if (item.valor > temp)
                    temp = item.valor;
                }
              });
              totalComplementos = (temp * totalsavoresSel);
            }
          }
          else {
            if (produto.CalcularComplenetos === 0 || produto.CalcularComplenetos === 2) { // adiciona o valor de cada complemento
              grupos[i].data.map((item) => {
                totalComplementos = totalComplementos + (item.valor * item.selected);

              });
            } else if (produto.CalcularComplenetos === 1) //adiciona o de maior valor
            {
              var temp2 = 0;
              var totalsavoresSel = 0;
              grupos[i].data.map((item) => {
                totalsavoresSel = totalsavoresSel + item.selected;
                if (item.selected > 0) {
                  if (item.valor > temp2)
                    temp2 = item.valor;
                }
              });

              totalComplementos = (temp2 * totalsavoresSel);

            } else {

              grupos[i].data.map((item) => {
                totalComplementos = totalComplementos + (item.valor * item.selected);
              });
            }
          }
        }
      }
      totalGrupos += totalComplementos;
      setTotalGrupos(totalGrupos)
      setProduto(Object.assign({}, produto));

    } catch (error) {
      //console.log(error)
      toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
    }

  }





  function validarProduto() {
    var obrigatorios = 0;
    var validados = 0;
    for (var i = 0; i < data.length; i++) {
      if (data[i].obrigatorio === 1) {
        obrigatorios++;
        var selected = 0;
        data[i].itens.map((item) => {
          selected += item.selected;
        });

        if (selected >= data[i].minimo)
          validados++;
      }
    }
    var tem = obrigatorios <= validados ? false : true
    setDisableok(tem);
  }


  //console.log(produto)

  return <div style={{
    flexFlow: 1,
    flex: 1,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'

  }} >
    <div>
      <Row style={{ width: '100%' }}>
        {produto?.Info && <Col span={24} style={{
          marginBottom: 15,
          color: 'gray',
          fontSize: '0.9em',
          paddingLeft: produto?.ispattern === 0 ? 45 : 0
        }}>
          {produto?.Info}
        </Col>
        }

        <Col span={24}>
          {
            data.map((section, index) => {
              return <div key={"prod-key-item-".concat(index)}>
                {
                  renderHeader(section, index)
                }
                {
                  section?.data && section?.data.map((item, indexItem) => (
                    renderItem(item, section, indexItem)
                  ))
                }
              </div>

            })
          }
        </Col>
        <Col span={24}><Divider /></Col>
        <Col span={24} className="grupo--item--footer">

        </Col>
      </Row>
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTopColor: '#DCDCDC',
      borderTopWidth: 1,
      borderTopStyle: 'double',
      padding: 5,
      marginLeft: -24,
      marginRight: -24,
      marginBottom: -24,
      alignItems: 'center'
    }}>
      <div>
        <div className="grupo--item--footer">
          <Button size="large" type={'link'} icon={<MinusCircleOutlined />}
            onClick={() => changeQuant(-1)}></Button>
          <Input value={produto?.Quant}
            style={{ width: 40, textAlign: 'center', justifyContent: 'center' }} />
          <Button size="large" type={'link'} icon={<PlusCircleOutlined />}
            onClick={() => changeQuant(1)}></Button>
        </div>
      </div>

      <Button type="primary" disabled={disableok}
        style={{
          marginLeft: 30,
          minWidth: 180,
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={() => { addProduto() }}>
        {'Adicionar    R$'.concat(((produto.Venda + totalGrupos) * produto.Quant).toFixed(2).toString())}
      </Button>

    </div>
  </div >



  //HEARDER  
  function renderHeader(sec, index) {
    var section = JSON.parse(JSON.stringify(sec))
    if (section.isobs)
      section.case = 0;
    else if (section.obrigatorio === 1 && section.selected !== section.maximo)
      section.case = 2;
    else if (section.data.length === 0)
      section.case = 0;

    switch (section.case) {
      case 0:
        return <div key={index}></div>

      case 1:
        return <Row key={index} style={{ backgroundColor: 'whitesmoke' }}>
          <Col span={24} style={{ padding: 5 }}>
            <b>{section.descricao === '' ? 'Selecione' : section.descricao}</b>
          </Col>
        </Row>

      case 2:
        return <Row key={index} style={{ backgroundColor: 'whitesmoke' }}>
          <Col span={24} className="grupo--item" style={{ padding: 5 }}>
            <div>
              <b> {section.descricao === '' ? 'Selecione' : section.descricao} </b>
            </div>
            <div>
              <Tag>
                {section.selected < section.minimo ?
                  section.selected.toString().concat("/", section.maximo) :
                  <CheckOutlined style={{ marginRight: 10, color: 'royalblue' }} />
                }
              </Tag>
              <Tag color="#108ee9">Obrigatório </Tag>
            </div>
          </Col>
        </Row >

      default:
        return <Row key={index} style={{ backgroundColor: 'whitesmoke' }}>
          <Col span={24} className="grupo--item" style={{ padding: 5 }}>
            <div> <b>{section.descricao === '' ? 'Selecione' : section.descricao}</b></div>
            <div>
              <Tag>
                {section.selected.toString().concat("/", section.maximo)}
              </Tag>
              <Tag color="success" style={{ minWidth: 78, textAlign: 'center' }}>{' Opcional '}</Tag>
            </div>
          </Col>
        </Row>
    }
  }


  function renderItem(item, section, index) {

    if (section.isobs === true) {
      return <TextArea style={{ width: '100%', marginTop: 20 }}
        key={index}
        maxLength={100}
        placeholder="Digite aqui as observações"
        id="outlined-multiline-static"
        value={observacoes}
        onChange={(e) => setObservacoes(e.target.value)} />
    }

    return <Row>
      <Col span={24} className="grupo--item">
        <div>
          <Row>
            <Col span={24}>
              <div className="div-sabores-pattern">
                {
                  produto?.ispattern === 0 && section.chave === 99998 ?
                    <div className="div-sabores-pattern">
                      <Avatar size="small" src={item.Url} />
                      <div style={{ marginLeft: 10 }}>{item.nome}</div>
                    </div> :
                    item.nome
                }
              </div>
            </Col>
            <Col span={24} style={{ color: 'gray', fontStyle: 'italic', fontSize: 'small' }}>
              {item.Info}
            </Col>
            <Col span={24}>
              <span style={{ fontSize: 14, color: 'blue' }}>
                {'+ R$'.concat(parseFloat(item.valor).toFixed(2).toString())}
              </span>
            </Col>
          </Row>
        </div>

        <div>{
          (section.maximo === 1 || (produto.CalcularComplenetos !== 2 && section.chave === 99999)) ?
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginRight: 0 }}>
              <Checkbox checked={item.selected === 1 ? true : false}
                onChange={() => checkBoxPress(item, section, index)} />
            </div> : <div className="grupo--item">
              <div>
                <Button type={'text'}
                  icon={<MinusCircleOutlined style={{
                    color: item.selected === 0 ? 'transparent' : 'red'
                  }} />}
                  onClick={() => clickAdd(item, section, index, -1)}>
                </Button>
              </div>
              <div>
                <div style={{ minWidth: 20, textAlign: 'center' }}>
                  {item.selected === 0 ? '' : item.selected}
                </div>
              </div>
              <div>
                <Button
                  type={"text"}
                  icon={<PlusCircleOutlined style={{ color: 'green' }} />}
                  onClick={() => clickAdd(item, section, index, 1)}>
                </Button>
              </div>
            </div>
        }
        </div>
      </Col>
      <Col span={24}><Divider style={{ margin: 0 }} /></Col>

    </Row>

  }

}

export default Produto