import React, { useEffect, useState } from 'react'
import Circle from 'react-circle';
import { api, loadHistoricoFidelidade } from '../../service/api.service'
import { Space, Tabs, Tag, List, Avatar, Button, Alert, App } from 'antd'
import { useParams } from 'react-router-dom';
import { userState, lojaState, pedidoState } from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil';



export const Fidelidade = (props) => {

  // const navigate = useNavigate()
  // const { setFidelidade } = props;
  // const [value, setValue] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [data, setData] = useState([]);
  const [inviteCode, setInviteCode] = useState('');
  const loja = useRecoilValue(lojaState);
  const user = useRecoilValue(userState);
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [pedido, setPedido] = useRecoilState(pedidoState)

  const { notification } = App.useApp();

  useEffect(() => {
    load();
  }, [])


  async function load() {
    setLoading(true)
    /*
    const response = await api.get('getfidelidadehistorico?uid='
      .concat(user.uid, '&path=', params.restaurant));
    var data = response.data;
    */
    var data = await loadHistoricoFidelidade(user?.uid, params.restaurant)
    if (data !== undefined && data !== null) {
      data = Object.values(data)
      if (data.length > 0) {
        setSaldo(data[0].saldo);
      }
    }
    setData(data)

    //recupera invite
    if (user !== null) {
      if (user?.isAnonymous === false) {
        if (!user?.inviteCode) {
          const response = await api.get('getinvitecode?uid='
            .concat(user.uid, "&path=", params.restaurant));
          var data2 = response.data;
          if (data2 !== undefined && data2 !== null) {
            if (data2.length > 0) {
              setInviteCode(data2[0].inviteCode)
            }
          }
        } else {
          setInviteCode(user.inviteCode)
        }
      }
    }
    setLoading(false)
  }


  async function resgatar() {

    try {
      if (saldo >= loja?.engpoints?.config?.pontos) {

        var resgate = {
          uid: user.uid,
          path: loja?.path
        }

        if (loja?.modo === 1) {
          
          setPedido({
            ...pedido, fidelidade: {
              tag: loja?.engpoints?.config?.nome === undefined ? 'Engpoints' : loja?.engpoints?.config?.nome,
              valor: loja?.engpoints?.config?.desconto
            }
          })

          notification.info(
            {
              message: `Parabéns! `,
              description: 'O programa de fidelidade foi aplicado com sucesso em seu cupom. O saldo será descontado ao enviar o pedido.!!',
              placement: 'topRight',
            })

          props.showFidelidade(false)
          // navigate("/restaurante/".concat(loja?.path))
          //  }
          //  }
        } else {
          // navigate("/restaurante/".concat(loja?.path))
        }

      }
    } catch (erro) {
      console.log('erro', erro)
    }

  }

  function renderTabs(index) {
    switch (index) {
      case 0:
        return renderPrograma()
      case 1:
        return renderHistorico()
      default:
        return renderInvite()
    }
  }


  return <div>
    {
      user?.isAnonymous !== false &&
      <Alert type='info' style={{ fontSize: '0.9em' }} showIcon message={"Necessário se registrar para participar do programa de fidelidade!"} />
    }
    <Tabs
      defaultActiveKey="1"
      centered
      items={["O PROGRAMA", "HISTORICO", "CONVITE"].map((text, i) => {
        const id = String(i + 1);
        return {
          label: text,
          key: id,
          children: renderTabs(i),
        };
      })}
    />
  </div>


  function renderPrograma() {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Space direction='vertical' size={'small'}>
        <div style={{ fontWeight: 'bold', marginTop: 0, fontSize: '1.1em', textAlign: 'center' }}>
          {loja?.informacoes?.Fantasia}
        </div>
        <div style={{ textAlign: 'center' }}>
          {loja?.engpoints?.config?.nome === undefined ? 'Engpoints' : loja?.engpoints?.config?.nome}
        </div>
        <div style={{ textAlign: 'center' }}>
          <Circle
            lineWidth="35"
            progress={((saldo !== undefined ? saldo : 0) * 100 / loja?.engpoints?.config?.pontos).toFixed(0)} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: 20 }}>{saldo !== undefined ? saldo.toString().concat(' pts') : " "}</span>
        </div>
        <div style={{ textAlign: 'center' }}>
          {
            (saldo >= loja?.engpoints?.config?.pontos && loja.modo === 1) && (
              <div style={{ padding: 4, marginTop: 10, marginBottom: 8 }}>
                <div style={{ marginBottom: 10 }}>
                  <img alt="award" src={require('../../img/award.png')} height={30} />
                  <span style={{
                    alignItems: 'center', justifyContent: 'center',
                    fontSize: 15, alignSelf: 'center', marginLeft: 5
                  }}>
                    Parabéns, você atingiu a pontuação necessária
                  </span>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button type='primary' size='small' onClick={() => resgatar()}>
                    Resgatar prêmio agora
                  </Button>
                </div>
              </div>

            )
          }
        </div>
        <div style={{ textAlign: 'center' }}>
          {
            (saldo >= loja?.engpoints?.config?.pontos && loja?.modo === 0 && saldo > 0) &&
            <Alert type='info' style={{ fontSize: '0.9em' }} showIcon message={"Resgate temporariamente indisponíveis!"} />
          }
        </div>
        <hr />
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>Sua pontuação</div>
          <div>
            {saldo !== undefined &&
              <span style={{ fontWeight: '700' }}>
                {saldo.toString().concat(' de ', loja?.engpoints?.config?.pontos)}
              </span>
            }
          </div>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>Cada real em compras vale</div>
          <div>1 ponto</div>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>Porcentagem sobre indicado</div>
          <div> {loja?.engpoints?.config?.porInvite.toFixed(0).concat('%')}</div>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>{"Recebe ".concat(loja?.engpoints?.config?.desconto,
            loja?.modo === 1 ? " reais " : "% ", " em desconto ao atingir")}</div>
          <div> {loja?.engpoints?.config?.pontos.toFixed(0).concat(' pts')}</div>
        </div>
        <div style={{ textAlign: 'center' }}>
          Tanto as regras de pontuação quanto o programa de fidelidade podem ser alterados e/ou habilitados e desabilitados sem aviso prévio.
        </div>

      </Space>
    </div>
  }



  function renderHistorico() {
    return <List size='small'
      className="demo-loadmore-list"
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      pagination={{
        onChange: (page) => {
          // console.log(page);
        },
        pageSize: 10,
      }}
      renderItem={(item) => (
        <List.Item >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar src={item?.pontos > 0 ? require('../../img/earn.png') : require('../../img/award.png')} />
              <Space direction='vertical' style={{ marginLeft: 10 }}>
                <strong>{item.descricao}</strong>
                <div style={{ fontSize: '0.9em', color: 'darkgray' }}>{item.data.concat(' - ', item.pontos > 0 ? "Crédito +".concat(item.pontos, ' pts') : "Resgate ".concat(item.pontos, ' pts'))}</div>
              </Space>
            </div>
            <Space size={'small'} direction='vertical'>
              <div>Saldo </div>
              <div> {item.saldo} pts</div>
            </Space>
          </div>

        </List.Item>
      )}
    />

  }


  function renderInvite() {
    if (user.isAnonymous === false) {
      return (
        <div>
          <h5 style={{
            fontWeight: 'bold', marginTop: 20, textAlign: 'center',
            marginLeft: 40, marginRight: 40
          }}>
            ENVIE SEU CÓDIGO PARA SEUS AMIGOS
          </h5>

          <h5 style={{
            fontWeight: 'bold', marginTop: 20, textAlign: 'center',
            marginLeft: 40, marginRight: 40
          }}>
            SEU CÓDIGO É
          </h5>

          <Space style={{
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <Tag color={"blue-inverse"}>{inviteCode}</Tag>
          </Space>



          <br />
          <span style={{
            margin: 20, color: 'gray', textAlign: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}>
            Ganhe pontos quando seus amigos fizerem pedidos pelo aplicativo.
          </span>


        </div>
      )
    } else {
      return <div container justify="center"
        alignItems="center">Efetue login para visualizar essa informação.
      </div>
    }
  }

}

export default Fidelidade