import React, { useEffect, useState } from 'react'
import './cidades.css'
import { Affix, Tag, Button, Row, Col, Breadcrumb, Divider } from 'antd';
import { isMobile } from 'react-device-detect'
import { Footer } from '../../component/footer'
import { api } from '../../service/api.service'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom';

export const Cidades = (props) => {

    const navigate = useNavigate()
    const { current } = props
    const [estados, setEstados] = useState([])
    const [estado, setEstado] = useState(undefined)
    const [cidades, setCidades] = useState([])
    const [loading, setLoading] = useState(false)
    const [isLoadCity, setIsLoadCity] = useState(false)

    useEffect(() => {
        // console.log('current', current)
        load()
    }, [])





    async function load() {
        try {
            setLoading(true)
            const response = await api.get('/api/v2.0/estados');
            if (response.status === 200) {
                var aux = response.data
                if (current) {
                    aux.map((item) => {
                        if (item.uf === current.uf && current.uf) {
                            item.selected = 1
                            setEstado({
                                uf: item.uf,
                                estadoname: item.estadoname
                            })
                            loadCidades(item.estadoname)
                        } else {
                            item.selected = 0
                        }
                    })
                }
                //  console.log('estados', aux)
                setEstados(aux)
            } else {

            }
        } catch (error) {
            console.log(error.message)
        }

        setLoading(false)
    }


    async function loadCidades(value) {
        try {
            setIsLoadCity(true)
            setCidades([])
            setLoading(true)
            const response = await api.get('/api/v2.0/estados/cidades', {
                params: {
                    estadoname: value
                }
            });
            // console.log(response.data)
            if (response.status === 200) {
                setCidades(response.data)
            } else {

            }
        } catch (error) {

        }

        setLoading(false)
    }



    return isMobile ? renderMobile() : renderDesktop()



    function renderMobile() {
        return <div className="cidades-main">
            <Affix offsetTop={0}>
                <div className="cidades-header">
                    <strong> Menuengfood </strong>
                </div>
            </Affix>
            <div className={'cidades-content-mobile'}>

                <div className="div-onde-vc-ta">Onde você está?</div>

                {
                    isLoadCity && <div style={{ marginTop: 8 }}>

                        <Breadcrumb style={{ marginLeft: 20 }}>
                            <Breadcrumb.Item><Button size='small' type='link' onClick={() => setIsLoadCity(false)}> Estados </Button></Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Button type='link' disabled size='small'>
                                    {estado?.estadoname}
                                </Button>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                       
                    </div>
                }


                <Row gutter={[8, 8]} style={{ margin:20 }}>
                    <Col span={1}></Col>
                    <Col span={22}>
                        {
                            isLoadCity === false && estados.map((item, index) => {
                                return <Button key={index} type={(item.selected === 1 ? 'primary' : 'default')}
                                    style={{ margin: 10, fontSize: 16, padding: 3 }}
                                    onClick={() => {
                                        var aux = Object.values(estados)
                                        estados.map((item2, index2) => {
                                            item2.selected = 0
                                            if (index === index2) {
                                                item2.selected = 1
                                                setEstado(item)
                                            }
                                        })
                                        setEstados(aux)
                                        loadCidades(item.estadoname)

                                    }}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {item.estadoname} </span>
                                </Button>
                            })
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>


                <Row gutter={[8, 8]} style={{ marginBottom: 25 }}>
                    <Col span={1}></Col>
                    <Col span={22} style={{ minHeight: 50 }}>
                        {
                            cidades.map((item, index) => {
                                return <Button key={"cid".concat(index)} type="link" style={{ margin: 10, fontSize: 16, padding: 3 }}
                                    onClick={() => navigate('/'.concat(estado.uf, '/',
                                        item.Cidade.trim().replaceAll(' ', '-').toLowerCase().normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, "")))}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {item.Cidade} </span>
                                </Button>
                            })
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row gutter={[8, 8]} style={{ marginBottom: 25, minHeight: 100 }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {loading && <Spin tip='carregando' />}
                    </Col>
                </Row>

            </div>
            <Footer />
        </div>

    }



    function renderDesktop() {
        return <div className="cidades-main">
            <Affix offsetTop={0}>
                <div className="cidades-header">
                    <strong> Menuengfood </strong>
                </div>
            </Affix>
            <div className={isMobile ? 'cidades-content-mobile' : 'cidades-content'}>
                <div className="div-onde-vc-ta">Onde você está?</div>
                <Row gutter={[8, 8]} style={{ margin: 20 }}>
                    <Col span={1}></Col>
                    <Col span={22}>
                        {
                            estados.map((item, index) => {
                                return <Button key={index} type={(item.selected === 1 ? 'primary' : 'default')}
                                    style={{ margin: 10, fontSize: 16, padding: 3 }}
                                    onClick={() => {
                                        var aux = Object.values(estados)
                                        estados.map((item2, index2) => {
                                            item2.selected = 0
                                            if (index === index2) {
                                                item2.selected = 1
                                                setEstado(item)
                                            }
                                        })
                                        setEstados(aux)
                                        loadCidades(item.estadoname)

                                    }}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {item.estadoname} </span>
                                </Button>
                            })
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>


                <Row gutter={[8, 8]} style={{ marginBottom: 25 }}>
                    <Col span={1}></Col>
                    <Col span={22} style={{ minHeight: 50 }}>
                        {
                            cidades.map((item, index) => {
                                return <Button key={"cid".concat(index)} type="link" style={{ margin: 10, fontSize: 16, padding: 3 }}
                                    onClick={() => navigate('/'.concat(estado.uf, '/',
                                        item.Cidade.trim().replaceAll(' ', '-').toLowerCase().normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, "")))}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {item.Cidade} </span>
                                </Button>
                            })
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row gutter={[8, 8]} style={{ marginBottom: 25, minHeight: 100 }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {loading && <Spin tip='carregando' />}
                    </Col>
                </Row>

            </div>
            <Footer />
        </div>
    }

}