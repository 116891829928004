import React, { useEffect, useState } from 'react'
import AppBar from '../../component/appBar'
import { useParams, useNavigate } from 'react-router-dom'
import { userState, lojaState } from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import { api } from '../../service/api.service'
import {Spin} from 'antd'


export const RestaurantOld = (props) => {

    const navigate = useNavigate()
    const params = useParams()
    const user = useRecoilValue(userState)
    const [loja, setLoja] = useRecoilState(lojaState)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        load()
    }, [])


    async function load() {
        try {
            const response = await api.get('getappcardapio?path='.concat(params.path, '&uid=',
                user?.uid === undefined ? '4yKjAX1sabZ0ItnURNk5PGeLI4A3' : user?.uid));

            if (response.data !== undefined) {
                if (response.data.status === 'success') {
                    if (response.data.loja !== undefined) {
                        setLoja(response.data.loja);
                        if (response.data.loja.modo === 1) {
                            if (response.data.loja.informacoes.Estado && response.data.loja.informacoes.Cidade)
                                navigate("/".concat(response.data.loja.informacoes.Estado,
                                    '/',
                                    response.data.loja.informacoes.Cidade
                                        .replaceAll(' ', '-').toLowerCase().normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, ""),
                                    '/',
                                    params.path))
                        }

                    }
                }
            } else {
                navigate("/noMath")
            }
            setLoading(false)
        } catch (erro) {
            setLoading(false)
        }
    }


    return <div className="default-main">
        <AppBar />

        {
            loading && <div style={{ display: 'flex', justifyContent: 'center', marginTop:20, alignItems: 'center' }}>
                {loading && <Spin tip='carregando' />}
            </div>
        }
    </div>
}

export default RestaurantOld