import React, { useEffect, useState } from 'react'
import { api } from '../../service/api.service'
import { useParams } from 'react-router-dom'
import { Rate, Tag, Row, Col, List, Badge, Avatar } from 'antd'
import { StarFilled } from '@ant-design/icons';
import '../../view/restaurant/restaurant.css'
import './avaliacoes.css'
import { useRecoilValue } from 'recoil';
import { lojaState } from '../../store/dataRecoil';

export const Avaliacoes = (props) => {

    const params = useParams()
    const [data, setData] = useState([])
    const loja = useRecoilValue(lojaState)

    useEffect(() => {
        loadAvaliacoes()
    }, [])

    function formatDate(data) {
        try {
            var array = data.split('-');
            return array[2].concat('/', array[1], '/', array[0])

        } catch (erro) {
            return data;
        }
    }

    async function loadAvaliacoes() {
        try {
            const response = await api.get('api/v2.0/pedidos/avaliacoes?path='
                .concat(params.restaurant));

          //  console.log("Response", response.data)
            if (response.status === 200) {
                setData(response.data)
            }

        } catch (error) {
            console.log(error.message);
        }
    }



    return <Row>
        <Col span={24}>
            <div>
                <Tag color="#FFD700">
                    <StarFilled style={{ marginRight: 3 }} readOnly/>
                    <b style={{ fontSize: 16 }}>  {loja?.rate} </b>
                </Tag>

                {
                    (loja?.rate !== '' && loja?.rate !== 'nova') &&
                    <Tag>
                        {loja?.avaliacoes} Avaliações
                    </Tag>

                }
            </div>

            <List
                header={
                    null
                }
                footer={null}
                bordered
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                        <div className="avaliacoes-list">
                            <div>
                                <Badge count={item.count} >
                                    <Avatar alt="user"
                                        src={(!item.url) ? 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png' : { uri: item.uri }}
                                        size="default" />
                                </Badge>
                            </div>
                            <div className="avaliacoes-list-item">
                                <div className="avaliacoes-list-item-column">
                                    <div>
                                        <b>{item?.name}</b>
                                    </div>
                                    <div>
                                        {item.descricao}
                                    </div>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={24} className="avaliacoes-item-rigth">
                                            {formatDate(item.data)}
                                        </Col>
                                        <Col span={24} className="avaliacoes-item-rigth">
                                            <Rate disabled  value={parseFloat(((item.comida + item.entrega + item.app) / 3).toFixed(1))} />
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </div>
                    </List.Item>
                )}
            />

        </Col>
    </Row>

}

export default Avaliacoes