import React, { useState, useEffect } from 'react'
import { DatePicker, Button,  App,  ConfigProvider } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import ptBR from "antd/es/locale/pt_BR";
import { FlexboxGrid, Divider, Container,  Content,Col, Table,  } from 'rsuite'
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';

const { Column, HeaderCell, Cell } = Table
const { RangePicker } = DatePicker;


export const Recebimentos = (props) => {

    const navigate = useNavigate()
    const waiterUser = useRecoilValue(waiterUserState)
    const { message, notification, modal } = App.useApp(); 
    const [dates, setDates] = useState([dayjs().add(-10, 'days'), dayjs()])
    const [dados, setDados] = useState([])
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)

    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return current && current > dayjs().endOf('day')
        /*
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 15;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 15;
        const tooLate2 = current > dayjs().endOf('day')
        return !!tooEarly || !!tooLate || tooLate2;
        */
    };


    useEffect(() => {
        pesquisar()
    }, [])

    async function pesquisar() {
        let _total = 0
        try {            
            setLoading(true)
            socket.emit('recebimentos', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {

                let lst = []
                let valor = 0
                result?.data.map((item, index) => {
                    _total = _total + parseFloat(item.reciboValorPago)
                    if (lst.length === 0) {
                        valor  = parseFloat(item.reciboValorPago)
                        lst.push({
                            data: item.reciboData,
                            valor: valor,
                            itens: [{
                                bandeira: item.formaPagamentoDescricao,
                                valor: item.reciboValorPago
                            }]
                        })
                    } else {
                        if (lst[lst.length - 1].data === item.reciboData) {
                            valor = valor + parseFloat(item.reciboValorPago)
                            lst[lst.length - 1].valor = valor
                            lst[lst.length - 1].itens.push(
                                {
                                    bandeira: item.formaPagamentoDescricao,
                                    valor: item.reciboValorPago
    
                                })
                        } else {
                            valor = parseFloat(item.reciboValorPago)
                            lst.push({
                                data: item.reciboData,
                                valor: valor,
                                itens: [{
                                    bandeira: item.formaPagamentoDescricao,
                                    valor: item.reciboValorPago
                                }]
                            })
                        }
                    }
                })
                setDados(lst)
                setLoading(false)
                setTotal(_total)
            })
           
        } catch (error) {
            console.log(error.message)
            notification.error({ message: error.message });
            setLoading(false)
        }
       
    }




    return <div className="show-grid">

        <Container>
            <Content>
                <div className="show-grid">
                    <FlexboxGrid>
                        <FlexboxGrid.Item as={Col} colspan={24} >
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>

                                <Button size='small' type='primary' style={{ marginLeft: 5 }}
                                    onClick={() => pesquisar()}>Pesquisar</Button>

                                <ConfigProvider locale={ptBR}>
                                    <RangePicker
                                        locale={ptBR}
                                        size='small'
                                        allowClear={false}
                                        format={'DD-MM-YYYY'}
                                        value={dates || value}
                                        disabledDate={disabledDate}

                                        onChange={(val) => {
                                            setDates(val);
                                        }}
                                        changeOnBlur />
                                </ConfigProvider>
                                <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>
                            </div>
                        </FlexboxGrid.Item>



                        <FlexboxGrid.Item style={{ width: '100%' }}>
                            <>
                                {
                                    dados?.map((item, index) => {
                                        return <div key={item.data} style={{ width: '100%', marginTop: index===0? 0 : 20 }}>
                                            <strong>
                                                {'DATA: '.concat(dayjs(item.data).format('DD/MM/YYYY'))}
                                            </strong>
                                            <Table
                                                autoHeight={true}
                                                hover
                                                height={600}
                                                rowHeight={30}
                                                headerHeight={30}
                                                data={item?.itens}
                                                onRowClick={rowData => {
                                                    // console.log(rowData);
                                                }}>
                                                <Column fixed flexGrow={1}>
                                                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>BANDEIRA</HeaderCell>
                                                    <Cell dataKey="BANDEIRA" style={{ padding: 3 }}>
                                                        {rowData => {
                                                            return rowData.bandeira
                                                        }}
                                                    </Cell>
                                                </Column>
                                                <Column align="center" width={60}>
                                                    <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>VALOR</HeaderCell>
                                                    <Cell dataKey="VALOR" style={{ padding: 3 }}>
                                                        {rowData => {
                                                            return "R$".concat(rowData.valor)
                                                        }}
                                                    </Cell>
                                                </Column>

                                            </Table>

                                            <i style={{ width: '100%', display:'flex', 
                                            flexDirection:'row', justifyContent:'flex-end'}}>
                                                {'Total para o período: R$'.concat(item.valor.toFixed(2))}
                                            </i>
                                            <Divider/>

                                        </div>
                                    })
                                }
                            </>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item 
                        style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', width:'100%' }}>
                           
                                {dados.length > 0 ? "TOTAL GERAL R$".concat(total.toFixed(2)) : ''}
                            
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item 
                        style={{ display: 'flex', flexDirection: 'row', justifyContent:'center',  alignItems: 'center', width:'100%' }}>
                           
                                {loading === true ? "loading..." : ''}
                            
                        </FlexboxGrid.Item>


                    </FlexboxGrid>
                </div>
            </Content>
        </Container>
    </div>


}
