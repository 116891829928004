import React from 'react'
import { Button, Result, Affix } from 'antd';
import { useNavigate } from 'react-router-dom';

export const ManagerNoMatch = (props) => {


    const navigate = useNavigate()

    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="Desculpe, a pagina que você visitou não existe ou não foi localizada."
                extra={<Button type="primary" onClick={()=>{
                    navigate("/manager")
                }}>Inicio</Button>}
            />
        </div>
    )
}
