import React, { useEffect, useState } from 'react'
import '../cidades/cidades.css'
import { Affix, Button, Input, Space, Alert } from 'antd';
import { isMobile } from 'react-device-detect'
import { Footer } from '../../component/footer'
import { api } from '../../service/api.service'
import { useNavigate } from 'react-router-dom';
import {validateEmail} from '../../service/dal.service'

export const DeleteUserAccount = (props) => {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [sucesso, setSucesso] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {

    }, [])



    async function excluirConta() {
        try {

            setSucesso(false)
            setLoading(true)
            await api.post('/api/v2.0/usuarios/delete-user-account', { email: email });
            setSucesso(true)
        } catch (error) {
            console.log(error.message)
        }

        setLoading(false)
    }






    return isMobile ? renderMobile() : renderDesktop()



    function renderMobile() {
        return <div className="cidades-main">
            <Affix offsetTop={0}>
                <div className="cidades-header">
                    <strong> Menuengfood </strong>
                </div>
            </Affix>
            <div className={'cidades-content-mobile'}>
                {renderExclusao()}
            </div>
            <Footer />
        </div>

    }



    function renderExclusao() {
        return <div style={{ padding: 50, minHeight: 300 }}>
            <div style={{ fontSize: '1.3em', fontWeight: 500 }}>Para excluir sua conta informe o E-mail cadastrado</div>
            <div style={{ marginTop: 10 }}>
                <Input value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder='Informe seu e-mail' />
            </div>
            <Space direction='horizontal' style={{ marginTop: 10 }}>
                <Button onClick={() => navigate('/')}>Voltar</Button>
                <Button type='primary' danger
                    loading={loading}
                    disabled={!validateEmail(email)}
                    onClick={() => excluirConta()}>Excluir</Button>
            </Space>

            {sucesso === true && <div style={{ marginTop: 20 }}>
                <Alert type='success' showIcon message={<b>Atenção!</b>}
                    description={"Um email de exclusão foi enviado para ".concat(email)} />
            </div>
            }
        </div>
    }



    function renderDesktop() {
        return <div className="cidades-main">
            <Affix offsetTop={0}>
                <div className="cidades-header">
                    <strong> Menuengfood </strong>
                </div>
            </Affix>
            <div className={isMobile ? 'cidades-content-mobile' : 'cidades-content'}>
                {renderExclusao()}

            </div>
            <Footer />
        </div>
    }

}