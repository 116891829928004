import React, { useState, useEffect } from 'react'
import { Row, Col, Tag, Button, Modal, Input, Alert } from 'antd';
import '../appBar/appBar.css'
import { GoogleOutlined } from '@ant-design/icons'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup
}
  from "firebase/auth";
import { validateEmail } from '../../service/dal.service'
import { api } from '../../service/api.service'
import InputMask from 'react-input-mask';
import { userState, selCurrentAddress } from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useToasts } from 'react-toast-notifications';



export const Account = (props) => {

  const current = useRecoilValue(selCurrentAddress)

  const toash = useToasts()
  const [user, saveUser] = useRecoilState(userState)
  const [modo, setModo] = useState(props.modo > -1 ? props.modo : 0)
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [loading, setLoading] = useState(false)
  const [visibleCodigo, setVisibleCodigo] = useState(false)
  const [inviteCode, setInviteCode] = useState('')
  const [invitedcode, setInvitedcode] = useState('')
  const [userTemp, setUserTemp] = useState({})

  const [msgErro, setMsgErro] = useState('')


  useEffect(() => {
    if (user) {
      if (user.isAnonymous === false) {
        setUserTemp(user)
        setModo(3)
      }
    }
  }, [])


  async function loginGoogle() {
    try {

      setLoading(true)
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          var usuario = result.user;
          usuario.name = usuario.displayName
          usuario.nome = usuario.displayName
          if (!usuario && usuario.photoURL) {
            usuario.photoUrl = usuario.photoURL

          }
          const resp2 = await api.post('api/v2.0/usuarios', usuario)
          console.log('resp2', resp2.data, current)
          if (resp2.status === 200) {
            usuario = Object.assign(usuario, resp2.data);
            // usuario.isAnonymous = false
          }

          // console.log('saveuser')
          saveUser(usuario)

          if (auth.currentUser.isAnonymous === false) {
            props.showAccount(false)

          } else {
            alert('algo inesperado aconteceu e não conseguimos processsar os dados corretamente!!!')
          }

          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
          alert(error.message)
        });

    } catch (error2) {
      alert(error2.message)
    }
    setLoading(false)
  }


  async function Login() {
    try {
      setMsgErro('')
      setLoading(true)
      const auth = getAuth();
      const result = await (await signInWithEmailAndPassword(auth, email.trim(), senha.trim()));
      // console.log('Result', result)
      if (result?.user) {
        if (!result.user?.isAnonymous) {
          var user = result?.user
          var photoUrl = null
          if (user.providerData.length > 0) {
            if (user.providerData[0].photoURL !== null && user.providerData[0].photoURL !== undefined)
              photoUrl = user.providerData[0].photoURL
          }
          const resp = await api.get("api/v2.0/usuarios/getuser?uid=".concat(user.uid))

          if (resp.data !== undefined && resp.data !== null && resp.data !== '') {
            user = resp.data;
            user.isAnonymous = false
            if (user.photoURL === null || user.photoURL === undefined || user.photoURL === "")
              user.photoUrl = photoUrl;
          } else {
            if (user.photoURL === null || user.photoURL === undefined || user.photoURL === "")
              user.photoUrl = photoUrl;
            const resp2 = await api.post('api/v2.0/usuarios', user)
            if (resp2.data !== undefined && resp2.data !== null && resp2.data !== '') {
              user = resp2.data;
              user.isAnonymous = false
            }
          }
          saveUser(user)
          props.showAccount(false)

        }
      } else {
        setMsgErro('Erro na autenticação junto ao servidor!')
        toash.addToast('Erro na autenticação junto ao servidor!', { appearance: 'error', autoDismiss: true })
      }
    } catch (error) {
      console.error("Login erorr", error.message)
      if (error.code === 'auth/wrong-password' || error.code === 'auth/invalid-email' || error.code === 'auth/user-not-found') {
        toash.addToast('Senha ou e-mail incorreto!', { appearance: 'error', autoDismiss: true })
        setMsgErro('Senha ou e-mail incorreto!')
      } else if (error.code === 'auth/too-many-requests') {
        setMsgErro('O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login malsucedidas. Você pode restaurá-lo imediatamente redefinindo sua senha ou você pode')
        toash.addToast('O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login malsucedidas. Você pode restaurá-lo imediatamente redefinindo sua senha ou você pode',
          { appearance: 'error', autoDismiss: true })
      } else {
        setMsgErro('error.message')
        toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
      }
    }
    setLoading(false)
  }

  async function updateAccount() {
    try {

      if (userTemp?.displayName?.length < 2) {
        toash.addToast('Informe um nome válido com pelo menos 2 caracteres!',
          { appearance: 'warning', autoDismiss: true })
      } else if (!validateEmail(userTemp?.email)) {
        toash.addToast('Informe um email válido!!!', { appearance: 'warning', autoDismiss: true })
      } else {
        setLoading(true)
        var user2 = {
          displayName: userTemp.displayName,
          nome: userTemp.displayName,
          name: userTemp.displayName,
          phoneNumber: userTemp.phoneNumber,
          email: userTemp.email,
          uid: userTemp.uid,
          isAnonymous: userTemp.isAnonymous,
          cpf: userTemp.cpf,
          invitedcode: invitedcode
        }
        const resp2 = await api.post('api/v2.0/usuarios', user2)
        if (resp2.status === 200) {
          user2 = resp2.data;
          user2.isAnonymous = false
        }
        // console.log('saveuser')
        saveUser(user2)
        props.showAccount(false)
      }

    } catch (error) {
      toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
    }

    setLoading(false)

  }


  async function createAccount(user) {
    try {
      try {
        //console.log('user', user)
        if (userTemp?.displayName?.length < 2) {
          toash.addToast('Informe um nome válido com pelo menos 2 caracteres!', { appearance: 'warning', autoDismiss: true })

        } else if (userTemp?.phoneNumber.trim().length < 9) {
          toash.addToast('Informe um telefone válido!',
            { appearance: 'warning', autoDismiss: true })

        } else if (!validateEmail(userTemp?.email)) {
          toash.addToast('Informe um email válido!!!', { appearance: 'warning', autoDismiss: true })
        } else {
          setLoading(true)
          const auth = getAuth();
          const credencial = await createUserWithEmailAndPassword(auth, userTemp?.email, userTemp.senha)
          if (credencial !== null) {
            if (credencial.user !== null) {
              var usr = Object.assign({}, credencial.user)
              usr.path = ''
              if (credencial.user !== null) {
                var user2 = {
                  displayName: userTemp.displayName,
                  nome: userTemp.displayName,
                  name: userTemp.displayName,
                  phoneNumber: userTemp.phoneNumber,
                  email: userTemp.email,
                  uid: credencial.user.uid,
                  isAnonymous: credencial.user.isAnonymous,
                  cpf: userTemp.cpf,
                  invitedcode: invitedcode
                }

                // const resp2 = await api.post('postuser', user2)
                const resp2 = await api.post('api/v2.0/usuarios', user2)

                console.log('resp2', resp2.data)
                if (resp2.status === 200) {
                  user2 = resp2.data;
                  user2.isAnonymous = false
                }

                console.log('saveuser')
                saveUser(user2)

                if (auth.currentUser.isAnonymous === false) {
                  props.showAccount(false)
                } else {
                  toash.addToast('algo inesperado aconteceu e não conseguimos processsar os dados corretamente!!!',
                    { appearance: 'warning', autoDismiss: true })
                }
              }
            }
          }

        }

      } catch (error) {

        if (error.code === 'auth/email-already-in-use') {
          toash.addToast(<p>Já existe um cadastro criado com o e-mail <strong>{userTemp?.email.toLowerCase()}</strong> nesse
            ou outro aplicativo que pertence a plataforma
            de restaurantes Engfood,
            click em ENVIAR Senha para que seja enviado um e-mail
            de recuperação de senha para o e-mail informado.</p>, { appearance: 'warning', autoDismiss: true })
          setModo(2)
        } else {
          toash.addToast('Erro ao salvar dados!', { appearance: 'warning', autoDismiss: true })
        }
        console.log('error', error)
      }

    } catch (error) {
      toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
    }

    setLoading(false)
  }


  async function forgot() {
    try {
      const auth = getAuth();
      const result = await sendPasswordResetEmail(auth, email)
      toash.addToast('Um e-mail de recuperação de senha foi enviado para '.concat(email),
        { appearance: 'success', autoDismiss: true })
      // console.log('forgot', result)
      setModo(0)
    } catch (error) {
      toash.addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }


  async function validarCodigo() {
    try {
      const response = await api.get('/api/v2.0/usuarios/validar-invitecode?code='.concat(inviteCode));

      if (response.data !== undefined && response.data !== null) {
        if (response.data.length > 0) {

          toash.addToast('Código válidado com sucesso!.', { appearance: 'success', autoDismiss: true })
          setInvitedcode(inviteCode)
          setVisibleCodigo(false)
          //  props.showAccount(false)

        } else {
          toash.addToast('Não foi possível validar o código, verifique se o mesmo está correto e tente novamente.',
            { appearance: 'warning', autoDismiss: true })
        }

      } else {
        toash.addToast('Não foi possível validar o código, verifique se o mesmo está correto e tente novamente.',
          { appearance: 'warning', autoDismiss: true })
      }

    } catch (erro) {
      toash.addToast('Erro ao tentar válidar o código!', { appearance: 'warning', autoDismiss: true })
    }
  }


  function renderModo() {
    switch (modo) {
      case 1:
        return <Row gutter={[16, 8]} style={{ marginTop: 20 }}>
          <Col span={24} style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> CRIAR CONTA</Col>

          <Col span={4}>Nome</Col>
          <Col span={18}>
            <Input value={userTemp?.displayName} maxLength={150} onChange={(e) => setUserTemp({ ...userTemp, displayName: e.target.value })} />
          </Col>

          <Col span={4}>Celular*</Col>
          <Col span={18}>
            <InputMask
              mask="99 99999-9999"
              maskChar=" "
              value={userTemp?.phoneNumber}
              onChange={e => setUserTemp({ ...userTemp, phoneNumber: e.target.value })}>
              {() => <Input maxLength={16} />}
            </InputMask>
          </Col>

          <Col span={4}>CPF</Col>
          <Col span={18}>
            <InputMask
              mask="999.999.999-99"
              maskChar=" "
              value={userTemp?.cpf}
              onChange={e => setUserTemp({ ...userTemp, cpf: e.target.value })}>
              {() => <Input />}
            </InputMask>
          </Col>

          <Col span={4}>E-mail</Col>
          <Col span={18}>
            <Input value={userTemp?.email} maxLength={150}
              onChange={(e) => setUserTemp({ ...userTemp, email: e.target.value.toLowerCase() })} />
          </Col>

          <Col span={4}>Senha</Col>
          <Col span={18}>
            <Input.Password value={userTemp?.senha} maxLength={12}
              onChange={(e) => setUserTemp({ ...userTemp, senha: e.target.value })} />
          </Col>
          <Col span={4}>Repetir senha</Col>
          <Col span={18}>
            <Input.Password value={userTemp?.senha2} maxLength={12} onChange={(e) => setUserTemp({ ...userTemp, senha2: e.target.value })} />
          </Col>
          {
            (userTemp?.senha && userTemp?.senha2 && userTemp?.senha !== userTemp.senha2) && <Col span={18} offset={6} style={{ color: 'red' }}>
              As senhas não são iguais
            </Col>
          }




          <Col span={24}
            style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>

            <Button type="link" danger onClick={() => setVisibleCodigo(true)}>Possui código de indicação?</Button>
            <Tag>{invitedcode}</Tag>


          </Col>
          <Col span={4}></Col>
          <Col span={18}>
            <Button type="primary" block
              loading={loading}
              disabled={userTemp?.senha?.trim().length < 6 || !validateEmail(userTemp?.email) || userTemp?.senha !== userTemp?.senha2 ||
                userTemp?.displayName?.trim() === ''}
              onClick={() => {
                createAccount(user)
              }}>
              Cadastrar
            </Button>
          </Col>
          <Col span={24}><br /></Col>
        </Row>
        break;

      case 2:
        return <Row gutter={[16, 8]} style={{ marginTop: 40 }}>
          <Col span={24} style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> RECUPERAR SENHA</Col>
          <Col span={24}>Informe no campo abaixo o e-mail cadastrado no sistema para que possamos lhe enviar um e-mail com link para redefinir sua senha</Col>
          <Col span={4}>E-mail</Col>
          <Col span={18}>
            <Input value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
          </Col>
          <Col span={4}></Col>
          <Col span={18}>
            <Button type="primary" block
              loading={loading}
              disabled={!validateEmail(email)}
              onClick={() => {
                forgot()
              }}>
              Enviar
            </Button>
          </Col>
        </Row>


      case 3:
        return <Row gutter={[16, 8]} style={{ marginTop: 20 }}>
          <Col span={24} style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>EDITAR DADOS</Col>

          <Col span={4}>Nome*</Col>
          <Col span={18}>
            <Input value={userTemp?.displayName} maxLength={150} onChange={(e) => setUserTemp({ ...userTemp, displayName: e.target.value })} />
          </Col>

          <Col span={4}>Celular*</Col>
          <Col span={18}>
            <InputMask
              mask="99 99999-9999"
              maskChar=" "
              value={userTemp?.phoneNumber}
              onChange={e => setUserTemp({ ...userTemp, phoneNumber: e.target.value })}>
              {() => <Input maxLength={16} />}
            </InputMask>
          </Col>

          <Col span={4}>CPF</Col>
          <Col span={18}>
            <InputMask
              mask="999.999.999-99"
              maskChar=" "
              value={userTemp?.cpf}
              onChange={e => setUserTemp({ ...userTemp, cpf: e.target.value })}>
              {() => <Input />}
            </InputMask>
          </Col>

          <Col span={4}>E-mail*</Col>
          <Col span={18}>
            <Input value={userTemp?.email} maxLength={150}
              onChange={(e) => setUserTemp({ ...userTemp, email: e.target.value.toLowerCase() })} />
          </Col>

          <Col span={24}
            style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>

            <Button type="link" danger onClick={() => setVisibleCodigo(true)}>Possui código de indicação?</Button>
            <Tag>{invitedcode}</Tag>


          </Col>
          <Col span={4}></Col>
          <Col span={18}>
            <Button type="primary" block
              loading={loading}
              disabled={!validateEmail(userTemp?.email) || userTemp?.displayName.length < 2}
              onClick={() => {
                updateAccount()
              }}>
              Atualizar
            </Button>
          </Col>
          <Col span={24}><br /></Col>
        </Row>
        break;



      default:
        return <Row gutter={[16, 8]} style={{ marginTop: 40 }}>
          <Col span={24} style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> LOGIN</Col>

          <Col span={24}>
            {
              msgErro && <Alert
                afterClose={() => setMsgErro('')}
                style={{ textAlign: 'center' }}
                message={msgErro}
                showIcon
                type='error' />
            }
          </Col>

          <Col span={4}>E-mail</Col>
          <Col span={18}>
            <Input value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
          </Col>

          <Col span={4}>Senha</Col>
          <Col span={18}>
            <Input.Password value={senha} onChange={(e) => setSenha(e.target.value)} />
          </Col>
          <Col span={24} style={{ height: 1 }}></Col>
          <Col span={4}></Col>
          <Col span={18}>
            <Button type="primary" block
              loading={loading}
              disabled={senha.trim().length < 6 || !validateEmail(email)}
              onClick={() => {
                Login()
              }}>
              Entrar
            </Button>
          </Col>

          <Col span={4}></Col>
          <Col span={18}>
            <Button type="dashed" block danger icon={<GoogleOutlined />}
              onClick={() => loginGoogle()}>
              Entrar com Google
            </Button>
          </Col>
          {/*
          <Col span={4}></Col>
          <Col span={18} >
            <Button type="dashed" block icon={<FacebookFilled style={{ color: 'blue' }} />}
              onClick={() => loginFaceBook()}>
              Entrar com Facebook
            </Button>
          </Col>
            */}
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button type="link" onClick={() => setModo(2)}>Esqueci a senha</Button>
          </Col>
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Não possui uma conta?
            <Button type="link" onClick={() => setModo(1)}>Criar conta</Button>

          </Col>
        </Row>
    }
  }




  return <div>
    {renderModo()}

    {/* MODAL DE VALIDAÇÃO DE CÓDIGO */}
    <Modal title={null}
      open={visibleCodigo}
      footer={null}
      width={300}
      centered
      closable={false}
      onOk={() => setVisibleCodigo(false)}
      onCancel={() => setVisibleCodigo(false)}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <b> INFORME O CÓDIGO DE INDICAÇÃO </b>
        </Col>
        <Col span={24}>
          <Input value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} />
        </Col>
        <Col span={24}>
          <div className="div-invite-code">
            <div className="div-invite-code-item">
              <Button type="dashed" danger onClick={() => setVisibleCodigo(false)}>Cancelar</Button>
            </div>
            <div>
              <Button type="primary"
                disabled={inviteCode.trim() === ''}
                onClick={() => validarCodigo()}>Validar</Button>
            </div>
          </div>
        </Col>

      </Row>
    </Modal>


  </div>

}


/*
const mapStateToProps = state => ({
    loja: state.lojaReducer.loja,
    loading: state.lojaReducer.loading,
    menuengfood: state.lojaReducer.menuengfood,
    user: state.userReducer.user,
    menu: state.lojaReducer.menu,
    pedido: state.pedidoReducer,
    current: state.addressReducer.current,
    address: state.addressReducer.address,
})

const mapDispatchToProps = dispatch => ({
    saveUser: (user) => dispatch(ActionUser.save_user(user)),
    clearPedido: (path) => dispatch(ActionPedidos.clear_pedido(path))
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
*/

export default Account