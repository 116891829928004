import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import { recoilPersist } from 'recoil-persist'
import {distance} from '../service/dal.service'

const { persistAtom } = recoilPersist()


export const TIME_SEGUNDOS = 3600 * 1000

export const rec_lgpd = atom({
    key: 'rec_lgpd', // unique ID (with respect to other atoms/selectors)
    default: true, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});

export const rec_dataLGPD = atom({
    key: 'rec_dataLGPD', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});



export const pedidoState = atom({
    key: 'pedidoState',
    default: {
        createdAt: new Date().getTime(),
        local: '',
        itens: [],
        type: 'DELIVERY'
    },
    effects_UNSTABLE: [persistAtom]
});


/*
const pedidoAtom = atom({
    key: 'pedidoAtom',
    default: {
        createdAt: new Date().getTime(),
        local: '',
        itens: [],
        type: 'DELIVERY'
    },
    effects_UNSTABLE: [persistAtom]
});

export const pedidoState = selector({
    key: 'pedidoState',
    get: ({ get }) => {
        var carrinho = get(pedidoAtom)
        var value = new Date().getTime();
        if (!carrinho) {
            carrinho = {
                createdAt: value,
                local: carrinho.path,
                itens: [],
                type: 'DELIVERY'
            };
        }
        if (value - carrinho.createdAt > TIME_SEGUNDOS) {
           // console.log('AQUI')
            carrinho = {
                createdAt: value,
                local: carrinho.path,
                itens: [],
                type: 'DELIVERY'
            }           
        }

        carrinho = {
            createdAt: carrinho.createdAt,
            local: carrinho.path,
            itens: carrinho.itens,
            type: carrinho.type,
            fidelidade: carrinho.fidelidade,
            cupom: carrinho.cupom
        }
       // set(pedidoAtom, carrinho)
       // console.log(carrinho)
        return carrinho
    },
    set: ({ set }, newValue) => set(pedidoAtom, newValue),
    effects_UNSTABLE: [persistAtom]
});
*/

/*
export const getPedidoSelector = selector({
    key: 'getPedidoSelector',
    get: ({ get }) => {
        var carrinho = get(pedidoState);
        var value = new Date().getTime();
        if (!carrinho) {
            carrinho = {
                createdAt: value,
                local: carrinho.path,
                itens: [],
                type: 'DELIVERY'
            };
        }
        if (value - carrinho.createdAt > TIME_SEGUNDOS) {
            carrinho = {
                createdAt: value,
                local: carrinho.path,
                itens: [],
                type: 'DELIVERY'
            }
        }

        carrinho = {
            createdAt: carrinho.createdAt,
            local: carrinho.path,
            itens: carrinho.itens,
            type: carrinho.type,
            fidelidade: carrinho.fidelidade,
            cupom: carrinho.cupom
        }

        return carrinho

    },
});
*/

export const current_address = atom({
    key: 'current_address', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});

export const all_address = atom({
    key: 'all_address', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});


export const userState = atom({
    key: 'userState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});

export const lojaState = atom({
    key: 'lojaState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});

export const selCurrentAddress = selector({
    key: 'selCurrentAddress',
    get: ({ get }) => {
        var address ={... get(current_address)}
        var all = get(all_address);
        var l = get(lojaState);
        var bairros = []
        if (l)
            bairros = l.bairros

        if (!address) {
            if (all?.length > 0)
                address = all[0]
            else
                address = {
                    latitude: 0,
                    longitude: 0
                }
        }
      //  console.log(l)
        if (l?.informacoes.taxtype === 1) {

            const km = distance(address.latitude, address.longitude, l?.informacoes?.latitude, l?.informacoes?.longitude)
        //    console.log('km', km)
            var element = l.taxes.find(e => km > 0 && km <= e.raio / 1000)
          //  console.log('element', element)
            if (element) {
                address.atendido = 1;
                address.taxa = parseFloat(element.tax);

            } else {
                address.taxa = -1;
                address.atendido = 0;
            }

        } else {
            var result = bairros.filter(item => {
                return item.atendido === 1 && item?.descricao?.toUpperCase() === address?.bairro?.toUpperCase()
            })
            address = JSON.parse(JSON.stringify(address))
            if (result.length > 0) {
                address.atendido = 1;
                address.taxa = result[0].valor;
                address.current = true
            } else {
                address.taxa = -1;
                address.atendido = 0;
                address.current = false
            }
        }
        return address
    },
});


// appgarçom

export const waiterUserState = atom({
    key: 'waiterUserState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});


export const waitDataBaseState = atom({
    key: 'waitDataBaseState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});


export const mesaState = atom({
    key: 'mesaState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});

export const pedidoWaiterState = atom({
    key: 'pedidoWaiterState', // unique ID (with respect to other atoms/selectors)
    default:  {
        createdAt: new Date().getTime(),
        local: '',
        itens: [],
        type: 'MESA'
    }, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});

export const comandaState = atom({
    key: 'comandaState', // unique ID (with respect to other atoms/selectors)
    default: undefined, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});


export const pedidoCardapioState = atom({
    key: 'pedidoCardapioState', // unique ID (with respect to other atoms/selectors)
    default:  {
        createdAt: new Date().getTime(),
        local: '',
        itens: [],
        type: 'MESA'
    }, // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});


export const callCardapioState = atom({
    key: 'callCardapioState', // unique ID (with respect to other atoms/selectors)
    default:  [], // default value (aka initial value),
    effects_UNSTABLE: [persistAtom]
});
