import React, { useEffect, useState } from 'react'
import { Button, Flex, App, Alert } from 'antd'
import { socket } from '../../../service/socket'
import { useNavigate } from 'react-router-dom';
import { waiterUserState, mesaState, pedidoWaiterState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import { api } from '../../../service/api.service'
import { LoadingOutlined } from '@ant-design/icons'
import { FlexboxGrid } from 'rsuite';
import { isMobile } from 'react-device-detect';

export const WaiterMesas = (props) => {

  const { notification } = App.useApp()
  const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
  const [pedido, setPedido] = useRecoilState(pedidoWaiterState)
  const [mesa, setMesa] = useRecoilState(mesaState)
  const [mesas, setMesas] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPedido({
      createdAt: new Date().getTime(),
      local: '',
      itens: [],
      type: 'MESA'
    })
    setMesa(undefined)
    getMesas()

    function onMesas(value) {
      if (Array.isArray(value))
        setMesas(value)
    console.log("onMesas",value)
    }

    socket.on('updates-mesas', onMesas);

    //console.log('waiterUser', waiterUser)

    return () => {
      socket.off('updates-mesas', onMesas);
    };

    

  }, [])


  async function getMesas() {
    try {
      // console.log('getMesas')
      setLoading(true)
      const result = await api.get('api/v1.0/manager/mesas', {
        params: {
          sala: waiterUser?.database
        },
        headers: {
          'x-access-token': waiterUser?.token,
        }
      })

     

      if (result.data.status === 200)
        setMesas(result.data.data)
      else
        setMesas([])

      //  console.log(result.data.data)

    } catch (error) {
      console.log('waiterUser?.database', waiterUser?.database)
      notification.error({
        description: 'Erro ao carregar mesas'
      })
    }
    setLoading(false)
  }


  function getColor(status) {
    var cor = ''
    switch (status) {
      case 1:
        cor = 'red';
        break;

      case 2:
        cor = 'orange';
        break;

      case 3:
        cor = 'black';
        break;

      default:
        cor = 'green';
        break;
    }

    return cor;
  }

  async function mesaClicked(item) {
    try {
      switch (item.status) {
        case 1:
        case 2:
        case 3:
          item.mesaID = item.chave
          setMesa(item)
          navigate('/manager/mesa')
          break;

        default:
          const result = await api.post('api/v1.0/manager/mesas/open', {
            mesaID: item.chave,
            garconID: waiterUser.funid,
            mesaNome: item.numero,
            tipoAtendimento: 2,
            sala: waiterUser?.database
          },
            {
              headers: {
                'x-access-token': waiterUser?.token,
              }
            })

          if (result.data.data.vendaID > 0) {
            result.data.data.status = 1
            setMesa(result.data.data)
            navigate('/manager/categorias')
          } else {
            notification.error({
              duration: 4,
              description: 'Não foi possível efetuar a abertura da mesa!'
            })
          }

          /*
                    socket.emit('setMesaOpen', waiterUser?.database, {
                      mesaID: item.chave,
                      garconID: waiterUser.funid,
                      mesaNome: item.numero,
                      tipoAtendimento: 2,
          
                    }, (value) => {
                      console.log('value', value)
                      if (value?.vendaID > 0) {
                        value.status = 1
                        setMesa(value)
                        navigate('/manager/categorias')
                      } else {
                        notification.error({
                          duration: 4,
                          description: 'Não foi possível efetuar a abertura da mesa!'
                        })
                      }
                    });
          */
          /*
            var result = await api3.post('/mesas/open',
                {
                    mesaID: item.chave,
                    garconID: garcom.ID,
                    mesaNome: item.numero,
                    tipoAtendimento: 2,

                });
            if (result.status === 200) {
                if (result.data.vendaID > 0) {
                    item.status = 1;
                    item.vendaID = result.data.vendaID;
                    setMesa(item)
                    props.history.push('/pedidos/categorias')
                } else {
                    setNotification('Aenção', 'Não foi possível efetuar a abertura da mesa!!', 'danger')
                }

            } else {
                setNotification('Aenção', 'Erro ao efetuar comunicação com servidor!', 'danger')
            }
            */
          break;
      }
    } catch (error) {
      console.error(error)
      // setNotification('Aenção', error.messsage, 'danger')
    }
  }


  async function setcall(item, waiter) {
    try {

      const data = {
        mesaChave: item.mesaChave,
        waiter: waiter,
        mesacallid: item.mesacallid,
        sala: waiterUser?.database,
        garcomID: waiter,

      }
      await api.post('api/v1.0/manager/setcall', data, {
        params: {
          sala: waiterUser?.database
        },
        headers: {
          'x-access-token': waiterUser?.token,
        }
      })

      getMesas()

    } catch (error) {
      notification.error({
        message: error.message
      })
    }
  }


  return (
    <div style={{ margin: 10, overflowY: 'auto' }}>
      <Flex wrap gap="small" justify='center'>
        {loading === true ? <Flex style={{ minHeight: 300 }} align='center'>
          <LoadingOutlined /> loading...
        </Flex> : <FlexboxGrid>


          {mesas?.filter(value => value.mesacallid > 0)?.map((item, index) => {
            return <FlexboxGrid.Item style={{ padding: 2 }} colspan={24} key={"alert-".concat(item.mesaNumero)}>
              <Alert message={`Mesa ${item.mesaChave} chamando.`}
                action={<Button size='small' type='primary' danger
                  onClick={() => {
                    setcall(item, waiterUser?.funid)
                  }}>Estou indo</Button>} />
            </FlexboxGrid.Item>
          })
          }


          {mesas?.map((item, i) => {
            return <FlexboxGrid.Item style={{ padding: 2 }} colspan={isMobile ? 8 : 4} key={item.mesaNumero}>
              <Button block
                onClick={() => mesaClicked(item)}
                type='primary'
                style={{
                  height: 45,
                  backgroundColor: getColor(item.status)
                }}>
                <div style={{ fontSize: '0.8em' }}>
                  {item.mesaNumero} <br />
                  {item.nomecliente}
                </div>
              </Button>
            </FlexboxGrid.Item>
          })
          }
        </FlexboxGrid>
        }
      </Flex>



    </div>
  )
}
