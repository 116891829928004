import React, { useState, useEffect } from 'react'
import '../../admin.css'
import '../address/address.css'
import {
  Row, Col, Table, Select, Input, App, Typography, Button
} from 'antd';
import '../appBar/appBar.css'
import { EditOutlined, DeleteOutlined, StarFilled, AimOutlined } from '@ant-design/icons'
import { api } from '../../service/api.service'
import axios from 'axios'
import { calcularTaxa } from '../../service/dal.service'
import { useParams } from 'react-router-dom';
import { current_address, lojaState, userState } from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useToasts } from 'react-toast-notifications';
import { useUID, useUIDSeed } from 'react-uid';
import locationPNG from '../../img/ilustracion/location.png'
import { getAuth, signInAnonymously } from "firebase/auth";

import AddressMap from '../addressMap'
import AddressMapPoint from '../addressMapPoint'

const { Column } = Table
const { Option } = Select

export const Address = (props) => {

  const uid = useUID();
  const toash = useToasts()
  const [currentAddress, setCurrentAddress] = useRecoilState(current_address)
  // const [address, salvarAddress] = useRecoilState(all_address)
  const params = useParams()
  const [modoEndereco, setModoEndereco] = useState(0)
  const [cidades, setCidades] = useState([])
  const [bairros, setBairros] = useState([])
  const [enderecoTemp, setEnderecoTemp] = useState({})
  const loja = useRecoilValue(lojaState)

  const [user, saveUser] = useRecoilState(userState)
  const { message } = App.useApp();

  useEffect(() => {
    disableButtonSalvar()
    if (enderecoTemp?.uf === undefined) {
      var temp = { ...enderecoTemp }
      temp.uf = params.uf
      temp.localidade = ''
      temp.bairro = ''
      setEnderecoTemp(temp)
      getCidades(params.uf)
    }
  }, [/*enderecoTemp*/])





  useEffect(() => {
    try {
      var bais = JSON.parse(JSON.stringify(loja?.bairros))
      bais.forEach(element => {
        element.nome = element.descricao
      });
      setBairros(bais)
    } catch (error) { }
  }, [])


  useEffect(() => {
    loaduser()
  }, [])


  async function usarLocalizacao() {
    try {
      if (navigator.geolocation) {
        navigator.permissions.query({ name: "geolocation" })
          .then(function (result) {
            //console.log(result);

            /*
            Usaremos APIs do opencage para obter mais informações sobre o local. 
            Portanto, certifique-se de se inscrever e obter uma chave de API. Você não precisa pagar nada, 
            pois o opencage fornece 2.500 solicitações/dia no teste gratuito. Você pode conferir mais informações 
            sobre o formulário de preços em seu site. Eu usei a API do Opencage,
            */

            if (result.state === "granted") {
              //If granted then you can directly call your function here 
              navigator.geolocation.getCurrentPosition((location) => {
           
                setEnderecoTemp({
                  minhalocalizacao: true,
                  longitude : location.coords.latitude,
                  latitude : location.coords.longitude
                })
                setModoEndereco(1)

              }, (error) => {
                console.log('error:', error);
              }, { enableHighAccuracy: true })
            }
            else if (result.state === "prompt") { //If prompt then the user will be asked to give permission 
              //navigator.geolocation.getCurrentPosition(success, errors, options);

              navigator.geolocation.getCurrentPosition((location) => {
              setEnderecoTemp({
                minhalocalizacao: true,
                longitude : location.coords.latitude,
                latitude : location.coords.longitude
              })
              setModoEndereco(1)

              }, (error) => {
              //  console.log('error:', error);
             //   message.error("Erro ao buscar localização! ")

              }, { enableHighAccuracy: true })

            } else if (result.state === "denied") { //If denied then you have to show instructions to enable location } });
              message.error("Acesso negado pelo seu navegador ao buscar localização! ")
            }

          });
      }
      else { console.log("Geolocation is not supported by this browser."); }
    } catch (error) {

    }
  }



  async function loaduser() {
    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        var user2 = await signInAnonymously(auth)
        user.address = []
        saveUser({ ...user, uid: user2.user.uid })
      }
    } catch (error) {

    }
    // console.log(user)
  }


  async function salvar() {
    try {
      // console.log(user, enderecoTemp)
      if (user?.isAnonymous === false) {
        // console.log('(user?.isAnonymous === false')
        enderecoTemp.uid = user.uid
        enderecoTemp.latitude = enderecoTemp.latitude ? enderecoTemp.latitude : 0;
        enderecoTemp.longitude = enderecoTemp.longitude ? enderecoTemp.longitude : 0;
        const result = await api.post('/api/v2.0/endereco', enderecoTemp)

        if (enderecoTemp.current === 1 || !enderecoTemp.idaddress) {
          var aux = calcularTaxa(enderecoTemp, user?.address, loja)
          setCurrentAddress(aux)
        }

        saveUser({ ...user, address: result.data })
        setModoEndereco(0)
        setEnderecoTemp({})

       

      } else {
        enderecoTemp.current = 1
        var aux = calcularTaxa(enderecoTemp, user?.address, loja)
        saveUser({ ...user, address: [enderecoTemp] })
        setCurrentAddress(aux)
      }

      setModoEndereco(0)
      setEnderecoTemp({})
      props?.showModalEndereco(false)

    } catch (error) {
      message.error(error.message)
    }
  }

  async function salvarCurrent(item) {
    try {
      if (user?.isAnonymous === false) {
        const result = await api.put('/api/v2.0/endereco/current', item)
        saveUser({ ...user, address: result.data })
        setCurrentAddress(item)
      }

    } catch (error) {

    }
  }

  async function excluirAddress(item) {
    try {
      if (user?.isAnonymous === false) {
        const result = await api.put('/api/v2.0/endereco', item)
        saveUser({ ...user, address: result.data })
      }

    } catch (error) {

    }
  }


  function disableButtonSalvar() {
    try {
      // console.log('Endereco: ', enderecoTemp)
      if (!enderecoTemp?.uf)
        return true
      if (!enderecoTemp?.localidade)
        return true
      if (!enderecoTemp?.bairro)
        return true
      if (!enderecoTemp?.logradouro)
        return true
      if (!enderecoTemp?.numero)
        return true
      if (!enderecoTemp?.referencia)
        return true

      return false

    } catch (error) {
      return true
    }
  }


/*
  async function getBairros(value) {
    var temp = { ...enderecoTemp }
    if (value >= 0)
      temp.localidade = cidades[value].nome
    setEnderecoTemp(temp)
    try {
      const resp1 = await api.get('/api/v2.0/bairros/all', {
        params: {
          municipioid: value >= 0 ? cidades[value].id : 0,
          path: params.restaurant
        }
      })
      if (resp1.status === 200) {
        setBairros(resp1.data)
      } else {
        toash.addToast('Não foi possível carregar a lista de bairros!', { appearance: 'warning', autoDismiss: true })
        setBairros([])
      }
    } catch (error) {
      toash.addToast(error.message, { appearance: 'warning', autoDismiss: true })
      setBairros([])
    }
  }
  */

  function bairosDefault() {
    var bais = JSON.parse(JSON.stringify(loja?.bairros))
    bais.forEach(element => {
      element.nome = element.descricao
    });
    return bais
  }


  async function getCidades(value) {
    try {
      var temp = { ...enderecoTemp }
      temp.uf = value
      temp.localidade = loja?.informacoes?.Cidade
      temp.bairro = loja?.informacoes?.Bairro
      setEnderecoTemp(temp)
      setBairros(bairosDefault)
      const res = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'.concat(value, '/municipios'))
      if (res.status === 200) {
        //console.log(res.data)
        setCidades(res.data)
      } else {
        console.log('Erro ao carregar cidades para o estado selecionado! ')
        toash.addToast('Erro ao carregar cidades para o estado selecionado!', { appearance: 'warning', autoDismiss: true })
        setCidades([])
      }
    } catch (error) {
      toash.addToast(error.message, { appearance: 'warning', autoDismiss: true })
      setCidades([])
    }
  }

  function renderModoEndereco() {
    console.log('modoEndereço: ', modoEndereco)
    switch (modoEndereco) {

      case 1:
        return <AddressMap setModoEndereco={setModoEndereco}
          enderecoTemp={enderecoTemp} setEnderecoTemp={setEnderecoTemp} salvar={salvar}/>
        break;

      case 2:
        return <AddressMapPoint setModoEndereco={setModoEndereco}
          enderecoTemp={enderecoTemp} setEnderecoTemp={setEnderecoTemp} salvar={salvar} />
        break

      default:
        return <Row gutter={[16, 16]} style={{ marginTop: 0 }}>
          <Col span={24} style={{ justifyContent: 'center', display: 'flex' }}>
            <img src={locationPNG} width={'80%'} />
          </Col>
          <Col span={24} style={{ justifyContent: 'center', display: 'flex' }}>
            <Typography.Text strong>Onde você quer receber seu pedido?</Typography.Text>
          </Col>

          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button icon={<AimOutlined />} block
              style={{ width: '80%', maxWidth: 500 }}
              onClick={() => { usarLocalizacao() }}
            >Usar minha localização</Button>
          </Col>

          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Input.Search
              style={{ width: '80%', maxWidth: 500 }}
              onSearch={() => {
                var ed = {}
                setEnderecoTemp(ed)
                setModoEndereco(1)
              }}
              placeholder="Buscar endereço e número"
              onFocus={() => {
                var ed = {}
                setEnderecoTemp(ed)
                setModoEndereco(1)
              }}>
            </Input.Search>
          </Col>

          <Col span={24}>
            {
              user?.address?.length > 0 && <Table
                dataSource={user?.address}
                size="small"
                pagination={false}
                showHeader={false}>
                <Column render={(text, record, index) => {
                  return <StarFilled
                    onClick={() => {
                      salvarCurrent(record)
                    }}
                    style={{
                      color: record.current ? 'yellow' : 'whitesmoke', fontSize: 25,
                      cursor: 'pointer'
                    }} />
                }} />
                <Column dataIndex="logradouro" key="logradouro"
                  render={(text, record) => {
                    return <Row style={{ fontSize: 16 }}>
                      <Col span={24} >
                        {record?.logradouro}{' '}{record?.numero}
                      </Col>
                      <Col span={24}>
                        {record?.bairro}{' - '}{record?.localidade}
                      </Col>
                    </Row>
                  }} />

                <Column dataIndex="ibge" key="ibge" width={80}
                  render={(text, record, index) => {
                    return <div>
                      <EditOutlined style={{ fontSize: 20, marginRight: 20 }}
                        onClick={() => {
                          //   console.log(record)
                          setEnderecoTemp(record)
                          setModoEndereco(1)
                          //  getBairros(-1)

                        }} />
                      <DeleteOutlined style={{ fontSize: 20 }}
                        onClick={() => {
                          if (record.current === 1) {
                            message.error('Não é possível remover o endereço atual')

                          } else {
                            excluirAddress(record)
                          }
                        }} />

                    </div>
                  }} />
              </Table>
            }

          </Col>

        </Row>
    }

  }

  return <div style={{ width: '100%' }}>
    {renderModoEndereco()}
  </div>

}

export default Address