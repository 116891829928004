import React, { useEffect, useState } from 'react'
import {
    Table, Message, Modal, Divider, FlexboxGrid,
    Col
} from 'rsuite';
import { DatePicker, Button, Space, App, Flex, Tag } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import { ArrowDown, ArrowUp, EventDetail } from '@rsuite/icons'
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';

const { Column, HeaderCell, Cell } = Table

export const ClientesMelhores = (props) => {
    const { message, notification, modal } = App.useApp();

    const waiterUser = useRecoilValue(waiterUserState)
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        pesquisar()
    }, [])


    async function pesquisar() {
        try {
            setLoading(true)
            socket.emit('clientesMelhores', waiterUser?.database, (value) => {
                if (value.status === 200)
                    setData(value.data)
                else
                    setData([])
                setLoading(false)
            });

        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }







    return (<main>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: 10 }}>
                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}
                <Table
                    loading={loading}
                    autoHeight={true}
                    hover
                    style={{ fontSize: '0.8em' }}
                    height={600}
                    rowHeight={30}
                    headerHeight={25}
                    data={data}
                    onRowClick={rowData => {
                        // console.log(rowData);
                    }}>
                    <Column flexGrow={1}>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CLIENTE</HeaderCell>
                        <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                            {rowData => {
                                return rowData.cliNome
                            }}
                        </Cell>
                    </Column>
                    <Column width={90} align="center" fixed>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>TELEFONE</HeaderCell>
                        <Cell dataKey="Caixa" style={{ padding: 3 }}>
                            {rowData => {
                                return rowData.cliTelefone
                            }}
                        </Cell>
                    </Column>
                    <Column width={100} align='center' fixed>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>ÚLTIMO PEDIDO</HeaderCell>
                        <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                            {rowData => {
                                return dayjs(rowData.DT_ABERTO).format('DD/MM/YYYY')
                            }}
                        </Cell>
                    </Column>
                    <Column width={80} align="center" fixed>
                        <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>PEDIDOS</HeaderCell>
                        <Cell dataKey="Caixa" style={{ padding: 3 }}>
                            {rowData => {
                                return rowData.total
                            }}
                        </Cell>
                    </Column>
                </Table>
            </div>
        </div>
    </main>
    )
}
