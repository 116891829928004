import React, { useState, useEffect, useRef } from 'react'
import '../../admin.css'
import '../address/address.css'
import './addressMap.css'
import {
    Row, Col, Button, Select, Input, App, Checkbox
} from 'antd';
import '../appBar/appBar.css'
import { api, GOOGLE_KEY } from '../../service/api.service'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { lojaState, userState } from '../../store/dataRecoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import { getAuth, signInAnonymously } from "firebase/auth";
import { StandaloneSearchBox, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';
import { LoadingOutlined } from '@ant-design/icons'
//import Autocomplete from "react-google-autocomplete";
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


const { Option } = Select

export const AddressMap = (props) => {

    const [salvando, setSalvando] = useState(false)
    const loja = useRecoilValue(lojaState)
    const inputRef = useRef();
    const [bairros, setBairros] = useState([])
    const [cidades, setCidades] = useState([])
    const params = useParams()
    const [user, saveUser] = useRecoilState(userState)
    const { message } = App.useApp();
    const [libraries] = useState(['places']);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_KEY,
        libraries: libraries,
    });
    const [bairroAtendido, setBairroAtendido] = useState(true)

    useEffect(() => {
        disableButtonSalvar()
        if (props.enderecoTemp?.uf === undefined) {
            var temp = { ...props.enderecoTemp }
            temp.uf = params.uf
            temp.localidade = ''
            temp.bairro = ''
            props.setEnderecoTemp(temp)
            getCidades(params.uf)
        }


    }, [/*props.enderecoTemp*/])



    async function salvar() {
        try {
            setSalvando(true)
            await props.salvar()
            // props.setModoEndereco(2)


        } catch (error) {
            message.error(error.message)
        }
        // setSalvando(false)
    }


    function checkBairroAtendido(bairro) {
        let ate = false
        let _bairro = bairro ? bairro : props.enderecoTemp.bairro
        try {
            const itens = bairros.filter(v => v.nome.toUpperCase().trim() === _bairro.toUpperCase().trim())
            // console.log('itens:', itens)
            if (itens)
                if (itens.length > 0) {
                    setBairroAtendido(true)
                    ate = true
                }
                else
                    setBairroAtendido(false)
            else
                setBairroAtendido(false)
        } catch (error) {
            setBairroAtendido(false)

        }
        return ate
    }


    useEffect(() => {
        checkBairroAtendido()
    }, [])



    useEffect(() => {
        try {
            // console.log(loja)
            var bais = JSON.parse(JSON.stringify(loja?.bairros))
            bais.forEach(element => {
                element.nome = element.descricao
            });
            setBairros(bais)
        } catch (error) { }
    }, [])


    useEffect(() => {
        loaduser()
        // console.log('loja?.informacoes?.taxtype: ',loja?.informacoes?.taxtype)
    }, [])

    async function loaduser() {
        try {
            const auth = getAuth();
            if (!auth.currentUser) {
                var user2 = await signInAnonymously(auth)
                user.address = []
                saveUser({ ...user, uid: user2.user.uid })
            }
        } catch (error) {

        }
        // console.log(user)
    }


    async function getBairros(value) {
        var temp = { ...props.enderecoTemp }
        if (value >= 0)
            temp.localidade = cidades[value].nome
        props.setEnderecoTemp(temp)
        try {
            const resp1 = await api.get('/api/v2.0/bairros/all', {
                params: {
                    municipioid: value >= 0 ? cidades[value].id : 0,
                    path: params.restaurant
                }
            })
            if (resp1.status === 200) {
                setBairros(resp1.data)
            } else {
                message.error('Não foi possível carregar a lista de bairros!')
                setBairros([])
            }
        } catch (error) {
            message.error(error.message)
            setBairros([])
        }
    }

    function bairosDefault() {
        var bais = JSON.parse(JSON.stringify(loja?.bairros))
        bais.forEach(element => {
            element.nome = element.descricao
        });
        return bais
    }

    async function getCidades(value) {
        try {
            var temp = { ...props.enderecoTemp }
            temp.uf = value
            temp.localidade = loja?.informacoes?.Cidade
            temp.bairro = loja?.informacoes?.Bairro
            props.setEnderecoTemp(temp)
            setBairros(bairosDefault)
            const res = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'.concat(value, '/municipios'))
            if (res.status === 200) {
                //console.log(res.data)
                setCidades(res.data)
            } else {
                console.log('Erro ao carregar cidades para o estado selecionado! ')
                message.error('Erro ao carregar cidades para o estado selecionado!')
                setCidades([])
            }
        } catch (error) {
            message.error(error.message)
            setCidades([])
        }
    }

    /*
        const handlePlaceChanged = () => {
    
            var end = {}
            const [place] = inputRef.current.getPlaces();
             console.log('place:' , place)
            if (place) {
                place.address_components.forEach(element => {
                    if (element.types.find(e => 'route' === e)) { end.logradouro = element.long_name }
                    else if (element.types.find(e => 'sublocality_level_1' === e || 'sublocality' === e)) {
                        if (loja?.informacoes?.taxtype === 1)
                            end.bairro = element.long_name
                        else {
                            if (checkBairroAtendido(element.long_name) === true)
                                end.bairro = element.long_name
                            else
                                end.bairro = ""
                        }
                    }
                    else if (element.types.find(e => 'administrative_area_level_2' === e)) { end.localidade = element.long_name }
                    else if (element.types.find(e => 'administrative_area_level_1' === e)) { end.uf = element.short_name }
                    else if (element.types.find(e => 'country' === e)) { end.pais = element.long_name }
                    else if (element.types.find(e => 'postal_code' === e)) { end.cep = element.long_name }
                    else if (element.types.find(e => 'street_number' === e)) { end.numero = element.long_name }
                });
                const lat = place.geometry.location.lat()
                const lng = place.geometry.location.lng()
                end.longitude = lng
                end.latitude = lat
                // console.log(place.address_components)
                props.setEnderecoTemp(end)
                // var distanceInKm = distance(loja?.informacoes?.latitude, loja?.informacoes?.longitude, lat, lng)
                // console.log(`A distância entre os pontos é de ${distanceInKm} km.`);
            } else {
                props.setEnderecoTemp(undefined)
            }
            checkBairroAtendido()
        }
        */


    function disableButtonSalvar() {
        try {
            // console.log('Endereco: ', props.enderecoTemp)
            if (!props.enderecoTemp?.uf)
                return true
            if (!props.enderecoTemp?.localidade)
                return true
            if (!props.enderecoTemp?.bairro)
                return true
            if (!props.enderecoTemp?.logradouro)
                return true
            if (!props.enderecoTemp?.numero)
                return true
            if (!props.enderecoTemp?.referencia)
                return true

            return false

        } catch (error) {
            return true
        }
    }



    async function buscar() {
        try {

            var aux = props.enderecoTemp?.logradouro.concat(props.enderecoTemp?.numero === 's/n' ? ' ' : props.enderecoTemp?.numero,
                ", ", props.enderecoTemp?.bairro, ",", props.enderecoTemp?.localidade, '-', props.enderecoTemp?.uf)
            var url = "https://maps.googleapis.com/maps/api/geocode/json?address=".concat(aux, "&key=", GOOGLE_KEY)
            const response = await axios.get(url);
            const data = response.data
            switch (data.status) {
                case "OK":
                    var ed = { ...props.enderecoTemp }
                    ed.latitude = data.results[0].geometry.location.lat
                    ed.longitude = data.results[0].geometry.location.lng
                    props.setEnderecoTemp(ed)
                    props.setModoEndereco(2)
                    break;
                case "ZERO_RESULTS": message.warning("Não foram localizados endereços para os dados informados!"); break;
                case "OVER_QUERY_LIMIT": message.warning("Desculpe, o serviço não está conseguindo localizar os dados!"); break;
                case "REQUEST_DENIED": message.warning("A solicitação foi negada pelo serviço do GOOGLE, verifique se os dados estão corretos e tente novamente!"); break;
                case "INVALID_REQUEST": message.warning("Erro ao efetuar solicitação, verifique se os dados do endereço estão corretos!"); break;
                case "UNKNOWN_ERROR": message.warning("Solicitação não foi processada devido a um erro de servidor. Tente novamente"); break;
                case "ERROR": message.error("Solicitação expirou ou houve um problema de contato com os servidores do Google, tente novamente em instantes!"); break;
                default: message.error("Erro desconhecido, tente novamente em instantes! ");
            }



        } catch (error) {
            console.log()
            message.error(error.message)
        }
    }

    const [searchResult, setSearchResult] = useState("Result: none");
    function onLoad(autocomplete) {
        console.log('onload Autocomplete google')
        setSearchResult(autocomplete);
    }

    function onPlaceChanged() {
        if (searchResult != null) {
            var end = {}
            const place = searchResult.getPlace();
            console.log(place)
            if (place) {
                place.address_components.forEach(element => {
                    if (element.types.find(e => 'route' === e)) { end.logradouro = element.long_name }
                    else if (element.types.find(e => 'sublocality_level_1' === e || 'sublocality' === e)) {
                        if (loja?.informacoes?.taxtype === 1)
                            end.bairro = element.long_name
                        else {
                            if (checkBairroAtendido(element.long_name) === true)
                                end.bairro = element.long_name
                            else
                                end.bairro = ""
                        }
                    }
                    else if (element.types.find(e => 'administrative_area_level_2' === e)) { end.localidade = element.long_name }
                    else if (element.types.find(e => 'administrative_area_level_1' === e)) { end.uf = element.short_name }
                    else if (element.types.find(e => 'country' === e)) { end.pais = element.long_name }
                    else if (element.types.find(e => 'postal_code' === e)) { end.cep = element.long_name }
                    else if (element.types.find(e => 'street_number' === e)) { end.numero = element.long_name }
                });
                const lat = 0//place.geometry.location.lat()
                const lng = 0//place.geometry.location.lng()
                end.longitude = lng
                end.latitude = lat
                end.minhalocalizacao = false
                // console.log(place.address_components)
                props.setEnderecoTemp(end)
                // var distanceInKm = distance(loja?.informacoes?.latitude, loja?.informacoes?.longitude, lat, lng)
                // console.log(`A distância entre os pontos é de ${distanceInKm} km.`);
            } else {
                props.setEnderecoTemp(undefined)
            }
            checkBairroAtendido()
        }
    }


    return <div style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: 400

    }}>

        <div style={{ width: isMobile ? '80%' : '100%' }}>

            {/*isLoaded ? <StandaloneSearchBox

                style={{ width: '100%', flexGrow: 2 }}
                onLoad={ref => inputRef.current = ref}
                debouce={800}
                fields={['address_components']}
                restrictions={{
                    country: ['br'],
                    fields:['address_components']
                }}
                options={{
                    restrictions:{
                        country: ['br'],
                        fields:['address_components']
                    },
                    fields:['address_components']
                }}
                onPlacesChanged={handlePlaceChanged}>
                <input
                    style={{ width: isMobile ? '100%' : '100%', padding: 5 }}
                    type="text"
                    className={"input-searching"}
                    placeholder="Informe o endereço"
                    onChange={(e) => {
                        props.setEnderecoTemp(undefined)
                    }}
                />
            </StandaloneSearchBox> : <i><LoadingOutlined /> carregando...</i>
                */ }

            


            {props.enderecoTemp?.minhalocalizacao !== true && (isLoaded ? <Autocomplete
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
                debouce={1300}
                key={GOOGLE_KEY}
                fields={['address_components']}
                options={{
                    componentRestrictions: {
                        country: 'br'
                    }
                }}
            >
                <Input
                    placeholder="Informe o endereço" />
            </Autocomplete> : <i><LoadingOutlined /> carregando...</i>)
            }





        </div>
        {
            (props.enderecoTemp?.logradouro || props.enderecoTemp?.minhalocalizacao == true) &&
            <div style={{ width: isMobile ? '80%' : '100%' }}>
                <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <Select
                            placeholder="Estado"
                            defaultValue={params?.uf}
                            value={props.enderecoTemp.uf}
                            style={{ width: '100%' }}
                            onChange={(value) => {
                                getCidades(value)
                            }}
                        >
                            <Option value="AC">Acre</Option>
                            <Option value="AL" >Alagoas</Option>
                            <Option value="AM" >Amazonas</Option>
                            <Option value="AP" >Amapa</Option>
                            <Option value="BA" >Bahia</Option>
                            <Option value="CE" >Ceará</Option>
                            <Option value="DF" >Distrito Federal</Option>
                            <Option value="ES" >Espirito Santo</Option>
                            <Option value="GO" >Goias</Option>
                            <Option value="MA" >Maranhão</Option>
                            <Option value="MG" >Minhas Gerais</Option>
                            <Option value="MS" >Mato Grosso do Sul</Option>
                            <Option value="MT" >Mato Grosso</Option>
                            <Option value="PA" >Pará</Option>
                            <Option value="PB" >Paraíba</Option>
                            <Option value="PE" >Pernambuco</Option>
                            <Option value="PI" >Piauí</Option>
                            <Option value="PR" >Paraná</Option>
                            <Option value="RJ" >Rio de Janeiro</Option>
                            <Option value="RN" >Rio Grande do Norte</Option>
                            <Option value="RS" >Rio Grande do Sul</Option>
                            <Option value="RO" >Roraima</Option>
                            <Option value="RR" >Rondônia</Option>
                            <Option value="SC" >Santa Catarina</Option>
                            <Option value="SP" >São Paulo</Option>
                            <Option value="SE" >Sergipe</Option>
                            <Option value="TO" >Tocantins</Option>
                        </Select>
                    </Col>
                    <Col span={24}>
                        {
                            <Select
                                placeholder="Cidade"
                                defaultValue={loja?.informacoes?.Cidade}
                                value={props.enderecoTemp.localidade}
                                style={{ width: '100%' }}
                                onChange={(value) => {
                                    getBairros(value)
                                }}>
                                {
                                    cidades.map((item, index) => {
                                        return <Option key={item.id} value={index}>{item.nome}</Option>
                                    })
                                }
                            </Select>
                        }

                    </Col>
                    <Col span={24}>
                        {loja?.informacoes?.taxtype === 1 ?
                            <Input placeholder="Bairro*" value={props.enderecoTemp.bairro}
                                onChange={(e) => props.setEnderecoTemp({ ...props.enderecoTemp, bairro: e.target.value })} />
                            : <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Select placeholder="Bairro"
                                    value={props.enderecoTemp.bairro}
                                    onChange={(value) => {
                                        props.setEnderecoTemp({ ...props.enderecoTemp, bairro: value })
                                        // checkBairroAtendido()
                                        setBairroAtendido(true)
                                    }}
                                    style={{ width: '100%' }}>
                                    {
                                        bairros.map((item, index) => {
                                            return <Option key={index} value={item.nome}>{item.nome}</Option>
                                        })
                                    }
                                </Select>
                                {
                                    bairroAtendido === false && <i style={{ color: 'red', fontSize: 11 }}>Bairro não atendido!</i>
                                }
                            </div>
                        }
                    </Col>
                    <Col span={12}><Input placeholder="Rua*" value={props.enderecoTemp.logradouro}
                        onChange={(e) => props.setEnderecoTemp({ ...props.enderecoTemp, logradouro: e.target.value })} /></Col>
                    <Col span={12}>
                        <Input placeholder="Número*"
                            disabled={props.enderecoTemp?.numero === 's/n'}
                            value={props.enderecoTemp.numero}
                            onChange={(e) => props.setEnderecoTemp({ ...props.enderecoTemp, numero: e.target.value })} /></Col>
                    <Col span={12}><Input placeholder="Complemento" value={props.enderecoTemp.complemento}
                        onChange={(e) => props.setEnderecoTemp({ ...props.enderecoTemp, complemento: e.target.value })} /></Col>
                    <Col span={12}><Input placeholder="Referência*" value={props.enderecoTemp.referencia}
                        onChange={(e) => props.setEnderecoTemp({ ...props.enderecoTemp, referencia: e.target.value })} /></Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Checkbox checked={props.enderecoTemp?.numero === 's/n'}
                            onChange={(e) => {
                                console.log(e)
                                if (e.target.checked)
                                    props.setEnderecoTemp({ ...props.enderecoTemp, numero: 's/n' })
                                else
                                    props.setEnderecoTemp({ ...props.enderecoTemp, numero: '' })
                            }}>
                            Endereço sem número
                        </Checkbox>
                    </Col>

                    <Col span={24}>
                        <div className="address--buttons">
                            <div className="address--buttons-item">
                                <Button type="dashed" danger onClick={() => {
                                    //  console.log(props)
                                    props.setModoEndereco(0)

                                }}>Voltar</Button>
                            </div>
                            <div className="address--buttons-item">
                                <Button type="primary" style={{ minWidth: 100 }}
                                    loading={salvando}
                                    disabled={disableButtonSalvar()}
                                    onClick={() => {
                                        if (loja?.informacoes?.taxtype === 1)
                                            props?.enderecoTemp?.minhalocalizacao === true ? salvar() : buscar()
                                        else {
                                            if (checkBairroAtendido() === true)
                                                props?.enderecoTemp?.minhalocalizacao === true ? salvar() : buscar()
                                            else
                                                message.error("O bairro não é atendido pelo restaurante!")
                                        }

                                    }}
                                >{props?.enderecoTemp?.minhalocalizacao === true ? 'Salvar' : 'Buscar'}</Button>
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        }

    </div>

}

export default AddressMap