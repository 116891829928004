import axios from 'axios';


const baseUrl = 'https://menuengfood.com.br/';
//const baseUrl = 'http://192.168.1.12:80/';
export const api = axios.create({ baseURL: baseUrl });
//export default api;


const baseUrlViaCep = 'https://viacep.com.br/ws/';
export const apiviacep = axios.create({ baseURL: baseUrlViaCep });
//export default viacep;


//PAGAR.ME export const ENCRYPT_KEY =   "ek_test_kSsao2ZIDvQwezLlAAJOYda7RGKJqY"; 
export const ENCRYPT_KEY = "ek_live_CLweNIoV6kVsfsmxjZ5VucBXi1e0Iz";

//MERCADOPAGO
export const MP_PUBLIC_KEY = 'APP_USR-928324a9-5946-4b16-8e00-25d1c1956546'

export const BASEURL = baseUrl

//GOOGLE API
export const GOOGLE_KEY = "AIzaSyDaCMlM289_X30c4PfJR8DPyYXf9og3B80"

export const loadHistoricoFidelidade = async (uid, path) => {
    try {
       // console.log("loadHistoricoFidelidade")
        const response = await api.get('getfidelidadehistorico?uid='
            .concat(uid, '&path=', path));
        return response.data
    } catch (error) {
        console.log(error.message)
        return undefined
    }
}