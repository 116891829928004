import React, { useRef, useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print';
import { QRCodeCtrl } from '../../../component/qrCodeCtrl'

import { Typography, App, QRCode, Button, Flex, Switch } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil'
import { waiterUserState } from '../../../store/dataRecoil'
import { api, BASEURL } from '../../../service/api.service'
import { isMobile } from 'react-device-detect';

export const QRCodeMesas = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [mesas, setMesas] = useState([])
    const { notification } = App.useApp()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [loading, setLoading] = useState(false)

    const baseurl = BASEURL.concat("order/")

    useEffect(() => {
        getMesas()
        return () => { }
    }, [])

    async function getMesas() {
        try {
            console.log('load mesas')
            setLoading(true)
            const result = await api.get('api/v1.0/manager/qrcodes', {
                params: {
                    sala: waiterUser?.database
                },
                headers: {
                    'x-access-token': waiterUser?.token,
                }
            })
            setMesas(result.data)
            //console.log(result.data)
        } catch (error) {
            console.log('waiterUser?.database', waiterUser?.database)
        }
        setLoading(false)
    }


    async function update(item) {
        try {
            const result = await api.put('api/v1.0/manager/qrcode', item, {
                params: {
                    sala: waiterUser?.database
                },
                headers: {
                    'x-access-token': waiterUser?.token,
                }
            })
        } catch (error) {
            getMesas()
        }

    }




    function doDownload(url, fileName) {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const downloadCanvasQRCode = (myqrcode, mesaid) => {
        // console.log(myqrcode, mesaid)
        const canvas = document.getElementById(myqrcode)?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            doDownload(url, 'QRCode'.concat(mesaid, '.png'));
        }
    };

    const downloadSvgQRCode = (myqrcode, mesaid) => {
        const svg = document.getElementById(myqrcode)?.querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svg);
        const blob = new Blob([svgData], {
            type: 'image/svg+xml;charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        doDownload(url, 'QRCode Mesa '.concat(mesaid, '.svg'));
    };



    return (<div style={{ margin: 20, overflowY: 'auto' }}>
        <Flex justify="center" gap={3} vertical>
            <div >
                <b>QRCode de autoatendimento para Impressão:</b> coloque os qrcodes correspondente a cada mesa para permitir que o cliente possa efetuar o pedido utilizando o seu próprio smartphone.
            </div>
            {
                loading === true ? <div> loading.... </div>
                    :
                    <Flex vertical={isMobile}
                        gap={5}
                        wrap
                        style={{ overflowY: 'auto' }}>
                        {
                            mesas && mesas.map((item, index) => {

                                return <Flex
                                    id={item.url}
                                    gap={5}
                                    vertical
                                    align='center'
                                    key={'mesa-'.concat(index, item.url)}
                                    style={{ marginBottom: 30, padding: 10 }}>
                                    <Switch
                                        unCheckedChildren="Inativo"
                                        checkedChildren="Ativo"
                                        checked={item.ativo === 1}
                                        onChange={(e) => {
                                            let _mesas = JSON.parse(JSON.stringify(mesas))
                                            let aux = JSON.parse(JSON.stringify(item))
                                            aux.ativo = aux.ativo === 1 ? 0 : 1
                                            _mesas[index] = aux
                                            update(aux)
                                            setMesas(_mesas)

                                        }} />
                                    <QRCode
                                        type='svg'
                                        value={''.concat(baseurl, item.url)}
                                        bordered />

                                    <Button size='small'
                                        disabled={item.ativo === 0}
                                        onClick={() => downloadSvgQRCode(item.url, item.mesaid)}>
                                        download mesa {item.mesaid}
                                    </Button>
                                </Flex>
                            })
                        }
                    </Flex>
            }
        </Flex>
    </div>
    )
}


