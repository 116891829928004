import React from 'react'
import { Col, Row, Divider, Button } from 'antd'
import './footer.css'
import { InstagramOutlined, FacebookOutlined, GlobalOutlined } from '@ant-design/icons'
import days from 'dayjs'
import packageJson from '../../../package.json';




export const Footer = (props) => {
    return <div className="home-footer">
        <Row >
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <div className="home-footer-itens">
                    <div className="home-footer-item">
                        <b style={{ fontSize: 16 }}> Nossos Canais </b>
                    </div>
                    <div className="home-footer-item">
                        <a href="https://www.instagram.com/menuengfood/" target="_blank">
                            <InstagramOutlined style={{ fontSize: 25 }} /></a>
                        {
                            /*
                                <a href="https://pt-br.facebook.com/pg/programaengfood/posts/" target="_blank">
                                    <FacebookOutlined style={{ fontSize: 25, marginLeft: 15, marginRight: 15 }} />
                                </a>
                            */
                        }
                        <a href="https://menuengfood.com.br/" target="_blank">
                            <GlobalOutlined style={{ fontSize: 25 }} />
                        </a>
                    </div>

                </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <div className="home-footer-itens">
                    <div className="home-footer-item">
                        <b style={{ fontSize: 16 }}> Negócios </b>
                    </div>
                    <div className="home-footer-item">
                        Cadastre seu restaurante no Menuengfood e leve o Menuengfood para sua cidade.
                    </div>
                    <div className="home-footer-item">
                        <a href="https://portal.menuengfood.com.br/register" target="_blank">
                            Criar conta
                        </a>
                    </div>

                </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <div className="home-footer-itens">
                    <div className="home-footer-item">
                        <b style={{ fontSize: 16 }}> Confere aí </b>
                    </div>
                    <div className="home-footer-item">
                        <Button type="link" href="/termos" target="_blank">Termos de uso</Button>
                    </div>

                </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <div className="home-footer-itens">
                    <div className="home-footer-item">

                    </div>
                    <div className="home-footer-item-2">
                        Desenvolvido por <br />
                        <b>menuengfood</b>
                    </div>

                </div>
            </Col>
            <Col xs={{ span: 24 }} >
                <Divider />
            </Col>
            <Col xs={{ span: 24 }}>
                <div className="home-footer-item" style={{ textAlign: 'center' }}>

                    © Copyright {days().format('YYYY')} - Menuengfood
                    Todos os direitos reservados. <br />

                    Versão {packageJson.version}

                    <br /><br /> <br />

                </div>
            </Col>
        </Row>
    </div>
}