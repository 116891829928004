import React, { useState, useEffect } from 'react'
import {
    Table, Message, FlexboxGrid
} from 'rsuite';
import { DatePicker, Button, Space, ConfigProvider, Input } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import { SearchOutlined } from '@ant-design/icons'
import { ArrowDown, ArrowUp } from '@rsuite/icons'
import ptBR from "antd/es/locale/pt_BR";
import { isMobile } from 'react-device-detect';
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'

const { RangePicker } = DatePicker;
const { Column, HeaderCell, Cell } = Table

export const ProdutosMovimentos = (props) => {

    const waiterUser = useRecoilValue(waiterUserState)
    const [dates, setDates] = useState([dayjs().add(-15, 'days'), dayjs()])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [searching, setSearching] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        pesquisar()
    }, [])


    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return false//current && current > dayjs().endOf('day')
    };



    async function pesquisar() {
        try {
            setStatus(0)
            setLoading(true)
            socket.emit('produtosMovimentos', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            },
                (value) => {
                    if (value.status === 200)
                        setData(value.data)
                    else
                        setData([])
                    setLoading(false)
                });

        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }




    return (<main>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input placeholder='Nome do produto' size='small' width={300} style={{ width: 300 }}
                    prefix={<SearchOutlined />}
                    value={searching}
                    onChange={(e) => setSearching(e.target.value)}
                />
                <Space>
                    <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>
                    <ConfigProvider locale={ptBR}>
                        <RangePicker
                            locale={ptBR}
                            size='small'
                            allowClear={false}
                            format={'DD-MM-YYYY'}
                            value={dates || null}
                            disabledDate={disabledDate}
                            onChange={(val) => {
                                setDates(val);
                            }}

                            changeOnBlur />
                    </ConfigProvider>
                    <Button size='small' type='primary' style={{ marginLeft: 5 }}
                        onClick={() => pesquisar()}>Pesquisar</Button>
                </Space>

            </div>
            <div style={{ marginTop: 10 }}>
                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}
                <Table
                loading={loading}
                    autoHeight={true}
                    hover
                    style={{ fontSize: '0.8em' }}
                    rowHeight={isMobile ? 43 : 30}
                    headerHeight={30}
                    showHeader={!isMobile}
                    data={data.filter((item) =>
                        item?.produtoDescReduzida?.toLowerCase().includes(searching.toLowerCase()))
                    }
                    onRowClick={rowData => {
                        // pesquisar()
                    }}>
                    {isMobile ? <>
                        <Column fixed flexGrow={1}>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CATEGORIA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return <FlexboxGrid>
                                        <FlexboxGrid.Item colspan={24}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <span>  {rowData.menuDescricao} - {rowData.produtoDescReduzida} </span>
                                                {dayjs("".concat(rowData.datahora).substring(0, 10)).format('DD/MM/YYYY')}
                                                {" ".concat(rowData.datahora.substring(11, 19))}
                                            </div>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={24}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                {rowData.operacao === 0 ? <div >
                                                    <ArrowDown style={{ color: 'red', fontSize: 20 }} /> Saída
                                                </div>
                                                    : <div >
                                                        <ArrowUp style={{ color: 'green', fontSize: 20 }} /> Entrada
                                                    </div>}
                                                <Space direction='horizontal'>
                                                    {"Est. anterior: ".concat(rowData.estoqueanterior.toFixed(3))}
                                                    {rowData.quantidade}
                                                    {"Estoque atual: ".concat(rowData.estoquefinal.toFixed(3))}
                                                </Space>
                                            </div>
                                        </FlexboxGrid.Item>

                                    </FlexboxGrid>

                                }}
                            </Cell>
                        </Column>
                    </> : <>
                        <Column fixed flexGrow={1}>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>PRODUTO</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    // console.log(rowData)
                                    return rowData.produtoDescReduzida
                                }}
                            </Cell>
                        </Column>
                        <Column fixed width={160}>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>CATEGORIA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.menuDescricao

                                }}
                            </Cell>
                        </Column>

                        <Column fixed width={80}>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>E/S</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.operacao === 0 ? <div >
                                        <ArrowDown style={{ color: 'red', fontSize: 20 }} /> Saída
                                    </div>
                                        : <div >
                                            <ArrowUp style={{ color: 'green', fontSize: 20 }} /> Entrada
                                        </div>

                                }}
                            </Cell>
                        </Column>
                        <Column fixed width={80} align='center'>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>EST. ANTES</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.estoqueanterior.toFixed(3)

                                }}
                            </Cell>
                        </Column>
                        <Column align="center" width={70} fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>QTDE</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.quantidade


                                }}
                            </Cell>
                        </Column>
                        <Column width={90} align="left" fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>EST. FINAL</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.estoquefinal.toFixed(3)
                                }}
                            </Cell>
                        </Column>

                        <Column align="center" width={130} fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>DATA</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return <div> {dayjs("".concat(rowData.datahora).substring(0, 10)).format('DD/MM/YYYY')}
                                        {" ".concat(rowData.datahora.substring(11, 19))}
                                    </div>

                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={1} fixed>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>DESCRIÇÃO</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.observacoes

                                }}
                            </Cell>
                        </Column>
                    </>}

                </Table>
            </div>
        </div>
    </main>
    )
}
