import React, { useState, useEffect } from 'react'
import {
    Table, Message, Divider, FlexboxGrid, Badge
} from 'rsuite';
import { DatePicker, Button, Space, Card, App, ConfigProvider } from 'antd';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import ptBR from "antd/es/locale/pt_BR";
import { isMobile } from 'react-device-detect';
import { socket } from '../../../service/socket'
import { waiterUserState } from '../../../store/dataRecoil'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Column, HeaderCell, Cell } = Table

export const VendasMovimentos = (props) => {

    const navigate = useNavigate()
    const waiterUser = useRecoilValue(waiterUserState)
    const [dates, setDates] = useState([dayjs().add(-15, 'days'), dayjs()])
    const [data, setData] = useState([])
    const [status, setStatus] = useState(0)
    const [totais, setTotais] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        pesquisar()
    }, [])


    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        return false//current && current > dayjs().endOf('day')
    };



    async function pesquisar() {
        try {
            setLoading(true)
            setStatus(0)
            socket.emit('movimentos', waiterUser?.database, {
                data1: dates[0], data2: dates[1]
            }, (result) => {
                var mesat = 0
                var mesac = 0
                var comandat = 0
                var comandac = 0
                var balcaot = 0
                var balcaoc = 0
                var deliveryt = 0
                var deliveryc = 0

                result.data.map(item => {
                    switch (item.Nome) {
                        case 'Mesas': mesat = mesat + parseFloat(item.Total)
                            mesac++
                            break;
                        case 'Comandas': comandat = comandat + parseFloat(item.Total)
                            comandac++
                            break;
                        case 'Entregas': deliveryt = deliveryt + parseFloat(item.Total)
                            deliveryc++
                            break;
                        default: balcaot = balcaot + parseFloat(item.Total)
                            balcaoc++
                            break;
                    }
                    return item
                })
                setTotais({
                    mesac,
                    mesat,
                    comandac,
                    comandat,
                    balcaoc,
                    balcaot,
                    deliveryc,
                    deliveryt
                })
                setData(result.data)
                setLoading(false)
            })

        } catch (error) {
            setStatus(-1)
            setLoading(false)
        }
    }


    function cor(tipo) {
        switch (tipo) {
            case 'Mesas':
                return 'green'
            case 'Comandas':
                return 'red'
            case 'Entregas':
                return 'blue'
            default:
                return 'orange'
        }
    }


    return (<main>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Space direction='horizontal' size={'small'}>
                    <Card size='small' style={{ padding: 0, paddingLeft: 0 }}>
                        <div style={{ minWidth: 65, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: '1.1em' }}>R${totais?.mesat?.toFixed(2)}</div>
                            <div style={{ color: 'green', fontWeight: 'bold' }}>Mesas</div>
                            <div style={{ fontSize: '0.7em', color: 'darkgray' }}>Transações {totais?.mesac}</div>
                        </div>
                    </Card>

                    <Card size='small' style={{ padding: 0, paddingLeft: 0 }}>
                        <div style={{ minWidth: 65, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: '1.1em' }}>R${totais?.balcaot?.toFixed(2)}</div>
                            <div style={{ color: 'orange', fontWeight: 'bold' }}>Balcão</div>
                            <div style={{ fontSize: '0.7em', color: 'darkgray' }}>Transações {totais?.balcaoc}</div>
                        </div>
                    </Card>

                    <Card size='small' style={{ padding: 0, paddingLeft: 0 }}>
                        <div style={{ minWidth: 65, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: '1.1em' }}>R${totais?.deliveryt?.toFixed(2)}</div>
                            <div style={{ color: 'blue', fontWeight: 'bold' }}>Delivery</div>
                            <div style={{ fontSize: '0.7em', color: 'darkgray' }}>Transações {totais?.deliveryc}</div>
                        </div>
                    </Card>

                    <Card size='small' style={{ padding: 0, paddingLeft: 0 }}>
                        <div style={{ minWidth: 65, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ fontSize: '1.1em' }}>R${totais?.comandat?.toFixed(2)}</div>
                            <div style={{ color: 'red', fontWeight: 'bold' }}>Comandas</div>
                            <div style={{ fontSize: '0.7em', color: 'darkgray' }}>Transações {totais?.comandac}</div>
                        </div>
                    </Card>
                </Space>

                {!isMobile && <Space>
                    <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>

                    <ConfigProvider locale={ptBR}>
                        <RangePicker
                            locale={ptBR}
                            size='small'
                            allowClear={false}
                            format={'DD-MM-YYYY'}
                            value={dates || null}
                            disabledDate={disabledDate}
                            onChange={(val) => {
                                setDates(val);
                            }}

                            changeOnBlur />
                    </ConfigProvider>
                    <Button size='small' type='primary' style={{ marginLeft: 5 }}
                        onClick={() => pesquisar()}>Pesquisar</Button>

                </Space>
                }

            </div>

            {isMobile && <Space style={{ marginTop: 5 }}>
                <span style={{ marginLeft: 5, marginRight: 5 }}>  Período </span>

                <ConfigProvider locale={ptBR}>
                    <RangePicker
                        locale={ptBR}
                        size='small'
                        allowClear={false}
                        format={'DD-MM-YYYY'}
                        value={dates || null}
                        disabledDate={disabledDate}
                        onChange={(val) => {
                            setDates(val);
                        }}

                        changeOnBlur />
                </ConfigProvider>
                <Button size='small' type='primary' style={{ marginLeft: 5 }}
                    onClick={() => pesquisar()}>Pesquisar</Button>

            </Space>
            }

            <div style={{ marginTop: 10 }}>

                {status === -1 && <Message showIcon type="error" header="Error">
                    Desculpe, não foi possível processar a solicitação.
                </Message>}

                <Table
                    autoHeight={true}
                    hover
                    loading={loading}
                    showHeader={isMobile ? false : true}
                    style={{ fontSize: '0.8em', margin: 5 }}
                    height={600}
                    rowHeight={isMobile ? 43 : 30}
                    headerHeight={30}
                    data={data}
                    onRowClick={rowData => {
                        // pesquisar()
                    }}>
                    {isMobile ? <>
                        <Column fixed flexGrow={1}>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>Nº VENDA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return <FlexboxGrid>
                                        <FlexboxGrid.Item colspan={24}>
                                            <Space style={{ minWidth: 60 }} direction='horizontal'>
                                                Venda: {rowData.vendaChave}
                                            </Space>
                                            <Divider vertical />

                                            {dayjs("".concat(rowData.vendaHora).substring(0, 10)).format('DD/MM/YYYY')}
                                            {" ".concat(rowData.vendaHora.substring(11, 19))}

                                            <Divider vertical />
                                            <Space style={{ minWidth: 30 }} direction='horizontal'>
                                                {rowData.Total.toFixed(2)}
                                            </Space>

                                            <Divider vertical />
                                            {rowData.forma}

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={24}>
                                            <Space style={{ minWidth: 60 }} direction='horizontal'>
                                                <Badge style={{ background: cor(rowData.Nome) }} /> {rowData.Nome}
                                            </Space>
                                            <Divider vertical />

                                            {rowData.funNome}
                                        </FlexboxGrid.Item>

                                    </FlexboxGrid>
                                }}
                            </Cell>
                        </Column>
                    </> : <>
                        <Column fixed align='center'>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>Nº VENDA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.vendaChave
                                }}
                            </Cell>
                        </Column>
                        <Column fixed width={130} align='center'>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>DATA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    // console.log(rowData)
                                    return <div>  {dayjs("".concat(rowData.vendaHora).substring(0, 10)).format('DD/MM/YYYY')}
                                        {" ".concat(rowData.vendaHora.substring(11, 19))} </div>
                                }}
                            </Cell>
                        </Column>
                        <Column fixed width={80} align='center'>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>TOTAL</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.Total.toFixed(2)

                                }}
                            </Cell>
                        </Column>
                        <Column width={160} align='center'>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>FORMA</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.forma

                                }}
                            </Cell>
                        </Column>
                        <Column fixed width={120} align='center'>
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>TIPO</HeaderCell>
                            <Cell dataKey="ABERTURA" style={{ padding: 3 }}>
                                {rowData => {
                                    return <div style={{ minWidth: 70 }}> <Badge style={{ background: cor(rowData.Nome) }} /> {rowData.Nome}</div>

                                }}
                            </Cell>
                        </Column>
                        <Column fixed flexGrow={1} >
                            <HeaderCell style={{ fontWeight: 'bold', color: 'black', padding: 3 }}>FUNCIONÁRIO</HeaderCell>
                            <Cell dataKey="Caixa" style={{ padding: 3 }}>
                                {rowData => {
                                    return rowData.funNome

                                }}
                            </Cell>
                        </Column>
                    </>
                    }
                </Table>
            </div>
        </div>
    </main>
    )
}

