import { io } from 'socket.io-client';
import {BASEURL} from '../service/api.service'


export const socket = io(BASEURL)

/*
export const socket = io(URL,{
    extraHeaders: {
        "my-custom-header": "abcd"
      }
});
*/