import React, { useEffect, useState } from 'react'
import {
    Button, Flex, Col, Row, App,
    Breadcrumb, Divider} from 'antd'
import { socket } from '../../../service/socket'
import { titleize } from '../../../service/util'
import { DeleteOutlined,  PlusOutlined, CheckOutlined, MinusOutlined } from '@ant-design/icons'
import { waiterUserState, mesaState, pedidoWaiterState } from '../../../store/dataRecoil'
import { useRecoilState } from 'recoil'
import '../waiterOutlet.css'
import complemntoPNG from '../../../img/abaixo-a-direita.png'
import notaPNG from '../../../img/nota.png'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../service/api.service'


export const WaiterPedido = (props) => {


    const { notification } = App.useApp()
    const navigate = useNavigate()
   // const location = useLocation()
    const [waiterUser, setWaiterUser] = useRecoilState(waiterUserState)
    const [pedido, setPedido] = useRecoilState(pedidoWaiterState)
    const [mesa, setMesa] = useRecoilState(mesaState)
    /*
    const [data, setData] = useState([]);
    const [observacoes, setObservacoes] = useState('');
    const [totalGrupos, setTotalGrupos] = useState(0);
    const [maiorValor, setMaiorValor] = useState(0);
    const [complementosSelecionados, setComplementosSelecionados] = useState(0);
    const [disableok, setDisableok] = useState(true);
    const [Quant, setQuant] = useState(1);
    const [open, setOpen] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(true)
    const [cupom, setCupom] = useState(null)
    const [status, setStatus] = useState(0)
    const [cliente, setCliente] = useState(mesa.Cliente)
*/

    const [subtotal, setSubtotal] = useState(0)
    const [processando, setProcessando] = useState(false)

    useEffect(() => {
        // console.log(mesa)
        calcularSubTotal()
        if (pedido.itens.length === 0)
            setProcessando(true)
        else
            setProcessando(false)
    }, [pedido])

  

    function calcularSubTotal() {
        var total = 0;
        pedido.itens.map((item, index) => {
            total = (item.valorUnitarioFinal * item.Quant) + total;
            return item
        })
        setSubtotal(total)
    }

    function changeQuant(it, index, quant) {
        var item = JSON.parse(JSON.stringify(it));
        var itens = JSON.parse(JSON.stringify(pedido.itens));

        item.Quant = item.Quant + quant;
        item.quant = item.Quant;

        item.complementos.map((comp, index) => {
            comp.Quant = comp.Quant + quant
            return comp
        })
        if (item.Quant < 1) {
            itens.splice(index, 1);
        } else {
            itens[index] = item;
        }
        // pedido.itens = itens;
        setPedido({ ...pedido, itens: itens })
        // setForceUpdate(!forceUpdate)
    }


    async function enviarPedido() {
        // console.log('enviarPedido')
        setProcessando(true)
        var pedidoTemp = JSON.parse(JSON.stringify(pedido))
        try {
            if (!(waiterUser.funid > 0)) {
                notification.warning({
                    description: 'Garçom não identificado!',
                    duration: 3
                })
                return
            }


            if (!(mesa.vendaID > 0)) {
                notification.error({ description: 'Venda não localizada!', duration: 3 })
                setProcessando(false)
                return
            }

            pedidoTemp.itens.map((item, index) => {
                item.descricao = item.nome
                item.obs = item.Obs
                item.complementos.map((subitem, subindex) => {
                    subitem.descricao = subitem.NomeReduzido
                    return subitem
                })
                return item
            })

            if (pedidoTemp.itens.length > 0) {
                pedidoTemp.itens.map(item => {
                    item.complementos.map(comp => {
                        comp.venda = comp.Venda
                        comp.produtoID = comp.ProdutoID
                        return comp
                    })
                    return item;
                })

                pedidoTemp.itens[0].mesaID_comID = mesa.mesaID
                pedidoTemp.itens[0].vendaID = mesa.vendaID
                pedidoTemp.itens[0].garcomID = waiterUser.funid
                pedidoTemp.vendaID = mesa.vendaID
                pedidoTemp.garcomID = waiterUser.funid

                const responde = await api.post('api/v1.0/manager/neworder',
                    pedidoTemp, {
                    params: {
                        sala: waiterUser?.database
                    },
                    headers: {
                        'x-access-token': waiterUser?.token,
                    }
                })

const itdg = mesa.tipoAtendimento === 3 ? mesa.ComandaNome : mesa.numero

                if (responde.data.status === 200) {
                    notification.info({
                        duration: 3,
                        closable: false,
                        description: 'Pedido da '
                            .concat(itdg ? itdg : '', ' enviado com sucesso!')
                    })
                    setPedido(pedido)
                    navigate(mesa.tipoAtendimento === 3 ? '/manager/comandas' : '/manager/')
                } else {
                    notification.error({ description: "O pedido não foi enviado!", duration: 3 })
                }
                setProcessando(false)
                /*
                               socket.emit('newOrder', waiterUser?.database, pedidoTemp, (value) => {
               
                                   if (value.status === 200) {
                                       notification.info({
                                           duration: 3,
                                           closable: false,
                                           description: 'Pedido da '
                                               .concat(mesa.tipoAtendimento === 3 ? mesa.ComandaNome : mesa.numero,
                                                   ' enviado com sucesso!')
                                       })
                                       setPedido(pedido)
                                       navigate(mesa.tipoAtendimento === 3 ? '/manager/comandas' : '/manager/')
                                   } else {
                                       notification.error({ description: "O pedido não foi enviado!", duration: 3 })
                                   }
               
                                   setProcessando(false)
                               });
                               */

            } else {
                notification.warning({ description: "Adicione itens para efetuar o pedido!", duration: 3 })
                setProcessando(false)
            }
        } catch (error) {
            notification.error({ description: error.message, duration: 3 })
            setProcessando(false)
        }



    }


    return <>
        <main style={{flex:1, overflowY:'auto', overflowX:'hidden'}}>
            <Flex vertical style={{padding:5}}>
                <div style={{
                    backgroundColor: 'whitesmoke', 
                    
                }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            {mesa.tipoAtendimento === 3 ? ' Controle de Comandas' : ' Controle de Mesas'}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <b>{mesa.tipoAtendimento === 3 ? mesa?.ComandaNome : mesa?.numero} </b>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {pedido.itens.map((item, index) => (
                    <React.Fragment key={'pedido-'.concat(index, '-', item.nome)}>
                        <Row>
                            <Col flex={'auto'}><b> {titleize(item.nome)} </b> </Col>
                            <Col flex={'100px'}>

                                {item.Quant.toFixed(Number.isInteger(item.Quant) ? 0 : 3 )} x

                            </Col>
                            <Col flex={'100px'} style={{ textAlign: 'right' }}> 
                            R$ {item.complementos.length > 0 ? (item.Quant * item.venda).toFixed(2)
                                :
                                (item.Quant * item.valorUnitarioFinal).toFixed(2)
                            } </Col>
                        </Row>
                        {
                            item.complementos.map((item2, index2) => (
                                <Row>
                                    <Col flex={'auto'}>
                                        <img src={complemntoPNG} height={15} alt='item' style={{ marginRight: 5, marginLeft: 5 }} />
                                        {titleize(item2.NomeReduzido)} </Col>
                                    <Col flex={'100px'}> { item2.Quant.toFixed( Number.isInteger(item2.Quant) ? 0 : 3 ).concat(' x ')} </Col>
                                    <Col flex={'100px'} style={{ textAlign: 'right' }}> R$ {(item2.Quant * item2.Venda).toFixed(2)} </Col>
                                </Row>
                            ))}

                        {
                            item.Obs &&
                            <Row>
                                <Col flex={'auto'} style={{ color: 'darkgray', fontStyle: 'italic' }}>
                                    <img src={notaPNG} height={15} alt='item' style={{ marginRight: 5, marginLeft: 5 }} />
                                    {' '}
                                    {item.Obs}
                                </Col>
                            </Row>
                        }

                        {
                            item.complementos.length > 0 &&
                            <Row>
                                <Col flex={'auto'} style={{ textAlign: 'right' }}>
                                    R$ {(item.Quant * item.valorUnitarioFinal).toFixed(2)}
                                </Col>
                            </Row>
                        }

                        <Row >
                            <Col flex={'auto'}>
                                <Flex justify='center'>
                                    <Button onClick={() => changeQuant(item, index, -1)}
                                        type="primary"
                                        style={{ backgroundColor: 'rebeccapurple', marginRight: 5 }}
                                        size='small' >
                                        <MinusOutlined style={{ color: '#fff' }} />
                                    </Button>
                                    <Button onClick={() => changeQuant(item, index, 1)}
                                        type='primary'
                                        size='small'
                                        style={{ marginLeft: 5 }}>
                                        <PlusOutlined />
                                    </Button>
                                </Flex>

                            </Col>
                        </Row>


                        <Divider style={{ marginTop: 2, marginBottom: 2 }} />

                    </React.Fragment>
                ))}
                <Row>
                    <Col flex={'auto'} style={{ textAlign: 'right' }}>
                        <strong>  (=) SUBTOTAL </strong>
                    </Col>
                    <Col flex={'200px'} style={{ textAlign: 'right' }}>
                        <strong> R$ {subtotal.toFixed(2)} </strong>
                    </Col>
                </Row>

                <Flex vertical>
                    <Flex></Flex>
                </Flex>
            </Flex>

        </main>


        <footer>
            <Flex>
                <Button block icon={<DeleteOutlined />} type='primary' danger onClick={() => {
                    setPedido({ ...pedido, itens: [] })
                    navigate(mesa.tipoAtendimento === 3 ? '/manager/comandas' : '/manager')
                }}>
                    Cancelar
                </Button>

                <Button block icon={<PlusOutlined />} type='primary'
                    style={{ backgroundColor: 'green' }}
                    onClick={() => {
                        navigate('/manager/categorias')
                    }}>
                    Produtos
                </Button>

                <Button
                    block
                    icon={<CheckOutlined />}
                    type='primary'
                    loading={(processando === false) ? false : true}
                    onClick={() => enviarPedido()}>
                    Confirma
                </Button>
            </Flex>
        </footer>
    </>




}
