import * as React from "react";
import {
    createBrowserRouter, RouterProvider, Outlet,
    useNavigate
} from "react-router-dom";

import { Button, Result } from 'antd';

import Home from './view/home'
import { Cidades } from './view/cidades'
import { NoMatch } from './view/noMatch'
import { Restaurantes } from "./view/restaurantes";
import { Restaurant } from './view/restaurant'
import { RestaurantOld } from './view/restaurantOld'
import { Informacoes } from "./view/informacoes";
import { Parceiros } from './view/parceiros'
import { DeleteUserAccount } from './view/deleteUserAccount'
import { ManagerNoMatch } from "./view/appwaiter/manager/managerNoMatch";


import App from './App'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { RecoilRoot } from 'recoil';
import { ToastProvider } from 'react-toast-notifications';


// Waiter
import { WaiterLogin } from './view/appwaiter/login'
import { WaiterOutlet } from './view/appwaiter/waiterOutlet'
import { WaiterMesas } from './view/appwaiter/mesas'
import { WaiterMesa } from './view/appwaiter/mesa'
import { WaiterCategorias } from "./view/appwaiter/categoriasWaiter";
import { WaiterProdutos } from "./view/appwaiter/produtosWaiter";
import { WaiterProduto } from "./view/appwaiter/produtoWaiter";
import { WaiterPedido } from "./view/appwaiter/pedidoWaiter";
import { WaiterComandas } from "./view/appwaiter/comandas";
import { WaiterMonitor } from "./view/appwaiter/monitor";
import { Dashboard } from "./view/appwaiter/manager/dashboard";
import { Caixas } from "./view/appwaiter/manager/caixas";
import { ContasPagar } from "./view/appwaiter/manager/contasPagar";
import { VendasMovimentos } from "./view/appwaiter/manager/vendasMovimento";
import { Recebimentos } from "./view/appwaiter/manager/recebimentos";
import { EntregasBairros } from "./view/appwaiter/manager/entregasBairros";
import { ClientesMelhores } from "./view/appwaiter/manager/clientesMelhores";
import { Produtos } from "./view/appwaiter/manager/produtos";
import {ProdutosMovimentos} from './view/appwaiter/manager/produtosMovimentos'
import { EstoqueCritico } from "./view/appwaiter/manager/estoqueCritico";
import { AnaliseFinanceira } from "./view/appwaiter/manager/analiseFinanceira";
import { QRCodeMesas } from "./view/appwaiter/manager/qrCodeMesas";

import { CardapioLogin } from "./view/cardapioOnline/cardapioLogin";
import {MainCardapio} from "./view/cardapioOnline/mainCardapio"
import {CardapioOutlet} from './view/cardapioOnline/outletCardapio'


function MyApp() {
    return <Outlet />
}



const Erro404 = () => {
    const navigate = useNavigate()
    return <Result
        status="404"
        title="404"
        subTitle="Desculpa, a pagina que você visitou não existe!"
        extra={<Button type="primary" onClick={() => {
            navigate("/")
        }}>Voltar principal</Button>}
    />
}


export default function Rotas() {



    const router = createBrowserRouter([
        {
            path: "/",
            element: <MyApp />,
            errorElement: <Erro404 />,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/cidades",
                    element: <Cidades />,
                },
                {
                    path: "/:uf/:cidade",
                    element: <Restaurantes />,
                },
                {
                    path: "/:uf/:cidade/:restaurant",
                    element: <Restaurant />,
                },
                {
                    path: "/:uf/:cidade/:restaurant/:app",
                    element: <Restaurant />,
                },
                {
                    path: "/restaurante/:path",
                    element: <RestaurantOld />,
                },
                {
                    path: "/informacoes",
                    element: <Informacoes />,
                },
                {
                    path: "/parceiros",
                    element: <Parceiros />,
                },
                {
                    path: "/app/:restaurant",
                    element: <Restaurant />,
                },
                {
                    path: "/delete-user-account",
                    element: <DeleteUserAccount />,
                },
                {
                    path: "/manager/login",
                    element: <WaiterLogin />,
                },
                {
                    path: "/manager",
                    element: <WaiterOutlet />,
                    errorElement: <ManagerNoMatch />,                    
                    children: [{
                        path: "/manager",
                        element: <WaiterMesas />
                    },
                    {
                        path: "/manager/mesa",
                        element: <WaiterMesa />
                    },
                    {
                        path: "/manager/categorias",
                        element: <WaiterCategorias />
                    },
                    {
                        path: "/manager/produtos",
                        element: <WaiterProdutos />
                    },
                    {
                        path: "/manager/produto",
                        element: <WaiterProduto />
                    },
                    {
                        path: "/manager/pedido",
                        element: <WaiterPedido />
                    },
                    {
                        path: "/manager/comandas",
                        element: <WaiterComandas />
                    },
                    {
                        path: "/manager/monitor",
                        element: <WaiterMonitor />
                    },
                    {
                        path: "/manager/dashboad",
                        element: <Dashboard />
                    },
                    {
                        path: "/manager/caixas",
                        element: <Caixas />
                    },
                    {
                        path: "/manager/contas/pagar",
                        element: <ContasPagar />
                    },
                    {
                        path: "/manager/vendas/movimentos",
                        element: <VendasMovimentos />
                    },
                    {
                        path: "/manager/recebimentos",
                        element: <Recebimentos />
                    },
                    {
                        path: "/manager/bairros/entregas",
                        element: <EntregasBairros />
                    },
                    {
                        path: "/manager/clientes/melhores",
                        element: <ClientesMelhores />
                    },
                    {
                        path: "/manager/produtos/critico",
                        element: <EstoqueCritico />
                    },
                    {
                        path: "/manager/produtos/:isInsumo",
                        element: <Produtos />
                    },
                    
                    {
                        path: "/manager/movimentos/produtos",
                        element: <ProdutosMovimentos />
                    },
                    {
                        path: "/manager/analise",
                        element: <AnaliseFinanceira />
                    },
                    {
                        path: "/manager/qrcodes",
                        element: <QRCodeMesas />
                    },
                    {
                        index:true,
                        path: "*",
                        element: <ManagerNoMatch />,
                    }
                   
                    ]
                },                
                {
                    path: "*",
                    element: <NoMatch />,
                },
            ]
        },
        {
            path: "/cardapio/login/mesa/:id/:token",
            element: <CardapioLogin />,
        },
        {
            path: "/order/:url",
            element: <CardapioLogin />,
        },
        {
            path: "/order",
            element: <CardapioLogin />,
        },
        {
            path: "/cardapio/login",
            element: <CardapioLogin />,
        },
        {
            path: "/cardapio",
            element: <CardapioOutlet />,
            errorElement: <ManagerNoMatch />,                    
            children: [{
                path: "/cardapio",
                element: <MainCardapio />
            },
        
        
        ]
        }
    
    ])

    return <ToastProvider>
        <RecoilRoot>
            <RouterProvider router={router} />
        </RecoilRoot>
    </ToastProvider>
    /*
        return <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Home />} />
                <Route path="/cidades" element={<Cidades />} />
                <Route path="/:uf/:cidade" element={<Restaurantes />} />
                <Route path="/:uf/:cidade/:restaurant" exact element={<Restaurant />} />
                <Route path="/:uf/:cidade/:restaurant/:app" exact element={<Restaurant />} />
                <Route path="/restaurante/:path" exact element={<RestaurantOld />} />
                <Route path="/informacoes" element={<Informacoes />} />
                <Route path="/parceiros" element={<Parceiros />} />
                <Route path="/app/:restaurant" exact element={<Restaurant />} />
                <Route path="/delete-user-account" exact element={<DeleteUserAccount />} />
    
                <Route path="/waiter/login" exact element={<WaiterLogin />} />
    
                <Route path="*" element={<NoMatch />} />
    
            </Route>
        </Routes>
    */
}